@import 'client/styles/mixins';

.documents-page {
	.document-card {
		display: flex;
		gap: 30px;
		border: 1px solid var(--grey-5);
		position: relative;

		&:hover {
			background-color: var(--white);

			> .info > .name {
				color: var(--deep-blue-hover);
			}

			&:has(.document-card:hover) {
				> .info > .name {
					color: var(--black);
				}
			}
		}

		.file {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 10px;
			width: 90px;
			height: 90px;

			@include P2;
			text-transform: uppercase;

			.filesize,
			.archive {
				@include P3;
				color: var(--grey-3);
			}
		}

		.info {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			gap: 8px;

			.top {
				color: var(--grey-2);
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin: 0;

				.left {
					flex-grow: 1;
					@include P4;
				}
			}

			.accept-number,
			.effectife-expiration {
				display: flex;
				gap: 24px;

				.acceptAt,
				.number,
				.effectiveStartAt,
				.expirationAt,
				.ligament {
					display: flex;
					gap: 8px;

					.data {
						color: var(--black);
					}

					.text {
						color: var(--grey-2);
					}
				}
			}

			.effectife-expiration {
				margin: 8px 0;
			}

			.name {
				@include P2;
				color: var(--black);
			}

			.description-content {
				@include P3;
				color: var(--black);
			}

			.type,
			.number,
			.organization,
			.publishAt,
			.acceptAt,
			.children {
				@include P2;
				color: var(--grey-2);
			}

			.children {
				.toggle {
					text-decoration: underline;
					cursor: pointer;
					color: var(--grey-2);
				}

				.auto-animate {
					.document-card {
						border: none;
					}
				}
			}
		}

		@media (max-width: 640px) {
			.info {
				.top {
					display: flow;

					.publishAt {
						padding-top: 5px;
					}
				}

				.children {
					.auto-animate {
						.document-card {
							display: flow;
						}
					}
				}
			}
		}
	}
}
