.additional-banner-container {
  position: relative;
  width: 281px;
  height: 361px;
  margin: 0 auto;

  .additional-banner-image {
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 2;
    width: 281px;
    height: 361px;
    border-radius: 8px;
    overflow: hidden;

    img {
      width: auto;
      height: 100%;
      border-radius: 8px;
      background-size: cover;
    }

    .empty {
      width: 100%;
      height: 100%;
      background: var(--grey-2);
      display: flex;
      border-radius: 8px;
      z-index: 1;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: var(--white);
    }
  }

  .header-description-button-container {
    position: relative;
    z-index: 99;
    width: 281px;
    height: 197px;
    padding: 16px;
    top: 164px;

    .additional-banner-header {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      color: var(--white);
      margin-bottom: 16px;
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .additional-banner-description {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      color: var(--white);
      margin-bottom: 16px;
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .additional-banner-button {
      width: 100%;
      height: 37px;
      padding: 8px 16px;
      align-items: center;
      border-radius: 24px;
      border: none;
      background: var(--white);
      cursor: pointer;
      color: var(--grey-1);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;

      &:hover {
        opacity: 95%;
      }
    }
  }
}