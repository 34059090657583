.sp-popconfirm {
	text-align: center;
	padding: 10px;

	.sp-popconfirm-buttons {
		margin-top: 10px;

		button {
			min-width: 40px;
		}

		button + button {
			margin-left: 10px;
		}
	}
}