.sp-btn {
    @include H7;
    --default-text: var(--white);
    height: calc(var(--element-height-no-px) * 1em / 16);
    border-radius: 30px;
    padding: 0 24px;

    &.sp-btn-small {
        @include P2;
        border-radius: 24px;
        padding: 0 1.15em;
    }

    &.selected {
        border: 1px solid var(--deep-blue);
    }
}