@import 'site/styles/mediaBreakpoints';

.media-broadcast {
	display: flex;
	gap: 24px;
	padding: 64px 0;
	min-height: 700px;

	.media-broadcast-container {
		width: 50%;

		.media-broadcast-video {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 24px;

			.card-image, img {
				width: 100%;
			}
		}

		.media-broadcast-live {
			.card-image, img {
				width: 100%;
				height: auto;
				max-height: 400px;
			}
		}

		.media-broadcast-header {
			display: flex;
			align-items: center;
			margin-bottom: 24px;

			span {
				color: var(--primary-deep-blue, #1051A1);
				font-size: 12px;
				font-weight: 600;
				line-height: 140%;
				letter-spacing: 0.48px;
				text-transform: uppercase;
				white-space: nowrap;
				margin-right: 10px;
			}

			svg {
				margin-right: 10px;
			}

			.line {
				width: 100%;
				border-top: 1px solid #1051A1;;
			}
		}
	}
}

@media(max-width: $mobile-width-breakpoint){
	.media-broadcast{
		padding: var(--mobile-section-padding);
		flex-direction: column-reverse;
		gap: var(--mobile-standart-row-gap);

		.media-broadcast-container{
			@include flex-column;
			gap: var(--mobile-standart-row-gap);
			width: 100%;

			.media-broadcast-header{
				margin: 0px;
			}

			.media-broadcast-live, .media-broadcast-video{

				.media-card{
					@include flex-column;
					gap: var(--mobile-small-row-gap);
					height: max-content;

					.card-description{
						height: max-content;
						gap: 4px;
					}

					.card-name{
						@include h6-mobile;
						flex-grow: 1;
					}

					.card-author{
						@include p2-mobile;
					}

					.card-view-date{
						@include p3-mobile;
					}
				}
			}
		}
	}
}