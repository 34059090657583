@import 'client/styles/mixins';

.back-link,
.portal-link {
	display: flex;
	flex-direction: row;
	gap: 8px;
	align-items: center;
	opacity: 50%;

	&:hover {
		opacity: 100%;
	}

	.link-text {
		text-transform: uppercase;
		@include P4;
	}
}

.search-title-line {
	.portal-link {
		margin-bottom: 24px;
	}
}