.ui-input {
    height: var(--element-height);
    width: 100%;
    background: var(--white);
    border: 1px solid var(--grey-5);
    box-shadow: 0px 0px 16px rgba(28, 93, 168, 0.04);
    border-radius: 32px;
    padding: 0 16px;

    &::placeholder {
        @include P1;
        opacity: 0.3;
        color: var(--black);
    }
}