.labels-filter {
	display: flex;
	justify-content: space-between;
	color: var(--grey-2);

	a {
		text-decoration: none;
	}
	margin-bottom: 15px;

	.label {
		display: inline-block;
		vertical-align: top;
		height: 30px;
		line-height: 30px;
		padding-right: 16px;
		// border-radius: 15px;
		color: var(--black);
		cursor: pointer;
		margin-right: 5px;
		margin-bottom: 10px;
		font-size: 12px;

		&:hover {
			color: var(--deep-blue-hover);
		}

		&.selected {
			color: var(--deep-blue);
		}
	}

	.caret-left_right {
		position: absolute;
		right: 280px;
		top: 313px;
		display: flex;
		border: 2px solid #f1f7fa;
		border-radius: 16px;
	}
}
