@import 'site/styles/mediaBreakpoints';

.vacancy-container-isLoading {
	margin-top: 225px;
}

.vacancy-container {
	.header {
		position: fixed;
		top: 110px;
		z-index: 100;
	}

	.vacancy-items {
		display: flex;
		margin: 32px 0;

		.vacancy-right-part {
			display: flex;
			flex-direction: column;
		}

		.vacancy {
			width: 100%;
			padding-right: 20px;

			.vacancy-header {
				color: var(--grey-2);
			}

			.vacancy-item {
				display: flex;
				justify-content: space-between;
				gap: 24px;
				width: 100%;
				height: auto;
				margin: 24px 0;
				border: 1px solid #e4eef2;
				border-radius: 16px;
				padding: 16px 24px;
				cursor: pointer;

				.text-vacancy {
					width: 100%;

					.initiator {
						font-weight: 400;
						color: var(--grey-2);
					}
				}

				.title,
				.initiator {
					padding-bottom: 16px;
				}

				.location-date {
					display: flex;
					justify-content: space-between;
					width: 100%;
					font-weight: 400;

					.p2,
					.location {
						color: var(--grey-2);
					}

					.date {
						color: var(--grey-4);
					}
				}

				.image-vacancy-wrapper {
					display: flex;
					width: 64px;
					height: 64px;
					justify-content: center;
					align-items: center;
					padding: 30px;

					border-radius: 8px;
					background: var(--grey-6);

					.image-vacancy {
						border-radius: 100%;
						height: 36px;
						width: 36px;
					}
				}
			}
		}

		.sidebar {
			flex-basis: 170px;
			flex-shrink: 0;
			width: 285px;
			margin-bottom: 30px;

			.right {
				text-align: right;
			}

			.p3 {
				color: var(--grey-3);
			}

			.reset-all {
				cursor: pointer;
			}

			.sidebar-data {
				display: flex;
				align-items: center;
				color: var(--deep-blue);
				text-transform: uppercase;

				.line {
					width: 100%;
					margin-left: 10px;
					border-bottom: 1px solid var(--deep-blue);
				}
			}

			.open-data-tags-container {
				display: flex;
				flex-wrap: wrap;
				padding-bottom: 24px;

				.tags {
					height: 25px;
					cursor: pointer;
					color: var(--black);
					word-wrap: break-word;
					opacity: 0.6;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 150%;
					text-decoration: underline;
					padding-right: 10px;
				}
			}

			.search-sidebar {
				input {
					width: 100%;
				}

				.submit-search-input {
					position: relative;

					.search-icon {
						position: absolute;
						left: 14px;
						top: 12px;
						z-index: 6;
					}

					.ui-input {
						padding-left: 50px;
					}

					.sp-btn {
						position: absolute;
						top: 6px;
						right: 10px;
					}
				}
			}
		}
	}
}

.event-popup {
	position: fixed;
	right: 0;
	top: 0;
	bottom: 0;
	max-height: 100%;

	.sp-popup {
		height: 100%;
	}

	* {
		scrollbar-width: thin;
		scrollbar-color: var(--grey-5);
	}

	*::-webkit-scrollbar {
		width: 8px;
	}

	*::-webkit-scrollbar-track {
		background: none;
	}

	*::-webkit-scrollbar-thumb {
		background-color: var(--grey-5);
		border-radius: 8px;
	}
}

.vacancy-page {
	width: 766px;
	height: 100%;
	padding: 60px 40px 40px 40px;

	.description,
	.footing,
	.origin-source,
	.contacts,
	.app,
	.title-status-county,
	.title-education-work_experience {
		color: var(--grey-2);
	}

	.status-county-item,
	.education-work_experience-item {
		width: 280px;
	}

	.text-vacancy {
		display: flex;
		justify-content: space-between;

		.vacancy-item {
			width: 420px;
		}

		.category {
			color: var(--grey-1);
			font-weight: 400;
		}

		.initiator,
		.title,
		.category,
		.status-county,
		.education-work_experience {
			padding-bottom: 32px;
		}

		.initiator {
			color: var(--deep-blue);
			font-weight: 400;
		}

		.status-county,
		.education-work_experience {
			display: flex;
			flex-direction: row;
			align-self: stretch;
			width: 100%;

			.title-status-county,
			.title-education-work_experience {
				padding-bottom: 8px;
			}
		}

		.image-vacancy {
			border-radius: 8px;
			width: 142px;
			height: 142px;

			img {
				display: flex;
				padding: 14px;
				width: 142px;
				height: 142px;
				justify-content: center;
				align-items: center;
				border-radius: 8px;
			}
		}
	}

	.other-vacancy {
		.margin_bottom {
			margin-bottom: 32px;

			.description,
			.footing,
			.origin-source,
			.contacts,
			.app {
				padding-bottom: 8px;
			}

			.footing-item,
			.origin-source-item,
			.status {
				text-decoration: underline;
			}
		}

		.button {
			display: flex;
			width: 100%;
			position: fixed;
			bottom: -30px;
			right: inherit;
			background-color: var(--white);
			height: 84px;
			align-items: center;

			a {
				text-decoration: none;
			}

			.button-1 {

				&:hover {
					background: var(--deep-blue-hover);
				}
			}
		}

		.footing-item,
		.origin-source-item,
		.status {
			font-weight: 400;
			cursor: pointer;

			a {
				color: var(--black);
			}
		}

		.application {
			display: flex;
			flex-wrap: wrap;
			gap: 24px;

			.docs {
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: 50px;
				flex: 0 0 calc(50% - 24px);
				padding: 0 16px;
				border-radius: 8px;
				background: var(--grey-6);
				color: var(--black);
				text-decoration: inherit;
				text-align: left;
				cursor: pointer;

				&:hover {
					background: var(--grey-6-hover);
				}

				.file-name {
					word-wrap: break-word;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
				}
			}
		}

		.date {
			text-align: right;
			color: var(--grey-2);
			font-weight: 400;
		}

		padding-bottom: 60px;
	}
}

body:has(.vacancy-page) {
	overflow: hidden;
}

@media(max-width: $mobile-width-breakpoint){
	.vacancy-container{
		padding: var(--mobile-wrapper-content-padding-all);
		@include flex-column;
		gap: var(--mobile-standart-row-gap);

		.vacancy-left-part{

			.sidebar{

				@include flex-column;
				gap: var(--mobile-standart-row-gap);

				& > div{
					padding: var(--mobile-wrapper-content-padding-all);
					background: var(--white);
					@include flex-column;
					gap: var(--mobile-small-row-gap);

					.search-sidebar{
						@include flex-column;
						gap: var(--mobile-small-row-gap);

						div{
							margin-bottom: 0px !important;
						}

						.h7{
							@include h7-mobile;
						}

						input{
							height: max-content;
							padding: 8px 16px;
						}
					}

					& > div:last-child{
						.scrollableContainer{
							margin: 0px;

							ul{
								padding: 0px;
								@include flex-column;
								gap: var(--mobile-small-row-gap);

								.list-items{
									padding: 0px;
								}
							}
						}
					}
				}

				.reset-all{
					display: none;
				}
			}

			.vacancy-filters{
				.vacancy-filters__wrapper{

					.left{
						display: none;
					}

					.right{
						width: 100%;
						margin: 0px;

						span{
							display: none;
						}

						.sp-dropdown{
							width: 100%;
							margin: 0px;

							.filter{
								margin: 0px;
								width: 100%;
								@include flex-row;
								gap: var(--mobile-small-row-gap);
								flex-direction: row-reverse;
								justify-content: center;

								&::before{
									display: none;
								}

								.filter-content{
									margin: 0px;
									flex-grow: unset;
									flex-basis: auto;
									padding: 0px;

									.show-value{
										@include p1-mobile;
										margin: 0px;
										padding: 0px;
										justify-content: center;
									}
								}

								.filter-arrow{
									display: block;
									width: 0px;
									transform: none !important;

									&::before{
										content: '';
										display: block;
										width: 20px;
										height: 20px;
										background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='20' viewBox='0 0 21 20' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.41667 5C3.18655 5 3 5.18655 3 5.41667V6.25C3 6.48012 3.18655 6.66667 3.41667 6.66667H17.5833C17.8135 6.66667 18 6.48012 18 6.25V5.41667C18 5.18655 17.8135 5 17.5833 5H3.41667ZM5.91667 9.16667C5.68655 9.16667 5.5 9.35321 5.5 9.58333V10.4167C5.5 10.6468 5.68655 10.8333 5.91667 10.8333H15.0833C15.3135 10.8333 15.5 10.6468 15.5 10.4167V9.58333C15.5 9.35321 15.3135 9.16667 15.0833 9.16667H5.91667ZM8 13.75C8 13.5199 8.18655 13.3333 8.41667 13.3333H12.5833C12.8135 13.3333 13 13.5199 13 13.75V14.5833C13 14.8135 12.8135 15 12.5833 15H8.41667C8.18655 15 8 14.8135 8 14.5833V13.75Z' fill='%238291A4'/%3E%3C/svg%3E");
									}

									svg{
										display: none;
									}
								}
							}

							&.active{
								.filter{
									.filter-arrow{
										&:before{
											background: none;
										}
									}
								}
							}

						}
					}
				}
			}
		}

		.vacancy-items{
			margin: 0px;

			.vacancy{
				padding: 0px;
				@include flex-column;
				gap: var(--mobile-standart-row-gap);

				.vacancy-header{
					@include p1-mobile;
				}

				& > div{
					@include flex-column;
					gap: var(--mobile-standart-row-gap);

					.vacancy-item{
						margin: 0px;
						padding: 8px 16px;
						gap: var(--mobile-standart-row-gap);

						 .image-vacancy-wrapper{
							width: 48px;
							height: 48px;
							padding: 6px;
						}

						.text-vacancy{
							@include flex-column;
							gap: var(--mobile-small-row-gap);

							p{
								padding: 0px;
							}

							.h7{
								@include h7-mobile;
							}

							.p2{
								@include p2-mobile;
							}

							.location-date{
								flex-wrap: wrap;
								justify-content: flex-start;
								gap: var(--mobile-standart-row-gap);
								row-gap: 2px;
								p{
									@include p3-mobile;
								}
							}
						}
					}
				}
			}
		}
	}

	.vacancy-page{
		width: 100%;
		height: max-content;
		padding: var(--mobile-wrapper-content-padding-all);
		@include flex-column;
		gap: var(--mobile-standart-row-gap);

		.wrapper-back{
			@include flex-row;
			align-items: center;
			gap: var(--mobile-small-row-gap);
			color: var(--grey-3);
		}

		.text-vacancy{
			align-items: flex-start;
			gap: var(--mobile-medium-row-gap);

			.vacancy-item{
				flex-grow: 1;
				width: auto;
				max-width: calc(100% - 95px);
				@include flex-column;
				gap: var(--mobile-standart-row-gap);

				.initiator, .category{
					padding: 0px;
					@include p2-mobile;
				}

				.title{
					@include h6-mobile;
					padding: 0px;
				}

				.top-content-vacancy{
					@include flex-column;
					gap: var(--mobile-small-row-gap);
				}
			}

			picture{
				min-width: 71px;

				.image-vacancy{
					width: 71px;
					height: auto;
				}
			}
		}

		.wrapper-additional-info{
			@include flex-column;
			gap: var(--mobile-standart-row-gap);

			.status-county, .education-work_experience{
				@include flex-row;
				gap: var(--mobile-medium-row-gap);

				& > div{
					width: calc((100% - var(--mobile-medium-row-gap)) / 2);
				}
			}
		}

		.other-vacancy{
			@include flex-column;
			gap: var(--mobile-standart-row-gap);
			padding: 0px;

			.p2{
				@include p2-mobile;
				@include flex-column;
				gap: var(--mobile-small-row-gap);
			}

			.margin_bottom{
				margin: 0px;
			}

			.application{
				gap: var(--mobile-standart-row-gap);

				.docs{
					width: calc((100% - var(--mobile-standart-row-gap)) / 2);
					height: max-content;
				}
			}

			.button{
				position: relative;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				height: max-content;
				background: transparent;

				a{
					width: 100%;

					button{
						width: 100%;
						height: max-content;
						padding: 8px 16px;
					}
				}
			}
		}

	}

	body:has(.vacancy-page) {
		overflow: auto;
	}

	.wrapper-center-content-vacancy{
		width: 100%;
		@include flex-row;
		justify-content: center;
		padding: var(--mobile-wrapper-content-padding-all);
	}
}