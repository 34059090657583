@import 'site/styles/mediaBreakpoints';

.events-page {
  padding-bottom: 25px;

  .header {
    position: fixed;
    top: 110px;
    z-index: 100;
  }

  .sp-tabs-container {
    font-size: 17px;
  }

  .no-record {
    color: var(--grey-2);
  }
}

@media(max-width: $tabled-width-breakpoint){
  .events-page{

    .wrapper-list-event{

      .sp-table-container{
        min-width: 100%;
      }
    }
  }
}

@media(max-width: $mobile-width-breakpoint){
  .events-page{
    padding: var(--mobile-wrapper-content-padding-all);
    @include flex-column;
    gap: var(--mobile-standart-row-gap);

    .event-filters{
      margin: 0px;

      .selectors{
        @include flex-column;
        gap: var(--mobile-small-row-gap);

        .filter-field-wrapper{
          max-width: 100%;
          @include scroll-horizontal;

          .sp-dropdown{
            min-width: 140px;

            .show-value{
              @include p2-mobile;
            }
          }

          .sp-date-picker{
            input{
              @include p2-mobile;
            }
          }
        }

        .sort{

          span{
            @include p2-mobile;
          }

          .sp-dropdown{
            min-width: 140px;

            .show-value{
              @include p2-mobile;
            }
          }
        }
      }
    }

    .sp-tabs{
      @include flex-column;
      gap: var(--mobile-standart-row-gap);

      .sp-tabs-container{
        margin: 0px;
        height: max-content;

        .sp-tabs-well{
          height: max-content;
          @include flex-row;
          @include scroll-horizontal;
          max-width: 100%;
          gap: var(--mobile-standart-row-gap);

          .sp-tab{
            margin: 0px;
            height: max-content;
            @include p1-mobile;
            padding: 0px 0px 8px 0px;

            &.active{
              @include h6-mobile;
              color: var(--deep-blue);
              border-color:  var(--deep-blue);
            }
          }
        }
      }
    }
  }
}