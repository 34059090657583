.sp-popover-popup {
	$border-radius: 4px;

	background: var(--popover-bg, #fff);
	color: var(--popover-text, #000);
	min-width: 100px;
	border-radius: $border-radius;
	border: 1px solid rgba(#000, 0.15);
	//box-shadow: var(--popover-shadow, 0 3px 5px 1px rgba(#000, 0.1));
	box-shadow: 0 3px 3px 1px rgba(#000, 0.1);

	.sp-popover-header {
		height: 24px;
		line-height: 24px;
		padding: 0 10px;
		border-top-left-radius: $border-radius;
		border-top-right-radius: $border-radius;
		border-bottom: 1px solid #ddd;
		font-weight: bold;
	}

	.sp-popover-content {
		padding: 10px;
	}

	.sp-popover-arrow {
		position: absolute;
		z-index: 1002;
		display: block;
		overflow: hidden;
		width: 10px;
		height: 10px;

		&:after {
			content: '';
			position: absolute;
			top: -5px;
			display: block;
			background: var(--popover-bg, #fff);
			width: 10px;
			height: 10px;
			transform: rotate(45deg);
			border: 1px solid var(--border);
			box-shadow: var(--popover-shadow, 0 3px 5px 1px rgba(#000, 0.1));
		}
	}

	&.vertical {
		&.top {
			.sp-popover-arrow {
				bottom: -10px;
			}

			&.left .sp-popover-arrow {
				left: 10px;
			}

			&.center .sp-popover-arrow {
				left: 50%;
				transform: translate(-5px, 0);
			}

			&.right .sp-popover-arrow {
				right: 10px;
			}
		}

		&.bottom {
			.sp-popover-arrow {
				top: -10px;
				transform: rotate(180deg);
			}

			&.left .sp-popover-arrow {
				left: 10px;
			}

			&.center .sp-popover-arrow {
				left: 50%;
				transform: translate(-5px, 0) rotate(180deg);
			}

			&.right .sp-popover-arrow {
				right: 10px;
			}
		}
	}

	&.horizontal {
		&.left {
			.sp-popover-arrow {
				right: -10px;
				transform: rotate(270deg);
			}

			&.top .sp-popover-arrow {
				top: 10px;
			}

			&.center .sp-popover-arrow {
				top: 50%;
				transform: translate(0, -5px) rotate(270deg);
			}

			&.bottom .sp-popover-arrow {
				bottom: 10px;
			}
		}

		&.right {
			.sp-popover-arrow {
				left: -10px;
				transform: rotate(90deg);
			}

			&.top .sp-popover-arrow {
				top: 10px;
			}

			&.center .sp-popover-arrow {
				top: 50%;
				transform: translate(0, -5px) rotate(90deg);
			}

			&.bottom .sp-popover-arrow {
				bottom: 10px;
			}
		}
	}


}
