.inner-page-content {
	min-height: 100vh;

	& > * {
		max-width: var(--site-width);
		width: 100%;
		margin: 0 auto;
	}

	& .page-content-single {
		margin: 40px auto 88px;
		padding-top: 0;
	}
	.gis-map {
		margin-top: 88px;
	}
}
