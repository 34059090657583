.vacancy-widget-container {
  .header {
    padding-bottom: 16px;
  }

  .vacancy-widget {
    position: relative;
    width: 282px;
    height: 618px;
    overflow-y: scroll;

    .vacancy-item {
      padding: 16px;
      border-radius: 8px;
      background: var(--grey-6);
      margin-bottom: 16px;
      margin-right: 5px;
      cursor: pointer;

      &:hover {
        border: 1px solid var(--grey-4);
      }

      .vacancy-item-title {
        color: var(--black);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        padding-bottom: 8px;
      }

      .vacancy-item-contact-person {
        color: var(--grey-3);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        padding-bottom: 8px;
      }

      .vacancy-item-created-at {
        color: var(--grey-4);
        text-align: right;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
      }
    }
  }

  a {
    text-decoration: none;
  }

  .vacancy-widget-button {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    width: 282px;
    height: 48px;
    padding: 12px 24px;
    align-items: center;
    border-radius: 24px;
    border: none;
    background: var(--light);
    cursor: pointer;

    &:hover {
      background: var(--light-hover);
    }

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      color: var(--deep-blue);
    }
  }
}