@import 'site/styles/mediaBreakpoints';

.support-measure-widget-container {
	display: flex;

	flex-direction: row;
	justify-content: space-between;
	gap: 24px;
	align-self: stretch;
	height: 100%;

	a {
		color: var(--black);
		text-decoration: none;
	}

	.support-measure {
		width: 66%;
	}

	.support-measure,
	.interesting-news {
		.head-title-support-measure,
		.head-title-interesting-news {
			display: flex;
			align-items: center;
			color: var(--deep-blue);
			text-transform: uppercase;
			padding-bottom: 24px;

			.title {
				white-space: nowrap;
			}

			.line {
				width: 100%;
				margin-left: 10px;
				border-bottom: 1px solid var(--deep-blue);
			}
		}

		.support-measure-all-items {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 24px;

			.support-measure-items {
				align-items: flex-start;
				gap: 24px;
				flex: 1 0 0;

				.topic {
					font-size: 10px;
					font-style: normal;
					font-weight: 500;
					line-height: 150%;
					letter-spacing: 0.4px;
					text-decoration-line: underline;
					text-transform: uppercase;
					color: var(--grey-2);
					padding-bottom: 8px;
				}

				.title {
					font-size: 16px;
					font-style: normal;
					font-weight: 600;
					line-height: 140%;
					color: var(--black);
					margin-bottom: 8px;
					word-wrap: break-word;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
				}

				.description {
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 150%;
					color: var(--grey-1);

					word-wrap: break-word;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
				}

				&:hover {
					.title {
						color: var(--deep-blue);
					}

					.description {
						color: var(--grey-1-hover);
					}
				}
			}

			.button-support-measure {
				grid-column: 1 / 2 span;
				width: 100%;
				height: 37px;
				padding: 8px 16px;
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 10px;
				align-self: stretch;
				border-radius: 24px;
				border: none;
				background: var(--grey-6);
				cursor: pointer;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 150%;
				color: var(--grey-1);

				&:hover {
					background: var(--grey-6-hover);
				}
			}
		}
	}

	.interesting-news {
		width: 33%;
		margin-bottom: 44px;

		.interesting-news-all, .interesting-news-list {
			padding: 16px;
			display: flex;
			height: 100%;
			flex-direction: column;

			border-radius: 8px;
			background: var(--grey-6);

			.interesting-news-items {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				padding-bottom: 16px;
				margin-bottom: 16px;
				border-bottom: 1px solid var(--light);

				.text {
					padding-right: 16px;

					.news-title {
						font-size: 16px;
						font-style: normal;
						font-weight: 600;
						line-height: 150%;
						color: var(--black);
						word-wrap: break-word;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
					}

					.news-subtitle {
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 150%;
						padding-top: 8px;
						color: var(--grey-1);
						word-wrap: break-word;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 3;
						-webkit-box-orient: vertical;
					}
				}

				.image,
				.empty-image {
					min-width: 80px;
					height: 80px;
					overflow: hidden;
					border-radius: 8px;

					img {
						width: 80px;
						height: 80px;
						border-radius: 8px;
						object-fit: cover;
					}
				}

				&:hover {
					.news-title {
						color: var(--deep-blue);
					}

					.news-subtitle {
						color: var(--grey-1-hover);
					}
				}
			}

			.button-news {
				display: flex;
				height: 37px;
				padding: 8px 16px;
				justify-content: center;
				align-items: center;
				gap: 10px;
				align-self: stretch;
				border-radius: 24px;
				border: none;
				background: var(--light);
				cursor: pointer;

				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 150%;
				color: var(--deep-blue);

				&:hover {
					background: var(--light-hover);
				}
			}
		}
	}
}

@media(max-width: $mobile-width-breakpoint){
	.support-measure-widget-container{
		flex-direction: column;
		padding: var(--mobile-section-padding);

		.support-measure{
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: var(--mobile-standart-row-gap);

			.head-title-support-measure{
				padding: 0px;
			}

			.support-measure-all-items{
				display: flex;
				flex-wrap: wrap;
				gap: var(--mobile-standart-row-gap);

				a{
					width: calc((100% - var(--mobile-standart-row-gap)) / 2);

					.support-measure-items{
						display: flex;
						flex-direction: column;
						gap: var(--mobile-small-row-gap);

						.topic, .title{
							padding: 0px;
						}

						.title{
							@include h7-mobile;
							word-wrap: break-word;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 3;
							-webkit-box-orient: vertical;
						}

						.description{
							max-width: 100%;
						}
					}
				}
			}
		}

		.interesting-news{
			width: 100%;
			margin: 0px;
			display: flex;
			flex-direction: column;
			gap: var(--mobile-standart-row-gap);

			.head-title-interesting-news{
				padding: 0px;
			}

			.interesting-news-all{
				background: none;
				padding: 0px;
				display: flex;
				flex-direction: column;
				gap: var(--mobile-standart-row-gap);

				.interesting-news-list{
					flex-direction: row;
					gap: var(--mobile-standart-row-gap);
					max-width: 100%;
					overflow-x: auto;
					background: none;
					padding: 0px;

					&::-webkit-scrollbar{
						height: 0px;
					}

					a{

						.interesting-news-items{
							margin: 0px;
							max-width: 216px;
							min-width: 216px;
							display: flex;
							flex-direction: row;
							gap: var(--mobile-standart-row-gap);
							padding-bottom: 8px;

							.text, .image{
								padding: 0px;
							}

							.text{
								flex-grow: 1;
							}

							.image{
								min-width: max-content;
								height: max-content;

								img{
									width: 48px;
									height: 48px;
								}
							}

							.news-title{
								@include h7-mobile;
								-webkit-line-clamp: 2;
							}

							.news-subtitle{
								@include p2-mobile;
							}
						}
					}
				}

				.button-news{
					height: max-content;
				}
			}
		}
	}
}
