.route-tabs {
	$height: 60px;
	width: 100%;

	.route-tabs-container {
		width: 100%;
		height: $height;
		overflow: hidden;
		position: relative;
		margin-bottom: 30px;
		border-bottom: 1px solid var(--grey-4);

		.none {
			display: none;
		}
	}

	.route-tabs-hor {
		width: 100%;
		height: 100px;
		overflow: auto;
		user-select: none;
	}

	.route-tabs-wrapper {
		//overflow: visible;
		display: inline-block;
		width: 100%;
		height: 60px;
		white-space: nowrap;
		position: relative;

		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: calc(#{$height} - 1px);
			z-index: 1;
			right: 0;
			height: 1px;
		}
	}

	.route-tab {
		display: inline-block;
		vertical-align: bottom;
		height: $height;
		line-height: $height;
		color: var(--grey-1);
		font-size: 16px;
		padding: 0 8px;
		margin-bottom: 0;
		cursor: pointer;
		text-align: center;

		&:first-child {
			padding: 0;
		}

		&:hover {
			color: var(--deep-blue);
		}

		&.active {
			border-bottom: 3px solid var(--deep-blue);
			color: var(--deep-blue);
			font-size: 18px;
			font-weight: bold;
			position: relative;
            margin: 0 8px 0 0;

			// &:after {
			// 	content: '';
			// 	display: block;
			// 	position: absolute;
			// 	left: 0;
			// 	bottom: -1px;
			// 	z-index: 2;
			// 	right: 0;
			// 	height: 1px;
			// background: #fff;
			// }
		}
	}

	.left-grad,
	.right-grad {
		position: absolute;
		pointer-events: none;
		top: 20px;
		bottom: 0;
		right: 0;
	}

	.left-grad {
		left: 0;
		// background: linear-gradient(90deg, rgba(#fff, 1) 0%, rgba(#fff, 0) 100%);
	}

	.right-grad {
		right: 0;
		// background: linear-gradient(90deg, rgba(#fff, 0) 0%, rgba(#fff, 1) 100%);
	}
}

// .ilt-tabs {
// 	.route-tab {
// 		&:hover {
// 			color: var(--color-red);
// 		}
// 	}
// }
