@import 'site/styles/mediaBreakpoints';

.super-block-container {
  position: relative;
  display: flex;
  max-width: 100vw !important;
  height: 741px;
  padding: 445px 0 0 0;

  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-height: 534px;
    z-index: 0;
    object-fit: cover;
  }

  .tab-blocks-container {
    position: relative;
    z-index: 2;
    max-width: 1440px;
    margin: 0 auto;
    transition: all 300ms;

    .sp-tabs .sp-tabs-container {
      position: absolute;
      top: -35px;
      z-index: -1;
      height: 50px;
    }

    .sp-tabs .sp-tab.active {
      display: inline-block;
      height: 50px;
      padding: 8px 16px;
      align-items: flex-start;
      gap: 10px;
      width: auto;
      border-radius: 8px 8px 0 0;
      background: var(--gradient-gradient, linear-gradient(45deg, #1051a1 0%, #387acc 100%));
      box-shadow: 8px 0 48px 8px rgba(0, 0, 0, 0.16);

      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 18px */
      letter-spacing: 0.48px;
      text-transform: uppercase;
      color: var(--white);
    }

    .sp-tabs .sp-tab {
      display: inline-block;
      height: 50px;
      padding: 8px 16px;
      align-items: flex-start;
      gap: 10px;
      width: auto;
      border-radius: 8px 8px 0 0;
      border-right: 1px solid var(--translucent-black-20);
      box-shadow: 8px 0 48px 8px rgba(0, 0, 0, 0.16);

      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 18px */
      letter-spacing: 0.48px;
      text-transform: uppercase;
      color: var(--white);
    }

    .tab-blocks {
      display: flex;
      overflow: hidden;
      max-width: 100%;
      padding: 16px 24px;
      align-items: flex-start;
      gap: 24px;
      border-radius: 16px;
      border: 1px solid var(--grey-4);
      background: var(--white);
      margin: 0 auto;

      .block {
        display: flex;
        justify-content: space-between;
        min-width: 270px;
        height: 176px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        cursor: pointer;
        flex: 1;

        .block-title {
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
          color: var(--black);

          word-wrap: break-word;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }

        .block-info {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          color: var(--grey-3);
        }

        .block-createdAt-icon {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: flex-end;
          flex-direction: row;

          .block-createdAt {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
            color: var(--grey-3);
          }

          .block-icon {
            width: 61px;
            height: 61px;

            img {
              width: 61px;
              height: 61px;
              opacity: 0.08;
            }
          }
        }
      }
    }

    .super-block-indicators {
      display: flex;
      list-style: none;
      margin-top: 16px;
      justify-content: center;
    }

    .super-block-indicators li {
      height: 10px;
      width: 10px;
      background: var(--grey-4);
      border-radius: 50%;
      margin-right: 16px;
      cursor: pointer;
      opacity: 0.7;
      transition: all 300ms;
    }

    .super-block-indicators li:hover {
      opacity: 1;
      background: var(--deep-blue);
    }

    .super-block-indicators li.active {
      background: var(--deep-blue);
    }
  }
}

@media (min-width: 1920px) {
  .super-block-container {
    padding: 445px 240px 0 240px;

    .tab-blocks-container {
      min-width: 1200px !important;
      //.tab-blocks {
      //  .block {
      //    min-width: 330px;
      //  }
      //}
    }
  }
}

@media (min-width: 1400px) {
  .tab-blocks-container {
    max-width: 1200px !important;
  }

  .block {
    min-width: 270px !important;
  }
}

@media(max-width: $mobile-width-breakpoint){
  .super-block-container{
    padding: var(--mobile-wrapper-content-padding-vertical);
    padding-top: 0;
    display: flex;
    flex-direction: column;

    picture{
      display: block;
      position: relative;
      min-width: 100%;
      height: max-content;

      .background-image{
        position: relative !important;
      }
    }

    .background-image{
      position: relative;
    }

    .tab-blocks-container{
      max-width: 100%;
      margin-top: -54px;
      padding: var(--mobile-wrapper-content-padding-horizontal);

      .super-block-indicators{
        display: none;
      }

      .sp-tabs{
        position: relative;
      }

      .sp-tabs-container{
        width: max-content;
        max-width: 100%;
        border-radius: 8px;
      }

      .tab-blocks{
        width: max-content;
        max-width: 100%;
        overflow-x: auto;

        &::-webkit-scrollbar{
          height: 0px;
        }
      }
    }
  }
}
