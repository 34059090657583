@import 'site/styles/mediaBreakpoints';

.search-site-page {

	.search-category {
		.header {
			display: flex;
			justify-content: space-between;
			margin-bottom: 24px;

			.goSection {
				cursor: pointer;
				border-bottom: 1px solid var(--basic-grey-3, #A8AFB8);
				opacity: 0.6;
			}
		}

		.model {
			cursor: pointer;
			margin: 24px 0;
			border-bottom: 1px solid var(--Gray-5, #E0E0E0);

			.title {
				color: var(--basic-black, #464a51);
			}

			.description {
				margin-bottom: 16px;
				overflow: hidden;
				color: var(--basic-grey-3, #a8afb8);
				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
			}
		}

		button {
			margin-bottom: 24px;
			font-weight: 400;
			width: 100%;
		}
	}
}

@media(max-width: $mobile-width-breakpoint){
	.search-site-page{
		padding: var(--mobile-wrapper-content-padding-all);
		background: var(--white);
		@include flex-column;
		gap: var(--mobile-standart-row-gap);

		.search-filters{
			margin: 0px;
			@include flex-column;
			gap: var(--mobile-standart-row-gap);

			.sp-tabs{

				.sp-tabs-container{
					margin: 0px;

					.sp-tabs-well{
						height: max-content;
						@include scroll-horizontal;
						@include flex-row;
						gap: var(--mobile-standart-row-gap);

						.sp-tab{
							padding: 0px;
						}
					}
				}
			}

			.selectors{
				margin: 0px;
				flex-direction: column;
				gap: var(--mobile-standart-row-gap);

				.wrapper-search-page-mobile-filters{
					width: 100%;
				}

				.sort{
					margin: 0px;
					width: 100%;

					& > span{
						display: none;
					}

					.sp-dropdown{
						width: 100% !important;
						margin: 0px;

						.filter{
							margin: 0px;
							width: 100%;
							@include flex-row;
							gap: var(--mobile-small-row-gap);
							flex-direction: row-reverse;
							justify-content: center;

							&::before{
								display: none;
							}

							.filter-content{
								margin: 0px;
								flex-grow: unset;
								flex-basis: auto;
								padding: 0px;

								.show-value{
									@include p1-mobile;
									margin: 0px;
									padding: 0px;
									justify-content: center;
								}
							}

							.filter-arrow{
								display: block;
								width: 0px;
								transform: none !important;

								&::before{
									content: '';
									display: block;
									width: 20px;
									height: 20px;
									background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='20' viewBox='0 0 21 20' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.41667 5C3.18655 5 3 5.18655 3 5.41667V6.25C3 6.48012 3.18655 6.66667 3.41667 6.66667H17.5833C17.8135 6.66667 18 6.48012 18 6.25V5.41667C18 5.18655 17.8135 5 17.5833 5H3.41667ZM5.91667 9.16667C5.68655 9.16667 5.5 9.35321 5.5 9.58333V10.4167C5.5 10.6468 5.68655 10.8333 5.91667 10.8333H15.0833C15.3135 10.8333 15.5 10.6468 15.5 10.4167V9.58333C15.5 9.35321 15.3135 9.16667 15.0833 9.16667H5.91667ZM8 13.75C8 13.5199 8.18655 13.3333 8.41667 13.3333H12.5833C12.8135 13.3333 13 13.5199 13 13.75V14.5833C13 14.8135 12.8135 15 12.5833 15H8.41667C8.18655 15 8 14.8135 8 14.5833V13.75Z' fill='%238291A4'/%3E%3C/svg%3E");
								}

								svg{
									display: none;
								}
							}
						}

						&.active{
							.filter{
								.filter-arrow{
									&:before{
										background: none;
									}
								}
							}
						}

					}
				}
			}
		}

		.search-category{
			@include flex-column;
			gap: var(--mobile-standart-row-gap);

			.header{
				margin: 0px;

				.h5{
					color: var(--black);
				}
			}

			.model{
				margin: 0px;
				@include flex-column;
				gap: var(--mobile-small-row-gap);
				padding-bottom: 16px;

				.title{
					@include h6-mobile;
				}

				.description{
					margin: 0px;
				}
			}

			button{
				margin: 0px;
			}
		}
	}
}