@import 'site/styles/mediaBreakpoints';

.submit-search-input {
    position: relative;

    .search-icon {
        position: absolute;
        left: 14px;
        top: 12px;
        z-index: 6;
    }

    .ui-input {
        padding-left: 50px;
        transition: .4s;
    }

    .sp-btn {
        position: absolute;
        top: 6px;
        right: 10px;
    }
}

@media(max-width: $mobile-width-breakpoint){
    .submit-search-input-with-icon{
        display: flex;
        flex-direction: row;

        input{
            border-radius: 32px 0px 0px 32px;
            border-right: none;
            padding-right: 0px !important;

        }

        .sp-btn{
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0px 10px 0px 16px !important;
            top: 0;
            right: 0;
            border-radius: 0px 32px 32px 0px !important;
            background: var(--white);
            border: 1px solid var(--grey-5);
            border-left: none;
            width: max-content;
            height: auto !important;
            box-shadow: none !important;
            outline: none;
            transition: 0.4s;

            svg{
                display: block;
                position: relative;
                top: 0px;
                left: 0px;
                width: 20px;
            }
        }

        .active-focus{
            border-color: var(--input-hover-border-color);
        }

        & > svg:first-child{
            display: none;
        }

    }
}

