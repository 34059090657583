@import 'site/styles/mediaBreakpoints';

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.portal {
  display: flex;
  justify-content: center;
  width: 1440px;
  position: absolute;

  .portal-map-main,
  .wrapper-content-portal-map-main {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    flex-direction: column;
    align-items: flex-start;
    background-color: #2770c6;
    width: 100%;
    height: 100vh;
    animation: slideInFromTop 0.5s ease-in-out;
    padding: 20px 120px;
    z-index: 1000;

    .portal-map-header {
      display: flex;
      flex-direction: column;
      width: 100%;

      .first-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .lang-lost-hear-button {
          display: flex;
          flex-direction: row;
          align-items: center;

          .portal-lang-bad-vision {
            display: flex;
            flex-direction: row;
            align-items: center;

            .portal-bad-vision {
              margin-left: 8px;
            }
          }

          .submit-an-uppeal {
            margin-left: 24px;

            .submit-an-uppeal-text {
              color: #1051a1;
              font-weight: 400;
              font-size: 14px;
              font-family: "Golos Text", sans-serif;
              margin-left: 8px;
            }
          }
        }
      }

      .second-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 16px;

        .links a {
          color: var(--white);
        }

        .portal-modal-close {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 112px;
          height: 38px;
          border: none;
          border-radius: 40px;
          padding: 6px 12px;
          background-color: transparent;
          color: #fff;
          font-family: "Golos Text", sans-serif;
          font-size: 12px;
          font-weight: 600;
          text-transform: uppercase;

          &:hover {
            background-color: #256dc4;
            cursor: pointer;
          }
        }
      }
    }
    .portal-search-container {
      width: 100%;
      margin: 48px 0 32px 0;
    }
    .portal-map {
      display: flex;
      flex-direction: row;
      background-color: #2770c6;
      height: 100vh;
      width: 100%;
      flex-grow: 1;
      overflow: hidden;

      .portal-block-header {
        font-family: "Golos Text", sans-serif;
        font-weight: 600;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.30);
        text-transform: uppercase;
      }

      .portals {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 240px;

        .portals-upper-part {
          display: flex;
          flex-direction: column;

          .portals-list {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin-top: 16px;
            width: 240px;

            .portals-list-item-container {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              margin-bottom: 16px;

              .portals-list-item-wrapper {
                display: flex;
                align-items: center;

                .portals-list-item-image {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 20px;
                }

                .portals-list-item {
                  display: flex;
                  justify-content: flex-start;
                  font-family: "Golos Text", sans-serif;
                  font-weight: 600;
                  font-size: 14px;
                  color: #fff;
                  text-decoration: none;
                  cursor: pointer;

                  .portals-list-item-text {
                    margin-left: 10px;
                  }

                  &:hover {
                    cursor: pointer;
                    transition: 0.2s ease-out;
                    color: #2fa8ff;
                  }
                }
              }
            }
          }
        }

        .portals-map-link {
          font-family: "Golos Text", sans-serif;
          font-weight: 600;
          font-size: 14px;
          color: #2fa8ff;
          cursor: pointer;
          transition: .4s;
          &:hover {
            opacity: .6;
          }
        }
      }

      .all-portals-map {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        border-left: 1px solid #cce3f0;
        padding-left: 20px;
        width: 100%;

        .portals-map-container {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          margin-top: 24px;
          overflow-x: hidden;
          overflow-y: auto;
          width: 100%;

          .portals-map-column {
            display: flex;
            flex-direction: column;
            padding-right: 10px;
            width: 25%;

              .portals-list-item-wrapper {
                display: flex;
                align-items: center;

                .portals-list-item-image {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 20px;
                  flex-shrink: 0;
                }

                .portals-list-item {
                  display: flex;
                  justify-content: flex-start;
                  font-family: "Golos Text", sans-serif;
                  font-weight: 600;
                  font-size: 14px;
                  color: #fff;
                  text-decoration: none;
                  cursor: pointer;

                  .portals-list-item-text {
                    //margin-left: 10px;
                  }

                  &:hover {
                    cursor: pointer;
                    transition: 0.2s ease-out;
                    color: #2fa8ff;
                  }
                }
              }
          }
        }
      }
    }

    .portals-bottom-buttons {
      display: flex;
      margin-top: 32px;
      gap: 24px;
    }
  }
}

@media(max-width: $tabled-width-breakpoint){
  .portal{

     .portal-map-main, .wrapper-content-portal-map-main{
       height: 100%;
       padding: var(--mobile-section-padding);
     }

    .wrapper-content-portal-map-main{
      justify-content: flex-start;
    }

    .portal-map{
      height: max-content !important;
    }

  }
}

@media(max-width: $mobile-width-breakpoint){
  .portal{

    .portal-map-main{
      padding: var(--mobile-wrapper-content-padding-all) !important;
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 100vh;
      justify-content: flex-start;

      &::-webkit-scrollbar{
        width: 0px;
      }
    }

    .wrapper-content-portal-map-main{
      padding: 0px !important;
      position: relative;
      height: max-content;
      gap: var(--mobile-standart-row-gap);

      .portal-map-header{

        .desktop-menu{
          margin: 0px;
          .links{
            display: none;
          }

          .portal-modal-close{
            position: absolute;
            right: 0px;
            top: 0px;
          }
        }

        .first-row{

          .lang-lost-hear-button{
            display: none;
          }
        }
      }

      .portal-search-container{
        margin: 0px;
      }

      .portal-map{
        flex-direction: column;
        height: max-content;
        width: 100%;
        gap: var(--mobile-standart-row-gap);

        .portals{
          width: 100%;
          @include flex-column;
          gap: var(--mobile-standart-row-gap);

          .portals-upper-part{
            @include flex-column;
            gap: var(--mobile-standart-row-gap);
            width: 100%;

            .portals-list{
              margin: 0px;
              width: 100%;
              @include flex-row;
              flex-wrap: wrap;
              gap: var(--mobile-standart-row-gap);

              .portals-list-item-container{
                margin: 0px;
                width: calc((100% - var(--mobile-standart-row-gap)) / 2);
              }
            }
          }
        }

        .all-portals-map{
          padding: 0px;
          border-left: none;
          gap: var(--mobile-standart-row-gap);
          width: 100%;

          .portals-map-container{
            margin: 0px;
            flex-direction: column;
            gap: var(--mobile-standart-row-gap);
            width: 100%;

            .portals-map-column{
              width: 100%;
              padding: 0px;
              @include flex-row;
              flex-wrap: wrap;
              gap: var(--mobile-standart-row-gap);

              .portals-map-item{
                margin: 0px;
                width: calc((100% - var(--mobile-standart-row-gap)) / 2);
              }
            }
          }
        }
      }

      .portals-bottom-buttons{
        margin-top: 0px;
        @include flex-column;
        gap: var(--mobile-standart-row-gap);
        width: 100%;

        a{
          width: 100%;
          @include flex-row;
          justify-content: center;

          svg{
            path{
              fill: var(--white);
            }
          }
        }

        button{
          width: 100%;
          @include flex-row;
          justify-content: center;
        }
      }
    }
  }
}