@import 'site/styles/mediaBreakpoints';

.columns-container {
	.header {
		position: fixed;
		top: 110px;
		z-index: 100;
	}

	.columns {
		display: flex;
		justify-content: space-between;

		.columns-item {
			.selects {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-right: 48px;
				color: var(--grey-2);

				.p2 {
					padding-right: 20px;
				}

				.county-select {
					width: auto;
					text-transform: none;
					border: none;
					background-color: var(--grey-6);
					color: var(--grey-2);
					border-radius: 16px;
					padding: 6px 6px 6px 12px;
					cursor: pointer;
				}
			}

			.mid-column {
				display: flex;
				height: 190px;
				overflow: hidden;
				position: relative;
				margin: 32px 24px 24px 0;

				&:last-child {
					margin-right: 0;
				}

				.images {
					min-width: 280px;
					height: 190px;
					overflow: hidden;
					border-radius: 8px;
					margin-right: 24px;

					img {
						width: 280px;
						height: 190px;
						object-fit: cover;
						border-radius: 8px;
						transition: linear 1s;
					}
				}

				.title {
					height: auto;
					padding-top: 10px;
					overflow: hidden;
					color: var(--black);
					display: -webkit-box;
					-webkit-line-clamp: 5;
					-webkit-box-orient: vertical;
					text-overflow: ellipsis;
				}

				.text-middle {
					word-wrap: break-word;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
					color: var(--grey-2);
					margin-top: 8px;
				}
				.date {
					max-width: 200px;
					color: var(--grey-2);
				}

				.info {
					position: absolute;
					bottom: 0;
					padding-top: 20px;
					z-index: 20;
					color: var(--grey-2);
				}
				&:hover {
					img {
						transform: scale(1.3);
					}

					.title {
						color: var(--deep-blue);
					}

					.text {
						color: var(--black);
					}
				}
			}
		}

		.sidebar {
			position: sticky;
			flex-basis: 285px;
			flex-shrink: 0;
			width: 285px;
			margin-bottom: 30px;

			.sidebar-news {
				display: flex;
				align-items: center;
				color: var(--deep-blue);
				text-transform: uppercase;
				.line {
					width: 100%;
					margin-left: 10px;
					border-bottom: 1px solid var(--deep-blue);
				}
			}
		}
	}
}

@media(max-width: $mobile-width-breakpoint){
	.columns-container{
		margin: 0px !important;
		padding: var(--mobile-wrapper-content-padding-all) !important;
		@include flex-column;
		gap: var(--mobile-standart-row-gap);

		.tab-bar{
			flex-direction: column-reverse;
			padding: 0px;
			gap: var(--mobile-standart-row-gap);

			.exchange-item{
				@include flex-column;
				gap: var(--mobile-small-row-gap);
				align-items: flex-end;

				.exchange-name{
					color: var(--grey-3);
				}

				.currencies{
					padding: 0px;
					@include flex-row;
					gap: var(--mobile-standart-row-gap);

					.currencies-item{
						padding: 0px;
						@include flex-row;
						gap: var(--mobile-small-row-gap);

						.course{
							@include flex-row;
							gap: var(--mobile-small-row-gap);

							p{
								padding: 0px;
								@include p1-mobile;
							}
						}
					}
				}
			}

			.items{
				max-width: 100%;
				@include scroll-horizontal;

				&::-webkit-scrollbar{
					height: 0px;
				}

				.link{

					&.active{
						border-color: var(--deep-blue);
					}

					span{
						@include h7-mobile;
					}
				}
			}
		}

		.wrapper-columns-mobile-filters{
			@include flex-column;
			gap: var(--mobile-standart-row-gap);

			.sort{

				& > span{
					display: none;
				}

				.sp-dropdown{
					width: 100% !important;
					margin: 0px;

					.filter{
						margin: 0px;
						width: 100%;
						@include flex-row;
						gap: var(--mobile-small-row-gap);
						flex-direction: row-reverse;
						justify-content: center;

						&::before{
							display: none;
						}

						.filter-content{
							margin: 0px;
							flex-grow: unset;
							flex-basis: auto;
							padding: 0px;

							.show-value{
								@include p1-mobile;
								margin: 0px;
								padding: 0px;
								justify-content: center;
							}
						}

						.filter-arrow{
							display: block;
							width: 0px;
							transform: none !important;

							&::before{
								content: '';
								display: block;
								width: 20px;
								height: 20px;
								background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='20' viewBox='0 0 21 20' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.41667 5C3.18655 5 3 5.18655 3 5.41667V6.25C3 6.48012 3.18655 6.66667 3.41667 6.66667H17.5833C17.8135 6.66667 18 6.48012 18 6.25V5.41667C18 5.18655 17.8135 5 17.5833 5H3.41667ZM5.91667 9.16667C5.68655 9.16667 5.5 9.35321 5.5 9.58333V10.4167C5.5 10.6468 5.68655 10.8333 5.91667 10.8333H15.0833C15.3135 10.8333 15.5 10.6468 15.5 10.4167V9.58333C15.5 9.35321 15.3135 9.16667 15.0833 9.16667H5.91667ZM8 13.75C8 13.5199 8.18655 13.3333 8.41667 13.3333H12.5833C12.8135 13.3333 13 13.5199 13 13.75V14.5833C13 14.8135 12.8135 15 12.5833 15H8.41667C8.18655 15 8 14.8135 8 14.5833V13.75Z' fill='%238291A4'/%3E%3C/svg%3E");
							}

							svg{
								display: none;
							}
						}
					}

					&.active{
						.filter{
							.filter-arrow{
								&:before{
									background: none;
								}
							}
						}
					}

				}
			}
		}

		.columns{
			flex-direction: column;
			gap: var(--mobile-standart-row-gap);

			.columns-item{
				@include flex-column;
				gap: var(--mobile-standart-row-gap);

				& > div:last-child{
					@include flex-column;
					gap: var(--mobile-standart-row-gap);

					.mid-column{
						margin: 0px;
						height: max-content;
						@include flex-row;
						gap: var(--mobile-small-row-gap);

						.images, img{
							min-width: 80px;
							min-height: 80px;
							max-height: 80px;
							max-width: 80px;
							margin: 0px;
						}

						& > div:last-child{
							@include flex-column;
							gap: var(--mobile-small-row-gap);

							& > div{
								padding: 0px;
								margin: 0px;
							}

							.p3{
								@include p3-mobile;
							}

							.h6{
								@include h6-mobile;
								verflow: hidden;
								display: -webkit-box;
								-webkit-line-clamp: 3;
								-webkit-box-orient: vertical;
								text-overflow: ellipsis;
							}

							.p2{
								@include p2-mobile;
							}

							.info{
								position: relative;
								@include p3-mobile;
							}
						}
					}
				}
			}

			& > div:last-child{
				@include flex-column;
				gap: var(--mobile-standart-row-gap);

				.sidebar{
					width: 100%;
					margin: 0px;
					flex-basis: unset;
					@include flex-column;
					gap: var(--mobile-standart-row-gap);

					.list-articles{
						@include flex-row;
						gap: var(--mobile-standart-row-gap);
						@include scroll-horizontal;

						.small-article{
							margin: 0px;
							padding: 0px;
							border: none;
							min-width: 200px;
							width: max-content;
							max-width: 282px;

							.title{
								word-wrap: break-word;
								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box;
								-webkit-line-clamp: 3;
								-webkit-box-orient: vertical;
							}
						}
					}
				}
			}
		}
	}
}
