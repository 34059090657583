@import 'site/styles/mediaBreakpoints';

.media-page {
	margin-bottom: 64px;
	padding-top: 30px;

	.header {
		position: fixed;
		top: 110px;
		z-index: 100;
	}

	.sp-tabs-container {
		font-size: 17px;
	}

	.no-record {
		color: var(--grey-2);
	}

	.media-list {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 24px;
	}
}

.media-card {
	height: 290px;
	cursor: pointer;

	.card-image,
	svg {
		width: 100%;
		height: 167px;
		border-radius: 8px;

		& img {
			width: 100%;
			height: 167px;
			object-fit: cover;
			border-radius: 8px;
		}
	}

	.card-description {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: calc(100% - 160px);
		gap: 8px;

		.card-view-date {
			font-size: 12px;
			font-weight: 400;
			color: var(--basic-grey-2, #8291a4);
		}

		.card-author {
			font-size: 14px;
			font-weight: 400;
			color: var(--basic-grey-2, #8291a4);
		}

		.card-name {
			font-size: 16px;
			font-weight: 600;
			color: var(--basic-black, #464a51);
		}

		.card-name,
		.card-author {
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			align-self: stretch;
			overflow: hidden;
			text-overflow: ellipsis;
			line-height: 150%;
		}
	}
}

.inner-page:has(.media-page) {
	width: auto;
	margin: 16px 120px;
}

.record-popup {
	display: flex;
	justify-content: center;
	border-radius: 24px !important;
	width: 925px;
	height: auto;
	overflow: visible;
	padding-left: 4px;

	.sp-popup-content {
		overflow: visible;
	}

	.media-slider-content {
		display: flex;
		justify-content: center;
		border-radius: 8px;
		width: 80%;
		overflow: visible;
		.media-slider-content::-webkit-scrollbar {
			width: 0;
		}

		.media-slider-image {
			border-radius: 8px;
		}
	}
}

.single-name {
	display: flex;
	flex-wrap: wrap;
	font-weight: 600;
	font-size: 20px;
	color: #464a51;
}

.single-author {
	font-weight: 400;
	font-size: 14px;
	color: #8291A4;
}

.single-source {
	font-weight: 400;
	font-size: 14px;
	color: #8291A4;
}

.single-view-date {
	font-weight: 400;
	font-size: 14px;
	color: #8291A4;
}

.single-text-value {
	font-weight: 400;
	font-size: 14px;
	color: #464A51;
}

@media(max-width: $mobile-width-breakpoint){
	.media-page{
		padding: var(--mobile-wrapper-content-padding-all);
		@include flex-column;
		gap: var(--mobile-standart-row-gap);

		.wrapper-documents-mobile-filters{
			@include flex-column;
			gap: var(--mobile-standart-row-gap);

			.sort{

				& > span{
					display: none;
				}

				.sp-dropdown{
					width: 100% !important;
					margin: 0px;

					.filter{
						margin: 0px;
						width: 100%;
						@include flex-row;
						gap: var(--mobile-small-row-gap);
						flex-direction: row-reverse;
						justify-content: center;

						&::before{
							display: none;
						}

						.filter-content{
							margin: 0px;
							flex-grow: unset;
							flex-basis: auto;
							padding: 0px;

							.show-value{
								@include p1-mobile;
								margin: 0px;
								padding: 0px;
								justify-content: center;
							}
						}

						.filter-arrow{
							display: block;
							width: 0px;
							transform: none !important;

							&::before{
								content: '';
								display: block;
								width: 20px;
								height: 20px;
								background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='20' viewBox='0 0 21 20' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.41667 5C3.18655 5 3 5.18655 3 5.41667V6.25C3 6.48012 3.18655 6.66667 3.41667 6.66667H17.5833C17.8135 6.66667 18 6.48012 18 6.25V5.41667C18 5.18655 17.8135 5 17.5833 5H3.41667ZM5.91667 9.16667C5.68655 9.16667 5.5 9.35321 5.5 9.58333V10.4167C5.5 10.6468 5.68655 10.8333 5.91667 10.8333H15.0833C15.3135 10.8333 15.5 10.6468 15.5 10.4167V9.58333C15.5 9.35321 15.3135 9.16667 15.0833 9.16667H5.91667ZM8 13.75C8 13.5199 8.18655 13.3333 8.41667 13.3333H12.5833C12.8135 13.3333 13 13.5199 13 13.75V14.5833C13 14.8135 12.8135 15 12.5833 15H8.41667C8.18655 15 8 14.8135 8 14.5833V13.75Z' fill='%238291A4'/%3E%3C/svg%3E");
							}

							svg{
								display: none;
							}
						}
					}

					&.active{
						.filter{
							.filter-arrow{
								&:before{
									background: none;
								}
							}
						}
					}

				}
			}
		}

		.sp-tabs{
			.sp-tabs-container{
				@include scroll-horizontal;
				height: max-content;
				margin: 0px;

				.sp-tabs-well{
					height: max-content;
					gap: var(--mobile-standart-row-gap);
					@include flex-row;

					.sp-tab{
						padding: 0px 0px 16px 0px;
						line-height: normal;
						height: max-content;

						&.active{
							font-weight: 600;
							color: var(--deep-blue);
							border-color: var(--deep-blue);
						}
					}
				}
			}
		}

		.media-list{
			@include flex-column;
			gap: var(--mobile-standart-row-gap);

			.media-card{
				@include flex-column;
				gap: var(--mobile-small-row-gap);
				height: max-content;

				.card-image{
					height: auto;
					max-height: 191px;

					img{
						height: auto;
						max-height: 191px;
					}
				}

				.card-description{
					gap: var(--mobile-small-row-gap);
					height: max-content;

					.card-name{
						@include h7-mobile;
					}

					.card-author{
						@include p2-mobile;
					}

					.card-view-date{
						@include p3-mobile;
					}
				}
			}
		}
	}

	body:has(.record-popup){
		overflow: hidden;
	}

	.record-popup{
		padding: 0px;
		min-width: 100vw !important;
		max-width: 100vw !important;
		max-height: 100vh !important;
		min-height: 100vh !important;
		border-radius: 0px !important;

		.sp-popup-content{
			padding: 0px;
			padding: var(--mobile-wrapper-content-padding-vertical);
			max-height: 100% !important;
			@include scroll-vertical;

			.media-single{
				padding: 0px;
				width: 100%;

				& > div{
					padding: var(--mobile-wrapper-content-padding-horizontal);
				}

				.wrapper-back{
					padding-bottom: 8px;

					.wrapper-control-back{
						@include flex-row;
						align-items: center;
						max-width: max-content;
						padding: 8px 16px 8px 12px;
						background: var(--grey-6);
						border-radius: 50px;
						gap: var(--mobile-small-row-gap);
						color: var(--grey-2);
					}
				}

				.single-content{
					padding: 0px;

					.video-player-wrapper{
						video, iframe, & > div{
							width: 100%  !important;
							height: auto !important;
							border-radius: 0px;
						}

						iframe{
							min-height: 210px;
						}
					}

					audio{
						padding: var(--mobile-wrapper-content-padding-horizontal);
					}

					.media-slider-content{

						img{
							max-width: 100%;
							height: auto;
						}
					}
				}

				.single-bar{
					@include flex-column;
					gap: var(--mobile-small-row-gap);
					align-items: flex-start;

					.single-labels{
						@include flex-row;
						flex-wrap: wrap;
						gap: var(--mobile-small-row-gap);

						span{
							margin: 0px;
						}
					}
				}

				.single-name{
					@include h5-mobile;
				}

				.single-author, .single-source, .single-view-date{
					@include p2-mobile;
				}

				.single-description{
					@include p1-mobile;
				}
			}
		}
	}
}