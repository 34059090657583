$mobile-width-breakpoint: 640px;
$tabled-width-breakpoint: 1200px;

@mixin h1-mobile{
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

@mixin h2-mobile{
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  text-transform: uppercase;
}

@mixin h3-mobile{
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

@mixin h4-mobile{
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

@mixin h5-mobile{
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

@mixin h6-mobile{
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

@mixin h7-mobile{
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

@mixin p1-mobile{
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

@mixin p2-mobile{
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

@mixin p2-mobile-bold{
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

@mixin p3-mobile{
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

@mixin p4-mobile{
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: 0.48px;
  text-transform: uppercase;
}

@mixin p5-mobile{
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

@mixin flex-column{
  display: flex;
  flex-direction: column;
}

@mixin flex-row{
  display: flex;
  flex-direction: row;
}

@mixin scroll-horizontal{
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar{
    height: 0px;
  }
}

@mixin scroll-vertical{
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar{
    width: 0px;
  }
}