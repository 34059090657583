.news-filters {
	display: flex;
	flex-direction: column;
	gap: 25px;

	.filter {
		font-weight: 400;
	}

	.selectors-news {
		display: flex;
		justify-content: flex-end;

		.sort {
			.p2 {
				color: var(--black);
				font-weight: 400;
			}
		}
	}

	.news-filters-date {
		display: flex;
		flex-direction: row;
		gap: 10px;
	}
}
