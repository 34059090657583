.suggest-input {
	input::placeholder {
		color: #aaa;
	}
}

.suggestions-popup {
	transform: translate(0, -1px);

	.suggestions {
		min-width: 200px;
		background: #fff;
		border: 1px solid #ccc;
		border-radius: 4px;
		box-shadow: 0 3px 5px rgba(#000, 0.15);

		.suggestion {
			height: 30px;
			display: flex;
			align-items: center;
			border-bottom: 1px solid #ccc;
			padding: 0 5px;
			cursor: pointer;

			&:last-child {
				border: 0;
			}

			&:hover {
				background: #eee;
			}

			&.current {
				background: #0af;
				color: #fff;
			}
		}
	}
}
