@import 'site/styles/mediaBreakpoints';

.events-page {
	.event-list-table {
		thead {
			th {
				background: transparent;

				&:not(:last-child) {
					border-right: 1px solid var(--primary-light, #CCE3F0);
				}

				.column-title, a {
					color: var(--grey-1);
				}
			}
		}

		tbody {
			.border td {
				background: #CCE3F0;
			}

			tr {
				border-bottom: 0;

				td {
					padding: 32px 8px;

					.string-value {
						white-space: wrap;
						color: var(--grey-1);
					}
				}
			}
		}
	}

	.date-location {
		color: var(--deep-blue);
	}

	.event-organizer {
		.title {
			color: var(--black);
			margin-bottom: 8px;
		}
	}
}

@media(max-width: $mobile-width-breakpoint){
	.events-page{

		.wrapper-list-event{
			
			.sp-table-container{
				@include scroll-horizontal;

				table{
					margin-bottom: 0px;
				}

				.column-title{
					@include p1-mobile;
				}

				tbody{
					background: transparent;

					tr{
						height: max-content !important;

						td{
							padding-top: 24px !important;
							padding-bottom: 0px !important;
							height: max-content !important;

							div{
								height: max-content !important;
							}

							.string-value{
								@include p2-mobile;

								.event-organizer{
									.h6{
										@include h6-mobile;
										word-wrap: break-word;
										overflow: hidden;
										text-overflow: ellipsis;
										text-align: left;
										display: -webkit-box;
										-webkit-line-clamp: 3;
										-webkit-box-orient: vertical;
									}
								}
							}
						}

						&.border{
							td{
								padding: 0px !important;
								padding-bottom: 1px !important;
							}
						}
					}
				}

				tr{
					th:nth-child(3), td:nth-child(3){
						width: 200px !important;
					}
				}

			}

		}
	}
}