@import 'client/styles/mixins';

.about-republic-card-sidebar {
	display: flex;
	gap: 16px;
	border-bottom: 1px solid var(--light);
	padding-bottom: 16px;
	width: 100%;

	img {
		width: 80px;
		height: 80px;
		border-radius: 8px;
		object-fit: cover;
	}

	.title-description {
		display: flex;
		flex-direction: column;
		gap: 8px;

		.title {
			@include H7;
			@include line-clamp;
			-webkit-line-clamp: 3;
			color: var(--black);
		}

		.description {
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 150%;
			color: var(--grey-1);
			@include line-clamp;
			-webkit-line-clamp: 3;
		}
	}
}