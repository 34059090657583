@import 'site/styles/mediaBreakpoints';

.popular-services-big {
  margin-bottom: 48px !important;
  overflow: hidden;
  border-radius: 8px;

  .big-popular-services-header {
    padding-bottom: 16px;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    color: var(--black);
  }

  .big-popular-services-items {
    display: flex;
    transition: all 300ms;

    .big-popular-services-item {
      width: 100%;
      height: 357px;

      .big-popular-services-container {
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .big-popular-services {
          position: relative;
          width: 1200px;
          height: 357px;
          display: flex;
          border-radius: 8px;
          border: 1px solid var(--grey-4);
          background: var(--white);

          .big-popular-services-image {
            border-bottom-left-radius: 8px;
            border-top-left-radius: 8px;
            overflow: hidden;

            img {
              z-index: 5;
            }
          }

          .big-popular-services-blocks {
            align-self: stretch;
            z-index: 2;

            .big-block-column {
              display: grid;
              align-content: center;
              position: absolute;
              right: 0;
              top: 43%;
              transform: translate(0, -50%);
              margin: 24px;
              gap: 24px;

              :nth-child(n + 1) {
                grid-row: 1 span;
              }

              :nth-child(n + 3) {
                grid-row: 2;
              }

              :nth-child(n + 5) {
                grid-row: 3;
              }

              .big-block {
                display: flex;
                justify-content: space-between;
                width: 422px;
                height: 87px;
                padding: 12px 16px;
                align-items: center;
                gap: 16px;
                border-radius: 8px;
                border: 1px solid var(--grey-5);
                background: var(--white);

                &:hover {
                  cursor: pointer;
                  background: var(--grey-6);
                  .big-text-block {
                    color: var(--deep-blue);
                  }
                }

                .big-icon-block {
                  width: 36px;
                  height: 36px;
                  border-radius: 8px;
                }

                .big-text-block {
                  color: var(--black);
                  word-wrap: break-word;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-align: left;
                  display: -webkit-box;
                  -webkit-line-clamp: 3;
                  -webkit-box-orient: vertical;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 150%;
                }
              }
            }
          }
        }
      }
    }
  }

  .big-popular-services-indicators {
    display: flex;
    list-style: none;
    margin-top: 16px;
    justify-content: center;
  }
  .big-popular-services-indicators li {
    height: 10px;
    width: 10px;
    background: var(--grey-4);
    border-radius: 50%;
    margin-right: 16px;
    cursor: pointer;
    opacity: 0.7;
    transition: all 300ms;
  }
  .big-popular-services-indicators li:hover {
    opacity: 1;
    background: var(--deep-blue);
  }
  .big-popular-services-indicators li.active {
    background: var(--deep-blue);
  }
}

.big-popular-services-container {
  width: 1200px;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 8px;

  .big-popular-services {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    color: #fff;
    max-width: 100%;

    &.linked:hover {
      border: 0;
      box-shadow: none;
      transform: none;
    }
  }
}

@media (max-width: 1199px) {
  .big-top-slide-container {
    display: none;
  }
}

@media(max-width: $mobile-width-breakpoint){
  .popular-services-big {
    padding: var(--mobile-section-padding);
    @include flex-row;
    gap: var(--mobile-standart-row-gap);
    flex-wrap: wrap;
    margin-bottom: 0px !important;

    .big-popular-services-header{
      padding: 0px;
      @include p2-mobile-bold;
      text-transform: uppercase;
      color: var(--deep-blue);
      width: 100%;
      @include flex-row;
      align-items: center;
      gap: 10px;

      &:after{
        content: '';
        flex-grow: 1;
        height: 1px;
        background: var(--deep-blue);
      }
    }

    .big-popular-services-items{
      width: 100%;

      .big-popular-services-container{
        width: 100%;

        .big-popular-services{

          .big-popular-services-image{
            display: none;
          }

          .big-popular-services-blocks{
            width: 100%;

            .big-block-column{
              max-width: 100%;
              overflow-x: auto;
              @include flex-row;
              gap: var(--mobile-small-row-gap);

              &::-webkit-scrollbar{
                height: 0px;
              }

              a{
                display: flex;
                flex-direction: column;
                gap: 12px;
                align-items: flex-start;
                width: 121px;
                min-width: 121px;
                padding: 8px 12px;
                border-radius: 8px;
                border: 1px solid var(--grey-5);
                background: var(--white);

                div{
                  @include p2-mobile;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  align-self: stretch;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-align: left;
                }

                img:first-child{
                  width: 28px;
                }
              }
            }
          }
        }
      }
    }

    .big-popular-services-indicators{
      display: none;
    }
  }
}
