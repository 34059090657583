@import 'site/styles/mediaBreakpoints';
@import "site/styles/mixins";

.widget {
//для показа, потом удалить
  position: absolute;
  top: 200px;
  z-index: 99;

  margin: 0 auto;
  width: 384px;
  height: 500px;
  border-radius: 8px;
  border: 1px solid var(--translucent-white-60);
  background: var(--translucent-white-30);
  backdrop-filter: blur(12px);

  .widget-weather {
    width: 384px;
    height: 157px;
    padding: 16px 24px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    background: rgba(16, 81, 161, .3);
    backdrop-filter: blur(12px);

    .city-date {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      align-self: stretch;
      color: var(--white);
      padding-bottom: 16px;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;

      .city-weather {
        .city {
          @include H7;
        }
        .city-county {
          @include P3;
        }
      }

      .date-weather-day-of-week {
        @include P2;
      }
    }
  }

  .widget-text {
    width: 384px;
    height: 343px;
    padding: 16px 24px;

    .text-header {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      color: var(--deep-blue);
      padding-bottom: 4px;
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

    }

    .text {
      padding-top: 4px;
      height: 250px;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      color: var(--black);
      overflow-y: auto;
    }
  }
}
* {
  scrollbar-width: thin;
  scrollbar-color: var(--grey-5);
}

/* Работает в Chrome, Edge и Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: none;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--grey-5);
  border-radius: 8px;
}

@media(max-width: $mobile-width-breakpoint){
  .widget{
    position: relative;
    width: 100%;
    height: auto;
    top: 0;
    background: none;
    padding: 0px;

    .date-weather-day-of-week{
      @include p2-mobile;
    }

    .date-weather{
      @include h6-mobile;
    }

    .widget-weather{
      width: 100%;
      background: none;
      filter: none;
      padding: var(--mobile-wrapper-content-padding-all);
      height: max-content;

      .weather-container{
        height: 52px;
      }
    }

    .wrapper-slide-text{

      .content-slide-text{

        .widget-text{
          width: 100%;
          padding: var(--mobile-wrapper-content-padding-all);
          height: max-content;

          .text{
            height: max-content;
          }
        }
      }

      .control-slide-text{
        padding: 6px;
        display: flex;
        justify-content: center;
      }
    }
  }
}