@import 'site/styles/mediaBreakpoints';

.secondary-layout {
    background-color: var(--grey-6);
    position: relative;
    max-width: 100vw !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 64px 0;

    &.mountains {
        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 50%;
            background: no-repeat url("/static/mountains.svg");
            background-size: cover;
            bottom: 0;
        }
    }
}

@media(max-width: $tabled-width-breakpoint){
    .secondary-layout{
        max-width: unset !important;
    }
}
