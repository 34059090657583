.search-filters {
	margin-bottom: 32px;

	.sp-tabs-container {
		.sp-tab {
			color: var(--grey-2);
			padding: 0 5px;

			.tab-category {
				font-size: 16px;
			}

			&.active {
				color: var(--deep-blue, #1051A1);
				border-color: var(--deep-blue, #1051A1);
				.tab-category {
					font-weight: 600;
					font-size: 18px;
				}
			}
		}
	}

	.search-container {
		margin-bottom: 24px;
	}

	input[type="checkbox"] {
		width: 20px;
		margin-right: 10px;
	}

	.selectors {
		display: flex;
		align-items: center;
		column-gap: 8px;
		margin: 24px 0;
	}

	.sort {
		display: flex;
		align-items: center;
		gap: 8px;
		margin-left: auto;
	}
}