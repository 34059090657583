@import 'site/styles/mediaBreakpoints';

.calendar-toolbar {
	display: flex;
	justify-content: space-between;
	margin: 32px 0;

	.calendar-navigate {
		svg {
			color: var(--grey-3);
		}
	}
	.sp-btn + .sp-btn {
		margin-left: 8px;
	}
	label {
		color: var(--grey-3);
	}
}

.event-calendar {
	width: 100%;
	border-collapse: collapse;
	color: var(--grey-3);

	&.day-view {
		th {
			text-align: left;
		}

		tbody {
			display: flex;
			flex-direction: column;

			.day-cell {
				margin-top: 16px;
				margin-bottom: 8px;
			}

			.more {
				padding-bottom: 16px;
				border-bottom: 1px solid var(--grey-6);
			}
		}
	}

	.event-day {
		display: flex;
		flex-direction: column;
		height: 100%;
		padding: 16px;

		.show-more {
			margin-top: auto;
			cursor: pointer;
			border-radius: 24px;
			background: var(--grey-6, #F1F7FA);
			padding: 8px 12px;
			text-align: center;
		}
	}

	.days-cell {
		display: flex;

		.day-cell {
			color: var(--deep-blue);
		}
		.day-cell-not-current {
			color: var(--grey-3);
		}
		.current-day-cell {
			padding-left: 10px;
		}
	}

	.today {
		background: var(--white);
		box-shadow: 0 0 3px var(--grey-3);
		border-radius: 16px;
	}

	.single-event {
		display: flex;
		flex-direction: column;
		height: auto;
		cursor: pointer;

		.title {
			color: var(--grey-2);
		}
		&:not(:nth-last-child(2)) {
			.more {
				border-bottom: 1px solid var(--grey-6);
			}
		}
		.text {
			color: var(--black);
			line-height: initial;
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 5;
			-webkit-box-orient: vertical;
		}
		.more {
			padding-bottom: 16px;
		}

		&:hover {
			.text {
				color: var(--deep-blue);
			}
		}
	}
	th,
	td {
		border: 1px solid #ccc;
		padding: 5px;
		width: 120px;
	}
	th:first-child,
	td:first-child {
		border-left: none;
	}

	th:last-child,
	td:last-child {
		border-right: none;
	}

	th {
		border-top: none;
		font-weight: 400;
	}
}

@media(max-width: $mobile-width-breakpoint){
	.events-page{
		div.event-calendar{
			@include flex-column;
			gap: var(--mobile-medium-row-gap);

			.calendar-toolbar{
				margin: 0px;
				flex-direction: column;
				gap: var(--mobile-small-row-gap);

				.row-wrapper{
					@include flex-row;
					justify-content: space-between;
					align-items: center;

					label{
						@include p1-mobile;
					}

					& > div{
						@include flex-row;
						gap: var(--mobile-small-row-gap);

						button{
							width: 67px;
							height: 36px;
							@include flex-row;
							justify-content: center;
							align-items: center;
							margin: 0px;
						}
					}
				}
			}

			.wrapper-content-calendar{
				max-width: 100%;
				@include scroll-horizontal;

				.event-calendar{
					tr, td{
						min-width: 170px;
					}

					th{
						@include p2-mobile;
					}

					td{
						padding: 0px;
					}

					.event-day{
						gap: var(--mobile-small-row-gap);

						.single-event{
							gap: var(--mobile-small-row-gap);
						}
					}
				}
			}
		}
	}
}

