@import 'site/styles/mediaBreakpoints';

.support-measure-page {
	.header {
		position: fixed;
		top: 110px;
		z-index: 100;
	}

	.support-measure-container {
		display: flex;
		// max-width: 1350px;
		gap: 24px;
		padding: 16px 0 64px 0;
		flex-direction: row;
		justify-content: center;
		align-self: stretch;
		width: 100%;

		.sp-tabs {
			.sp-tab {
				padding: 0;
				margin-right: 16px;
				font-weight: 400;
				max-width: 200px;
				overflow: hidden;
				text-overflow: ellipsis;

				&.active {
					color: var(--deep-blue);
					font-weight: 600;
				}
			}
		}

		.support-measure-item {
			flex-grow: 1;
			overflow: hidden;
			padding-right: 24px;
		}

		.support-measure-all {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 24px;
			margin-top: 24px;

			.support-measure-items {
				align-items: flex-start;
				gap: 24px;
				flex: 1 0 0;

				.topic {
					font-size: 10px;
					font-style: normal;
					font-weight: 500;
					line-height: 150%;
					letter-spacing: 0.4px;
					text-decoration-line: underline;
					text-transform: uppercase;
					color: var(--grey-2);
					padding-bottom: 8px;
				}

				.title {
					font-size: 16px;
					font-style: normal;
					font-weight: 600;
					line-height: 140%;
					color: var(--black);
					margin-bottom: 8px;
					word-wrap: break-word;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
				}

				.description {
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 150%;
					color: var(--grey-1);

					word-wrap: break-word;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
				}
			}
		}

		.sidebar {
			position: sticky;
			flex-basis: 285px;
			flex-shrink: 0;
			width: 285px;
			margin-bottom: 16px;

			.tags-container {
				display: flex;
				flex-wrap: wrap;
				padding-bottom: 24px;

				.tags {
					height: 25px;
					cursor: pointer;
					color: var(--black);
					opacity: 0.6;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 150%;
					text-decoration: underline;
					padding-right: 10px;
				}
			}

			.sidebar-support-measure {
				display: flex;
				align-items: center;
				color: var(--deep-blue);
				text-transform: uppercase;
				padding-bottom: 24px;
				.line {
					width: 100%;
					margin-left: 10px;
					border-bottom: 1px solid var(--deep-blue);
				}
			}

			.right {
				text-align: right;
				cursor: pointer;
			}
		}
	}
}

@media(max-width: $mobile-width-breakpoint){
	.support-measure-page{
		background: var(--white);

		.support-measure-container{
			flex-direction: column-reverse;
			gap: var(--mobile-standart-row-gap);
			padding: var(--mobile-wrapper-content-padding-horizontal);
			margin-bottom: 16px;

			.sidebar{
				width: 100%;
				@include flex-column;
				gap: var(--mobile-standart-row-gap);

				.sidebar-support-measure{
					padding: 0px;
				}

				.tags-container{
					padding: 0px;
					gap: 10px;

					.tags{
						padding: 0px;
					}
				}

				.right{
					display: none;
				}

				.popular-support-measure{
					@include flex-row;
					gap: var(--mobile-standart-row-gap);
					@include scroll-horizontal;

					.support-measure-small{
						min-width: 200px;
						width: max-content;
						max-width: 282px;
						@include flex-column;
						gap: var(--mobile-small-row-gap);
						padding: 0px;

						& > div{
							padding: 0px;
						}
					}
				}
			}

			& > div{
				.sidebar:last-child{
					margin: 0px;
				}
			}

			.support-measure-item{
				padding: 0px;

				.support-measure-all{
					margin: 0px;
					width: 100%;
					@include flex-column;
					gap: var(--mobile-standart-row-gap);

					a{

						.support-measure-items{
							height: max-content;
						}
					}
				}
			}
		}
	}
}
