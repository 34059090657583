.top-banner-container {
  width: 894px;
  margin: 0 auto;
  background: var(--white);
  text-align: center;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 8px;

  .banner-894x280 {
    display: flex;
    //position: static;
    width: 100%;
    height: 100%;
    overflow: hidden;
    color: #fff;
    max-width: 100%;

    .text-block {
      justify-content: center;
      align-items: stretch;
      text-align: left;
      width: 50%;
      padding: 32px;

      .header {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        color: var(--black);
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .description {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        color: var(--black);
        padding-top: 16px;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .text-block-button-container {
        position: absolute;
        bottom: 0;
        padding-bottom: 32px;
        .text-block-button {
          display: flex;
          height: 48px;
          padding: 12px 24px;
          align-items: center;
          border: none;
          border-radius: 24px;
          background: var(--grey-6);
          color: var(--grey-1);
          cursor: pointer;
        }
      }
    }

    &.linked:hover {
      border: 0;
      box-shadow: none;
      transform: none;
    }

    div:first-child {
      width: 100%;
    }
  }
}

@media (max-width: 1199px) {
  .top-banner-container {
    display: none;
  }
}
