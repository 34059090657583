@import 'site/styles/mediaBreakpoints';

.news-main {
	display: flex;
	flex-direction: column;
	gap: 32px;

	.header {
		display: flex;
		align-items: center;

		.right {
			margin-left: auto;
		}

		.link {
			text-decoration: none;
			color: #888;

			svg {
				display: inline-block;
				vertical-align: middle;
			}
		}

		a {
			text-decoration: none;
		}
	}

	.news-container {
		display: grid;
		grid-column-gap: 24px;
		gap: 24px;
		grid-template-columns: 3fr 1fr;

		.left {
			grid-column: span 1 / span 3;

			button {
				margin-top: 24px;
				font-weight: 400;
				width: 100%;
			}
		}

		.right {
			grid-column: span 1 / span 1;

			display: flex;
			flex-direction: column;
			gap: 16px;
		}
	}

	.articles {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: auto;
		grid-column-gap: 24px;
		grid-row-gap: 24px;

		.big-article {
			grid-column-start: span 2;
		}
	}

	.telegram-banner {
		background-color: #8ec2e9;
		min-width: 0;
		width: 100%;
		min-height: 361px;
	}

	.top {
		color: #1051a1;
		font-size: 12px;
		font-family: Golos Text;
		font-weight: 600;
		text-transform: uppercase;
		line-height: 16.8px;
		letter-spacing: 0.48px;
		display: grid;
		grid-template-columns: 3fr 1fr;
		gap: 16px;

		& > div {
			display: flex;
			gap: 10px;
		}
		.line {
			flex: 1;
			display: flex;
			align-items: center;
			&::after {
				content: "";
				width: 100%;
				height: 1px;
				background-color: #1051a1;
			}
		}
	}
	.news-nav {
		display: flex;
		gap: 160px;
	}
}

@media(max-width: $mobile-width-breakpoint){
	.news-main{
		padding: 24px 16px;
		max-width: 100% !important;
		gap: var(--mobile-standart-row-gap);

		.top{
			display: flex;
			line-height: 140%;

			.right{
				display: none;
			}

			.left{
				flex-grow: 1;
				align-items: center;

				svg{
					width: 20px;
					height: 20px;

					path{
						fill: var(--deep-blue);
					}
				}
			}
		}

		.news-nav{
			max-width: 100%;
			overflow-x: auto;
			gap: var(--mobile-medium-row-gap);
			align-items: center;

			&::-webkit-scrollbar{
				height: 0px;
			}

			.sp-tabs{
				width: max-content;

				.sp-tabs-container{
					margin: 0px;
					width: max-content;

					.sp-tabs-well{
						width: max-content;
					}
				}
			}

			.news-filters{

				.selectors{
					padding-top: 0px;
				}
			}
		}

		.news-container{
			display: flex;
			flex-direction: column;

			.left{
				display: flex;
				flex-direction: column;
				gap: var(--mobile-standart-row-gap);

				.sp-btn{
					margin-top: 0px;
				}

				.articles{
					display: flex;
					flex-direction: column;
					gap: var(--mobile-standart-row-gap);
				}
			}

		}

		.news-more{
			max-width: max-content;
			overflow-x: auto;

			&::-webkit-scrollbar{
				height: 0px;
			}

			.articles-grid{
				display: flex;
				flex-direction: row;
				gap: var(--mobile-standart-row-gap);

				.small-article{
					min-width: 281px;
					margin-bottom: 0px;
					display: flex;
					flex-direction: column;
					justify-content: space-between;

					&:last-child{
						border-bottom: 1px solid var(--grey-4);
					}

					.title{
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 3;
						-webkit-box-orient: vertical;
						@include h7-mobile
					}

					.info{

						.p3{
							@include p3-mobile;
						}
					}
				}
			}
		}
	}
}
