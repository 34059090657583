@import 'site/styles/mixins';

.weather-container {
  height: 64px;
  border-radius: 8px;
  background: linear-gradient(45deg, #1051A1 0%, #387ACC 100%);
  display: flex;
  align-items: center;

    .weather-items {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    padding: 0 5px;
    flex-grow: 1;
    color: var(--white);

    .pressure-wind {
      display: flex;
      flex-direction: column;
      gap: 5px;
      @include P3;
    }

    .temperature {
      font-size: 40px;
    }
  }
}