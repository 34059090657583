.banners-250x250-container {
	margin: 30px auto;
	//background: #f5f5f5;
	padding: 30px 0;

	.banners-250x250 {
		width: 1200px;
		height: 250px;
		display: flex;
		margin: 0 auto;
		justify-content: space-between;

		.banner-250x250 {
			// width: 250px;
			// height: 250px;
			// display: flex;
			// align-items: center;
			// justify-content: center;
			// text-align: center;
			// background: #ddd;
			// margin: 0 auto;
		}
	}
}

@media (max-width: 1199px) {
	.banners-250x250-container {
		display: none;
	}
}
