.topics-wrapper {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	.caret-left_right {
		display: flex;
		border: 2px solid #f1f7fa;
		border-radius: 16px;
	}

	.topics-list {
		margin: 0 !important;
		.topics-title {
			font-size: 20px;
			font-weight: bold;
			color: #ddd;
		}

		a {
			text-decoration: none;
			color: #5e5e5e;
			&:hover {
				color: #5e5e5e;
			}
		}
		margin-bottom: 15px;
		color: #5e5e5e;

		.topic {
			display: inline-block;
			vertical-align: top;
			height: 30px;
			line-height: 30px;
			padding-right: 15px;
			border-radius: 5px;
			cursor: pointer;
			margin-right: 5px;
			font-size: 16px;

			&:hover {
				color: var(--deep-blue);
			}

			&.selected {
				color: var(--deep-blue);
			}
		}
	}
}
