.columns-filters {

    .filter {
      font-weight: 400;
    }

    .selectors {
      display: flex;
      justify-content: space-between;

      padding-top: 16px;

      :nth-child(1n) {
        margin-right: 8px;
      }

      .sort {
        .p2 {
          color: var(--black);
          font-weight: 400;
        }
      }
    }
  }
