@import 'site/styles/mediaBreakpoints';

.support-measure-single-container {
	display: flex;
	z-index: 99;

	.support-measure-single-page {
		width: 100%;
		margin-right: 24px;

		a {
			color: var(--black);
			text-decoration: none;
		}

		.support-measure-head {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;

			.category-subcategory {
				display: flex;
				padding-bottom: 8px;
				font-weight: 500;
				color: var(--grey-3);

				.category {
					padding-right: 10px;
					border-right: 1px solid var(--grey-3);
					color: var(--grey-3);
					cursor: pointer;
				}

				.subcategory {
					padding-left: 10px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			.to-portal {
				display: flex;
				align-items: center;
				padding-right: 24px;

				.go-portal {
					padding-left: 10px;
					color: var(--grey-3);
					white-space: nowrap;
					cursor: pointer;
				}
			}
		}

		.support-measure-single-page-item {
			color: var(--black);

			.item-title {
				font-size: 32px;
				font-style: normal;
				font-weight: 600;
				line-height: 140%;
				margin-bottom: 24px;
			}

			.item-topic {
				font-size: 10px;
				font-style: normal;
				font-weight: 500;
				line-height: 150%; /* 15px */
				letter-spacing: 0.4px;
				text-transform: uppercase;
        color: var(--grey-2);
				gap: 24px;
				margin-bottom: 24px;
			}

			.description-title,
			.additional-info-title,
			.evaluate-measure-title {
				font-size: 20px;
				font-style: normal;
				font-weight: 600;
				line-height: 140%;
				margin-bottom: 24px;
			}

			.description {
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 150%;
				margin-bottom: 24px;
			}

			.useful-info-choice {
				display: flex;
				justify-content: space-between;
				margin-bottom: 24px;
				padding: 16px 24px;
				align-items: center;
				gap: 8px;
				align-self: stretch;
				border-radius: 8px;
				background: var(--grey-6);

				.useful-info-title {
					font-size: 18px;
					font-style: normal;
					font-weight: 600;
					line-height: 140%;
				}

				.button-contanier {
					display: flex;

					.support-measure-info {
						padding-top: 10px;
					}

					.support-measure-poll-form {
						display: flex;
						justify-content: center;
					}
				}
			}

			.item-button,
			.useful-info-button {
				text-align: center;
				font-weight: 400;

				&:hover {
					background: var(--deep-blue-hover);
				}

				&:active {
					background: var(--grey-3);
				}
			}

			.item-button {
				margin-bottom: 24px;
			}

			.app {
				font-size: 12px;
				font-style: normal;
				font-weight: 600;
				line-height: 140%; /* 16.8px */
				letter-spacing: 0.48px;
				text-transform: uppercase;
				color: var(--grey-2);
				margin-bottom: 16px;
			}
			.item-application {
				display: flex;
				flex-wrap: wrap;
				gap: 24px;
				margin-bottom: 24px;

				.docs {
					display: flex;
					align-items: center;
					justify-content: space-between;
					height: 50px;
					flex: 0 0 calc(50% - 24px);
					padding: 0 16px;
					border-radius: 8px;
					background: var(--grey-6);
					color: var(--black);
					text-decoration: inherit;
					text-align: left;
					cursor: pointer;

					&:hover {
						background: var(--grey-6-hover);
					}

					.file-name {
						word-wrap: break-word;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
					}
				}
			}

			.labels-share {
				display: flex;
				justify-content: space-between;

				.labels {
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 150%;
					color: var(--grey-2);
					
					&:hover {
						color: var(--grey-2-hover);
					}
				}

				.social-share {
					display: flex;
					align-items: center;
				}
			}

			.back-forward {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin: 35px 0;

				.back, .forward {
					display: flex;
					align-items: center;
					cursor: pointer;

					.p4 {
						color: var(--grey-2);

						&:hover {
							color: var(--black);
						}
					}

					.arrow-left, .arrow-right {
						margin-right: 10px;
						vertical-align: middle;
					}
				}
			}
		}
	}

	.sidebar {
		position: sticky;
		flex-basis: 285px;
		flex-shrink: 0;
		width: 285px;
		margin-bottom: 30px;

		.sidebar-support-measure-small {
			display: flex;
			align-items: center;
			color: var(--deep-blue);
			text-transform: uppercase;
			padding-bottom: 24px;
			.line {
				width: 100%;
				margin-left: 10px;
				border-bottom: 1px solid var(--deep-blue);
			}
		}
	}
}

@media(max-width: $mobile-width-breakpoint){
	.support-measure-single-container{
		padding: var(--mobile-section-padding) !important;
		margin: 0px !important;
		background: var(--white);
		flex-direction: column;
		gap: var(--mobile-standart-row-gap);

		.wrapper-back{
			@include flex-row;
			align-items: center;
			gap: var(--mobile-small-row-gap);
			color: var(--grey-3);
			margin-bottom: 16px;
		}

		.support-measure-single-page{
			margin: 0px;

			.support-measure-single-page-item{
				@include flex-column;
				gap: var(--mobile-standart-row-gap);

				 & > div{
					 margin: 0px !important;
				 }

				.h1{
					@include h1-mobile;
				}

				.h5{
					@include h5-mobile;
				}

				.description{
					@include p1-mobile;
				}

				& > a{

					button{
						margin: 0px !important;
						width: 100%;

						.icon-content{
							justify-content: center;
						}
					}
				}

				.evaluate-measure-title{
					@include h5-mobile;
				}

				.useful-info-choice{
					padding: var(--mobile-wrapper-content-padding-all);
					flex-direction: column;

					.useful-info-title{
						@include h6-mobile;
					}

					.button-contanier{
						width: 100%;

						form{
							width: 100%;

							button{
								flex-grow: 1;
							}
						}
					}
				}

				.labels-share{
					padding: 0px;
					flex-direction: column;
					gap: var(--mobile-standart-row-gap);

					.social-share{
						padding: 0px;
						flex-direction: column;
						gap: var(--mobile-standart-row-gap);

						.social-bottom{

							.share-links{
								gap: var(--mobile-standart-row-gap);

								a{
									margin: 0px;
									width: 48px;
									height: 48px;
									@include flex-column;
									justify-content: center;
									align-items: center;
								}
							}
						}
					}
				}

				.back-forward{
					margin: 0px;
					flex-direction: column;
					gap: var(--mobile-standart-row-gap);
					align-items: flex-start;

					a{
						opacity: .5;
						svg{
							path{
								fill: var(--black);
							}
						}

						.p4{
							color: var(--black) !important;
						}
					}
				}

				.additional-info-title{
					@include h5-mobile;
				}

				.item-application{
					width: 100%;
					gap: var(--mobile-standart-row-gap);

					.docs{
						padding: 8px 16px;
						height: max-content;
						width: calc((100% - var(--mobile-standart-row-gap)) / 2);
						flex: unset;
						flex-direction: column-reverse;
						align-items: flex-start;
						gap: var(--mobile-small-row-gap);
					}
				}

			}
		}

		.sidebar{
			width: 100%;
			@include flex-column;
			gap: var(--mobile-medium-row-gap);

			.sidebar-support-measure-small{
				padding: 0px;
			}

			.support-measure-small{
				padding: 0px;
			}
		}
	}
}
