@import 'site/styles/mediaBreakpoints';

.summary-block-container {
  display: flex;

  .summary-block {
    display: flex;

    .vacancy-block {
      padding-right: 24px;
    }

    .popular-services {
      margin-bottom: 1px;
    }
  }
}

@media(max-width: $mobile-width-breakpoint){
  .summary-block-container{
    padding: var(--mobile-wrapper-content-padding-all);
    max-width: 100% !important;
    box-sizing: border-box;

    .summary-block{
      flex-direction: column;
      max-width: 100%;
      gap: var(--mobile-standart-row-gap);

      .vacancy-block{
        padding: 0px;

        .vacancy-widget-container{
          display: flex;
          flex-direction: column;
          gap: var(--mobile-standart-row-gap);

          .h6{
            padding: 0px;
            @include h6-mobile;
            color: var(--black);
          }

          .vacancy-widget{
            width: 100%;
            max-width: 100%;
            overflow-x: auto;
            padding: 0px;
            height: max-content;
            display: flex;
            flex-direction: row;
            gap: var(--mobile-standart-row-gap);

            &::-webkit-scrollbar{
              height: 0px;
            }

            a{
              min-width: 160px;
              max-width: 160px;
              display: block;

              .vacancy-item{
                margin: 0px;
                display: flex;
                flex-direction: column;
                gap: var(--mobile-small-row-gap);
                min-height: 100%;

                & > div{
                  padding: 0px;
                }

                .p2{
                  @include p2-mobile;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 3;
                  -webkit-box-orient: vertical;
                }

                .p3{
                  @include p3-mobile;
                }

                .vacancy-item-contact-person{
                  flex-grow: 1;
                }

              }
            }
          }

          & > a{
            button{
              margin: 0px;
              width: 100%;
              padding: 8px 16px;
              height: max-content;
              background: var(--grey-6);

              p{
                @include p2-mobile;
                font-weight: var(--mobile-p2-weight);
                color: var(--grey-1);
              }
            }
          }
        }
      }

      .popular-services-slider-block{

        .popular-services{

          .popular-services-container{

            .h6{
              @include h6-mobile;
            }

            .popular-services{
              width: 100%;
              padding: 0px;
              border: none;
              background: none;
              height: max-content;

              .popular-services-image{
                display: none;
              }

              .popular-services-blocks{
                max-width: 100%;
                overflow-x: auto;

                &::-webkit-scrollbar{
                  height: 0px;
                }

                .block-column{
                  display: flex;
                  gap: var(--mobile-small-row-gap);
                  margin: 0px;
                  position: relative;
                  top: 0;
                  transform: none;

                  a{
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
                    align-items: flex-start;
                    height: max-content;
                    width: 121px;
                    min-width: 121px;
                    padding: 8px 12px;

                    .text-block{
                      @include p2-mobile;
                      -webkit-line-clamp: 2;
                    }

                    img:first-child{
                      width: 28px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .slider-block{

        .slider{
          width: 100%;

          .slide-items{

            .item{
              width: 100%;
              height: max-content;

              .top-slide-container{
                display: block;

                .sliders{
                  display: flex;
                  flex-direction: column-reverse;

                  img{
                    width: 100% !important;
                    height: auto !important;
                  }

                  .slide-text-block{
                    padding: var(--mobile-wrapper-content-padding-all);
                    display: flex;
                    flex-direction: column;
                    gap: var(--mobile-standart-row-gap);

                    .slide-header{
                      @include h6-mobile;
                    }

                    .slide-description{
                      padding: 0px;
                      @include p1-mobile;
                    }

                    .slide-text-block-button-container{
                      position: relative;
                      padding: 0px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
