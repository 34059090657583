.button-show-more {
  display: flex;
  margin-top: 24px;
  justify-content: center;
  height: 36px !important;
  width: 100%;
  padding: 8px 12px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  background: var(--grey-6) !important;
  font-size: 14px !important;
  color: var(--grey-2) !important;
}