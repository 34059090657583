.sp-table-container,
.ui-table {
	thead {
		th {
			background: transparent;
			padding: 8px;
			white-space: nowrap;
			.column-title,
			a {
				color: #aeb2b7;
			}
			tr {
				background: #f77f00;
			}
		}
	}

	.sp-table {
		margin-bottom: 50px;
		thead {
			th {
				background: none;
			}
		}
	}

	tbody {
		background: var(--white, #fff);

		tr:not(.border) {
			padding: 9px 0;
			height: 64px;
		}
	}
}

.sp-dropdown {
	border-radius: 16px;
	background: var(--grey-6);
	color: var(--grey-1);
	font-weight: 600;
	border: none;

	&.sp-dropdown-list {
		border-radius: 0 0 16px 16px;
	}

	.sp-dropdown-item,
	&:has(.search) {
		background: white;
		color: #464a51;
	}

	.filter .filter-content {
		padding: 0 5px 0 12px;
	}
	.placeholder {
		color: var(--grey-1) !important;
	}
	.filter-arrow {
		margin: 0 4px 0 0;
	}
	.actions {
		border-radius: 0 0 16px 16px;
	}
}

.sp-date-picker {
	input {
		border-radius: 16px;
		font-weight: 600;
		background: var(--grey-6);
		color: var(--grey-1);
		border: none;
		padding-left: 12px;
	}
	.clear-btn {
		margin: 0 4px 0 0;
	}
}

.sp-map {
	.map-right-sidebar {
		z-index: 5;

		input[type="radio"] {
			padding: 0;
			margin: 0;
			height: auto;
			vertical-align: middle;
		}
	}
}

input[type="radio"] {
	padding: 0;
	margin: 0;
	height: auto;
	vertical-align: middle;
}

.sp-popup {
	position: fixed !important;
}
