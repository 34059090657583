.social-block {
	display: flex;
	align-items: center;

	a + a {
		margin-left: 24px;
	}

	a {
		height: 26px;
		transition: all 150ms;
        cursor: pointer;

		&:first-child {
			margin-left: 0;
		}

		&:hover {
			transform: scale(1.1, 1.1);
		}
	}
}