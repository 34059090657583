@import 'site/styles/mediaBreakpoints';
@import "client/styles/mixins";

.topic-button{
  padding: 4px 16px;
  border-radius: 50px;
  @include P2;
  border-width: 1px;
  border-style: solid;
}

@media(max-width: $mobile-width-breakpoint){
  .topic-button{
    padding: 2px 8px;
    @include p2-mobile;
  }
}