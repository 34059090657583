@import 'site/styles/mediaBreakpoints';

.gis-map {
	height: 850px;
	padding-top: 0 !important;
	max-width: 100%;
}

@media(max-width: $mobile-width-breakpoint){
	.gis-map {
		height: 100vh;
		max-width: 100%;
		margin: 0px !important;
	}
}
