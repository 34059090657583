@import 'site/styles/mediaBreakpoints';
@import "client/styles/mixins";
$height-cover-image: 654px;

.main-header {
	padding-bottom: 268px;
	z-index: 1;
	position: relative;
	flex: 1;

	.cover-header-noimage {
		position: absolute;
		width: 100%;
		z-index: 0;
		background: linear-gradient(180deg, #2069c2 0%, #9edbf5 57.52%);
		height: 877px;
	}

	.cover-header-image-wrapper {
		width: 100%;
		display: flex;
		justify-content: center;
		position: absolute;
		height: 877px;
		overflow: hidden;


		.cover-header-image {
			position: absolute;
			width: 100%;
			z-index: 0;
			background: linear-gradient(180deg, #2069c2 0%, #9edbf5 57.52%);
			height: 100%;
			&.widget-is-empty {
				height: 100%;
			}
		}
	}

	.wrapper {
		display: flex;
		flex-direction: row;
		justify-content: center;
		position: relative;

		.content {
			&:before {
				position: absolute;
				content: "";
				top: 100px;
				left: -120px;
				width: 120px;
				height: 160px;
				background: no-repeat url("/static/pattern-stars-left.svg");
			}

			&:after {
				position: absolute;
				content: "";
				top: 0;
				right: -120px;
				width: 120px;
				height: 160px;
				background: no-repeat url("/static/pattern-stars-right.svg");
			}

			padding: 20px 0;
			max-width: var(--site-width);
			width: 100%;
			position: relative;
			.page-header-title-container {
				display: flex;
				flex-direction: row;
				height: 450px;
				margin-top: 69px;

				.page-header-title-left {
					display: flex;
					flex-direction: column;
					justify-content: center;
					width: 66%;
					margin-right: 24px;

					.page-title {
						display: flex;
						flex-direction: column;
						justify-content: center;
						text-align: left;
						align-items: flex-start;
						margin-bottom: 24px;

						.page-title-text-1 {
							font-weight: 600;
							font-size: 32px;
							color: #fff;
							line-height: 1.1;
						}

						.page-title-text-2 {
							margin-top: 24px;
							font-weight: 400;
							font-size: 24px;
							color: #fff;
						}
					}

					.page-header-search {
						margin-top: 64px;

						.ui-input {
							opacity: 0.6;
						}
					}

					.page-header-buttons{
						display: flex;
						flex-wrap: wrap;
						gap: 8px;
					}
				}

				.page-header-title-right {
					display: flex;
					width: 32%;

					.header-title-widget {
						width: 384px;
					}
				}
			}
		}
	}

	.header {
		.header-left-right {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 16px;

			.header-right {
				display: flex;
				align-items: center;
				gap: 8px;
			}

			.sp-btn,
			.sp-dropdown {
				background-color: rgba(50, 53, 56, 0.08);
				border: 1px solid transparent !important;
				color: var(--white);
				&:active {
					background-color: unset;
				}
				&.selected {
					background-color: var(--deep-blue);
				}
			}
		}

		.desktop-menu, .mobile-menu {
			color: #fff;
			white-space: nowrap;

			a {
				color: #fff;
				text-decoration: none;
				padding-right: 8px;
			}
		}
	}
}

@media(max-width: $tabled-width-breakpoint){
	.main-header{
		margin: 0px !important;

		.wrapper{

			.content{

				&:before, &:after{
					display: none;
				}
			}
		}
	}
}

@media(max-width: $mobile-width-breakpoint){
	.main-header{
		padding: 0px;
		margin-bottom: 0px !important;

		.cover-header-noimage {
			height: $height-cover-image;
		}

		.cover-header-image-wrapper{
			height: $height-cover-image;

			img{
				position: relative;
				min-height: 100%;
				width: auto !important;
			}
		}

		& > .wrapper{
			max-width: 100%;
			padding: var(--mobile-wrapper-content-padding-horizontal);

			.content{
				padding: var(--mobile-wrapper-content-padding-vertical);
				max-width: 100%;

				&:before, &:after{
					display: none;
				}

				.header{

					.header-content{
						display: flex;
						flex-direction: column;
						gap: var(--mobile-big-row-gap);

						.page-header-title-container{
							margin-top: 0px;
							flex-direction: column;
							height: max-content;
							gap: var(--mobile-medium-row-gap);

							.page-header-title-left{
								width: 100%;
								margin-left: 0px;
								display: flex;
								flex-direction: column;
								gap: var(--mobile-medium-row-gap);

								.page-title{
									display: flex;
									flex-direction: column;
									gap: var(--mobile-small-row-gap);
									margin: 0px;

									.page-title-text-1{
										@include h1-mobile;
									}
									.page-title-text-2{
										@include h3-mobile;
										margin: 0px;
									}
								}

								.page-header-buttons{
									display: flex;
								}

								.page-header-search{
									margin: 0px;

									input{
										@include p1-mobile;
									}
								}
							}

							.page-header-title-right{
								width: 100%;
								flex-direction: column;
								gap: var(--mobile-standart-row-gap);
							}
						}
					}
				}
			}
		}
	}
}
