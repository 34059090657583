@import "mixins";
@import "reset.css";
@import "hover.css";
@import "font.css";
@import "smartplatform.scss";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "ui";

$black: #464a51;
$white: #ffffff;

$bright: #bbe4fc;
$bright-hover: #9dd1f0;

$light: #cce3f0;
$light-hover: #c1dded;

$blue: #2682f3;
$bright-blue: #3d7dba;

$deep-blue: #1051a1;
$deep-blue-hover: #225ea8;

$translucent-white-60: rgba(255, 255, 255, 0.6);
$translucent-white-30: rgba(255, 255, 255, 0.3);

$translucent-black-20: rgba(51, 51, 51, 0.2);
$translucent-black-80: rgba(51, 51, 51, 0.8);

$grey: #333333;

$grey-1: #6d7886;
$grey-1-hover: #464a51;

$grey-2: #8291a4;
$grey-2-hover: #a8afb8;

$grey-3: #a8afb8;
$grey-3-hover: #8291a4;

$grey-4: #c8d2e0;
$grey-4-hover: #a8afb8;

$grey-5: #e4eef2;
$grey-5-hover: #c8d2e0;

$grey-6: #f1f7fa;
$grey-6-hover: #e4eef2;

$grey-7: #fafcff;

$light-green: #61d9cd;

$primary: #37b813;
$primary-rgb: 55, 184, 19; // #37b813

:root {
	--element-height-no-px: 48;
	--element-height: 48px;
	--element-height-small: 36px;
	--inner-page-header-height: 180px;
	--black: #{$black};
	--primary: #{$primary};
	--light-green: #{$light-green};
	--primary-rgb: #{$primary-rgb};
	--white: #{$white};
	--bright: #{$bright};
	--bright-hover: #{$bright-hover};
	--light: #{$light};
	--light-hover: #{$light-hover};
	--blue: #{$blue};
	--bright-blue: #{$bright-blue};
	--deep-blue: #{$deep-blue};
	--deep-blue-hover: #{$deep-blue-hover};
	--translucent-white-60: #{$translucent-white-60};
	--translucent-white-30: #{$translucent-white-30};
	--translucent-black-20: #{$translucent-black-20};
	--translucent-black-80: #{$translucent-black-80};
	--grey: #{$grey};
	--grey-1: #{$grey-1};
	--grey-1-hover: #{$grey-1-hover};
	--grey-2: #{$grey-2};
	--grey-2-hover: #{$grey-2-hover};
	--grey-3: #{$grey-3};
	--grey-3-hover: #{$grey-3-hover};
	--grey-4: #{$grey-4};
	--grey-4-hover: #{$grey-4-hover};
	--grey-5: #{$grey-5};
	--grey-5-hover: #{$grey-5-hover};
	--grey-6: #{$grey-6};
	--grey-6-hover: #{$grey-6-hover};
	--grey-7: #{$grey-7};
	--red: #e21d24;
	--light-grey: #f5f5f5;
	--site-width: 1200px;
	--title-font: "Bitter", sans-serif;
	--title-weight: 600;
	--title-weight-lighter: 500;
	--title-big-size: 24px;
	--title-mid-size: 18px;
	--title-small-size: 16px;
	--title-smaller-size: 15px;

	--mobile-wrapper-content-padding-horizontal: 0px 16px;
	--mobile-wrapper-content-padding-vertical: 16px 0px;
	--mobile-wrapper-content-padding-all: 16px;
	--mobile-section-padding: 24px 16px;
	--mobile-p2-weight: 400;
	--mobile-big-row-gap: 48px;
	--mobile-medium-row-gap: 24px;
	--mobile-standart-row-gap: 16px;
	--mobile-small-row-gap: 8px;

}

body {
	font-family: "Golos Text", sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.5em;
	background: #fff;
	overflow-x: hidden;

	&.fixed {
		overflow: hidden;
	}

	&.noselect {
		user-select: none;
	}
}

#root {
	min-height: 100vh;
}

a {
	text-decoration: none;
}

.sakhagov {
	background: var(--grey-7);
}

.all-link {
	text-align: center;
	margin: 15px 0;
}

.divider {
	height: 18px;
	background: repeat-x url("/static/divider.svg");
	margin: 10px 0;
}

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1240px
);

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1240px
);

.text-right {
	text-align: right;
}

.cursor-pointer {
	cursor: pointer;
}

blockquote {
	margin: 1em 0;
	font-style: italic;
	padding-left: 30px;
	border-left: 2px solid var(--red);
	background-size: 40px;
}

@media (max-width: 1199px) {
	.main-content {
		//overflow: auto;
	}
}

#root .ui-pager {
	text-align: center;

	a {
		padding: 0;
		width: 30px;
		height: 30px;
		line-height: 30px;
		border-radius: 50%;
		color: #969696;
		font-size: 14px;
		transition: 250ms;
		margin-right: 3px;
		//background: #eee;

		&:hover {
			background: rgba(#eee, 0.7);
		}

		&.active {
			background: rgba(#e21d24, 0.07);
		}

		&.disabled {
			color: #ccc;
		}
	}

	&.single {
		display: none;
	}
}

img[style="float: left;"] {
	margin-top: 3px;
	margin-right: 15px;
}

img[style="float: right;"] {
	margin-top: 3px;
	margin-left: 15px;
}
html.poor-vision {
	* {
		font-family: "Roboto Slab", serif !important;
	}

	a:hover {
		color: var(--color-pure-vision-blue);
	}

	.static-page ul li::before {
		color: var(--color-pure-vision-blue);
	}
}

.desktop-menu,
.inner-page-header {
	font-size: 1rem !important;
}

html.font-size {
	&-medium {
		#root,
		p,
		div {
			font-size: 1.2rem !important;
		}
	}
	&-big {
		#root,
		p,
		div {
			font-size: 1.4rem !important;
		}
	}
}

html.font-style-serif {
	* {
		font-family: "Golos Text", serif !important;
	}
}

html.font-style-sans-serif {
	* {
		font-family: "Montserrat", sans-serif !important;
	}
	#root {
		font-family: "Montserrat", sans-serif;
	}

	h1,
	h2,
	h3,
	h4,
	h5 {
		font-family: "Montserrat", sans-serif;
	}

	p,
	ol,
	.text__ul {
		font-family: "Montserrat", sans-serif;
	}

	.note-page-title {
		h1 {
			font-family: "Montserrat", sans-serif;
		}
	}

	.note-page__date {
		font-family: "Montserrat", sans-serif;
	}

	.wrapper-img-history {
		p {
			font-family: "Montserrat", sans-serif;
		}
	}

	.react-datepicker-popper {
		.react-datepicker {
			font-family: "Montserrat", sans-serif;
		}
	}
}

html.images-off {
	img:not(.logo-minus):not(.logo-domain) {
		display: none;
	}

	.main-header {
		background-color: var(--deep-blue);
	}
}

html.color-blue {
	#root,
	.inner-page-header,
	.search-page {
		background: #9dd1ff;
	}

	--color-grey-light-1: #85c2ff;
	--color-primary-dark: #85c2ff;
	--color-grey-light-2: #85c2ff;
}

html.color-inversion {
	#root,
	.main-content,
	footer,
	.inner-page-header,
	.search-page,
	.sp-dropdown-item,
	.actions,
	.sp-calendar,
	.month-year,
	.week-days {
		background: black !important;
	}

	p:not(.blind-header-name),
	a,
	div:not(.map):not(.placeholder):not(.show-value):not(.sp-date-picker),
	span,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	li,
	label,
	input,
	textarea,
	input::placeholder,
	textarea::placeholder {
		color: white !important;
	}

	.show-value,
	.sp-btn-default.grey > span,
	.sp-date-picker > input {
		color: var(--grey-1) !important;
	}
}

html.string-interval-medium {
	#root,
	p {
		line-height: 25px !important;
	}
}

html.string-interval-big {
	#root,
	p {
		line-height: 30px !important;
	}
}

html.word-interval-medium {
	#root,
	p {
		letter-spacing: 2px !important;
	}
}

html.word-interval-big {
	#root,
	p {
		letter-spacing: 4px !important;
	}
}

html.word-interval {
	&-medium,
	&-big {
		&.font-size-medium,
		&.font-size-big {
			* {
				word-break: break-word;
			}

			.header {
				* {
					word-break: normal !important;
				}
			}
		}
	}
}
