@import 'client/styles/mixins';

.site-card {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	border: 1px solid #e4eef2;
	padding: 16px 24px;
	gap: 16px;
	border-radius: 8px;
	cursor: pointer;
	background-color: var(--white);

	.name {
		width: 100%;
		@include P2;
		color: var(--grey-1);
		-webkit-line-clamp: 2;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	.arrow {
		display: inline-block;
		width: 24px;
		height: 24px;
	}

	.icon {
		height: 32px;
		width: 32px;
	}

	&.popular {
		background-color: #d9eef6;
		min-height: 120px;
		flex-direction: column;
		align-items: start;
		padding: 16px;
		position: relative;

		.icon {
			height: 48px;
			width: 48px;
		}

		.position {
			z-index: 2;
			position: absolute;
			right: 15px;
			top: -5px;
			font-weight: bold;
			font-size: 96px;
			line-height: 96px;
			color: #1051a1;
			opacity: 4%;
			text-align: right;
		}

		&:before {
			content: '';
			position: absolute;
			display: block;
			background-image: url('/static/pattern-stars-position.svg');
			right: 5px;
			top: 0;
			height: 120px;
			width: 90px;
		}
	}
}