@import 'site/styles/mediaBreakpoints';

.media-feed {
	padding-bottom: 24px;

	.media-feed-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 32px;

		span {
			color: var(--black, #464a51);
			font-size: 32px;
			font-weight: 600;
			line-height: 140%;
		}

		a {
			display: flex;
			align-items: flex-end;
			gap: 8px;
			color: var(--grey-4, #c8d2e0);
			font-size: 12px;
			font-weight: 600;
			line-height: 140%;
			letter-spacing: 0.48px;
			text-transform: uppercase;
			text-decoration: none;

			&:hover {
				color: var(--black, #464a51);

				svg {
					* {
						fill: var(--black, #464a51);
					}

					path:nth-child(2) {
						stroke: var(--black, #464a51);
					}
				}
			}

			svg {
				* {
					fill: var(--grey-4, #c8d2e0);
				}

				path:nth-child(2) {
					stroke: var(--grey-4, #c8d2e0);
				}
			}
		}
	}

	.media-content {
		display: flex;
		gap: 24px;

		svg,
		img {
			width: 265px !important;
		}
	}

	.slider-content {
		max-width: 300px;
		padding-right: 24px;

		div {
			display: -webkit-box !important;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
			color: var(--black, #464A51);
			font-size: 16px;
			font-weight: 600;
			line-height: 150%;
			margin-top: 8px;
		}

		svg,
		img {
			object-fit: cover;
			max-width: 300px;
			width: 100%;
			height: 167px;
			border-radius: 8px;
		}
	}


	.slick-slider {
		.slick-arrow {
			display: none !important;
		}

		.slick-dots {
			position: relative;
			margin-top: 16px;

			.slick-active .paging {
				background: var(--deep-blue, #1051A1);
			}

			li {
				width: 8px;
				height: 8px;
				margin: 0 16px;
			}

			.paging {
				width: 8px;
				height: 8px;
				border-radius: 8px;
				background: var(--grey-4, #C8D2E0);
			}
		}
			}
}

@media(max-width: $mobile-width-breakpoint){
	.media-feed{
		padding: var(--mobile-section-padding);
		@include flex-column;
		gap: var(--mobile-standart-row-gap);

		.media-feed-header{
			margin: 0px;
			@include flex-row;
			gap: var(--mobile-standart-row-gap);
			flex-wrap: wrap;

			span{
				@include p2-mobile-bold;
				text-transform: uppercase;
				color: var(--deep-blue);
				flex-grow: 1;
				@include flex-row;
				align-items: center;
				gap: 10px;

				&::after{
					content: '';
					height: 1px;
					background: var(--deep-blue);
					flex-grow: 1;
				}
			}

			a{
				width: 100%;
			}
		}

		.media-content{
			gap: var(--mobile-small-row-gap);
			max-width: 100%;
			overflow-x: auto;

			&::-webkit-scrollbar{
				height: 0px;
			}

			.slider-content{
				padding: 0px;
				max-width: 160px;
				min-width: 160px;

				img{
					width: 100% !important;
					height: 130px;
				}

				div{
					@include h6-mobile;
				}
			}
		}
	}
}
