.h1 {
	font-size: 32px;
	font-weight: bold;
	line-height: 32px;
}

.h2 {
	font-size: 32px;
	font-weight: bold;
	text-transform: uppercase;
}

.h3 {
	font-size: 24px;
}

.h4 {
	font-size: 24px;
	font-weight: bold;
}

.h5 {
	font-size: 20px;
	font-weight: bold;
}

.h6 {
	font-size: 18px;
	font-weight: bold;
}

.h7 {
	font-size: 16px;
	font-weight: bold;
}

.p1 {
	font-size: 16px;
    font-weight: 400;
}

.p2 {
	font-size: 14px;
}

.p3 {
	font-size: 12px;
}

.p4 {
	font-size: 12px;
	font-weight: bold;
	text-transform: uppercase;
}

.p5 {
	font-size: 10px;
}

.p6 {
    font-size: 10px;
    line-height: 150%;
    text-transform: uppercase;
}

.bold {
	font-weight: bold;
}