@import 'client/styles/mixins';

.documents-page {
	.attachment-card {
		display: flex;
		gap: 30px;
		border: 1px solid var(--grey-5);
		position: relative;

		> .info > .name{
			color: var(--black);
		}

		&:hover {
			background-color: var(--white);

			> .info > .name {
				color: var(--deep-blue);
			}

			&:has(.document-card:hover) {
				> .info > .name {
					color: var(--black);
				}
			}
		}

		.file {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 10px;
			width: 90px;
			height: auto;

			@include P2;
			text-transform: uppercase;

			.filesize,
			.archive {
				@include P3;
				color: var(--grey-3);
			}
		}

		.info {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			gap: 8px;
		}
	}
}
