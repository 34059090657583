.podcasts {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
}

.podcast-item {
	width: 24%;
	height: 285px;
	background: #F5F5F5;
	margin-bottom: 15px;
	margin-right: 1.3%;
	text-decoration: none;
	border-bottom: 1px solid transparent;
	box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&:nth-child(4n) {
		margin-right: 0;
	}

	&:hover {
		color: black;
	}

	.image {
		width: 100%;
		height: 160px;
		overflow: hidden;
		position: relative;

		img {
			width: 100%;
			height: 160px;
			object-fit: cover;
		}

		.play-btn {
			content: '';
			display: block;
			width: 120px;
			height: 120px;
			position: absolute;
			top: 50%;
			left: 50%;
			margin-left: -60px;
			margin-top: -60px;
			background-image: url("../../img/podcast_play.svg");
		}
	}

	&:hover .play-btn {
		background-image: url("../../img/podcast_play_red.svg");
	}

	.title {
		height: auto;
		padding: 10px 20px;
		line-height: 1.3em;
		font-size: 20px;
		font-weight: bold;
		-webkit-box-orient: vertical;
		text-overflow: ellipsis;
	}

	.info {
		display: flex;
		justify-content: space-between;
		height: 50px;
		line-height: 2;
		padding: 0 10px;
		z-index: 20;
		color: rgba(#000, 0.6);
		font-size: 13px;
		.date {
			max-width: 165px;
		}
		.stats {
			display: flex;
			&>div {
				margin-left:10px;
			}
		}
	}

}
.single {
	.view-count {
		display:none
	}
}

.podcasts-page {
	display: flex;
	flex-direction: column;
	.description{
		margin: 20px 0;
	}
	.load-more {
		align-self: center;
	}
}

@media (max-width: 768px) {
	.podcast-item {
		width: 100%;
		box-shadow: unset;
	}
}

@media (max-width: 767px) {
	.single {

		.image img {
			max-width: 70px;
		}

		.content {
			.podcast-title {
				font-size: 12px;
			}

			.podcast-controls {
				flex-direction: column;

				.volume {
					flex-basis: initial;
					width: 100%;
				}

				.timeline {
					height: initial;
					padding-right: initial;
					width: 100%;
				}

				.buttons {
					flex-basis: initial;
					margin: 0 0 15px 0;
				}
			}
		}
	}

	.podcasts {
		background: none;
		width: 100%;

		.podcast {
			display: block;
			width: 100%;
			padding: 0;
			background: #f5f5f5;
			text-decoration: none;
			cursor: pointer;
			box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);

			&:not(:last-child) {
				margin-bottom: 20px;
			}

			.image {
				width: 100%;
				height: 160px;
				position: relative;
				margin-bottom: 10px;

				img {
					width: 100%;
					height: 160px;
					object-fit: cover;
				}

				.bg {
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 74.74%);
					display: flex;
					align-items: center;
					justify-content: center;

					img {
						width: 80px;
						height: 80px;
					}
				}
			}

			.title {
				padding: 0 15px;
				margin-bottom: 5px;
				font-weight: bold;
			}

			.info {
				display:flex;
				padding: 0 15px 10px 15px;
				font-size: 12px;
				color: #aaa;
				.stats {
					display:flex;
					margin-left : auto;
					&>div {
						margin-left:10px;
					}
				}
				.active-count {
					display: none;
				}
			}
		}
	}
}
