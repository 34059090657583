@import 'site/styles/mediaBreakpoints';

.media-single {
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 890px;
	padding: 9px;

	.media-slider-main {
		margin-bottom: 26px;
	}

	.single-content:has(.slick-slider) {

		.slick-arrow {
			position: absolute;
			z-index: 10;

			svg {
				position: absolute;
				left: -18px;
				top: -18px;
			}
		}

		.slick-prev {
			left: -65px;
		}

		.slick-next {
			right: -65px;
		}

		.slick-arrow::before {
			color: transparent;
		}

		.slick-dots {
			bottom: -36px;
			button:before {
				content: '';
			}
			button {
				height: 8px;
				width: 8px;
				border-radius: 100%;
				background: var(--basic-grey-4, #C8D2E0);
			}
			.slick-active {
				button {
					height: 8px;
					width: 8px;
					border-radius: 100%;
					background: var(--primary-deep-blue, #1051A1);
				}
			}
		}
	}

	.single-bar {
		display: flex;
		justify-content: space-between;

		.single-download {
			display: flex;
			align-items: center;
			gap: 8px;
			border-radius: 24px;
			background: var(--basic-grey-6, #f1f7fa);
			height: 32px;
			padding: 8px 16px 8px 12px;
			color: var(--basic-grey-1, #6d7886);
			font-size: 14px;
			font-weight: 400;
			line-height: 150%;
			text-decoration: none;
		}

		.single-labels span {
			border-bottom: 1px solid var(--basic-grey-4, #C8D2E0);
			opacity: 0.6;
			margin: 0 8px;
		}

		.single-type {
			padding: 2px 8px;
			border-radius: 4px;
			background: var(--basic-grey-6, #f1f7fa);
			color: var(--basic-grey-2, #8291a4);
			font-size: 14px;
			font-weight: 400;
			line-height: 150%;
		}
	}

	audio {
		width: 100%;
	}

	iframe,
	video {
		border-radius: 8px;
	}

	.single-name {
		color: var(--basic-black, #464a51);
		font-size: 20px;
		font-weight: 600;
		line-height: 140%;
	}

	.single-author,
	.single-source,
	.single-view-date {
		color: var(--basic-grey-2, #8291a4);
		font-size: 14px;
		font-weight: 400;
		line-height: 150%;

		span {
			color: var(--basic-black, #464a51);
		}
	}

	.single-description {
		color: var(--basic-black, #464a51);
		font-size: 16px;
		font-weight: 400;
		line-height: 150%;
	}

	.next-arrow .prev-arrow {
		width: 10px;
		height: 20px;
		background-color: red;
	}


}

@media(max-width: $tabled-width-breakpoint){
	.record-popup{

		.media-single{
			width: 100%;
			@include scroll-vertical;
			max-height: 100%;

			video{
				max-width: 100%;
				height: auto;
			}

			iframe{
				max-width: 100%;
				height: auto;
				min-height: 400px;
			}

			.iframe-wrapper{
				max-width: 100%;
				height: auto !important;

			}
		}
	}
}