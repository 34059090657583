.scrollableContainer {
	position: relative;
	margin: 16px 0;

	.list {
		display: flex;
		flex-direction: column;
		overflow-y: scroll;
		list-style: none;
		padding: 10px;
		margin: 0;

		.list-items {
			display: flex;
			align-items: center;
			padding-bottom: 16px;

			.custom-checkbox {
				width: 20px;
				height: 20px;
				border-radius: 8px;
				//outline: none;
			}

			.custom-checkbox {
				width: 20px;
				height: 20px;

			}

			.items {
				display: flex;
				justify-content: space-between;

				width: 100%;

				.item, .item-id {
					padding-left: 10px;

					color: var(--black);
					font-weight: 400;
				}

          .item-id {
						padding-right: 8px;
           }
			}
		}
	}
	* {
		scrollbar-width: thin;
		scrollbar-color: var(--grey-5);
	}

	/* Работает в Chrome, Edge и Safari */
	*::-webkit-scrollbar {
		width: 8px;
	}

	*::-webkit-scrollbar-track {
		background: none;
	}

	*::-webkit-scrollbar-thumb {
		background-color: var(--grey-5);
		border-radius: 8px;
	}
}
