@import 'site/styles/mediaBreakpoints';


.inner-page-header-wrapper {
	height: 88px;
	width: 100%;
	position: fixed;
	background-color: var(--white);
	z-index: 95;
	top: 0;



	.inner-page-header {
		display: flex;
		gap: 16px;
		padding: 24px 0;
		position: fixed;
		top: 0;
		left: 50%;
		margin-left: -600px;
		color: var(--black);
		white-space: nowrap;
		max-width: var(--site-width);
		width: 100%;
	}

	.poor-vision {
		svg.stars-left {
			top: 143px !important;
		}

		.inner-page-header {
			position: absolute;
			top: 90px;
		}
	}

	.poor-vision.scroll-90 {
		.inner-page-header {
			position: fixed;
			top: 0;
		}
	}
}

.inner-page-header-wrapper {
	.inner-page-header {
		.wrapper-content-portal-map-main {
			white-space: pre-wrap !important;
		}

	}
}

.secondary-page-header-wrapper {
	padding-top: 88px;
	height: 148px;
}

.secondary-page-header-bottom {
	height: unset;
	padding: 30px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.breadcrumbs {
		margin-bottom: 0;
	}
}

.inner-page-header-container {
	background-color: var(--white);

	.blind-popup-wrapper {
		max-width: var(--site-width);
		width: 100%;
		margin: 0 auto;
		margin-bottom: -90px;

		.blind-popup {
			padding: 0;
			width: 100%;
			margin-top: 80px;
			display: flex;
			justify-content: space-between;
		}
	}

}

@media(max-width: $mobile-width-breakpoint){
	.inner-page-header-container{
		height: max-content;

		.inner-page-header-wrapper{
			height: max-content;
			position: relative;
			z-index: 97;
			background: transparent;

			.inner-page-header{
				position: relative;
				left: 0;
				top: 0;
				margin: 0px;
				padding: var(--mobile-wrapper-content-padding-all);
				max-width: 100%;

				.desktop-menu{

					& > .links:first-child{
						display: none;
					}
				}
			}
		}
	}
}