@import 'client/styles/mixins';
@import 'site/styles/mediaBreakpoints';

.about-republic-container {
	padding-bottom: 64px;

	.about-republic-info {
		display: flex;
		flex-direction: column;
		gap: 24px;
		padding-bottom: 24px;

		.about-republic {
			display: flex;
			gap: 24px;

			img {
				width: 384px;
				height: 260px;
				border-radius: 8px;
				object-fit: cover;
			}

			.about-republic-description {
				display: flex;
				flex-direction: column;
				gap: 24px;

				.description {
					@include P1;
					@include line-clamp;
					-webkit-line-clamp: 10;
				}

				.read-more {
					@include P2-bold;
					color: var(--deep-blue);
					text-decoration: none;
				}
			}
		}

		.items-info {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			row-gap: 10px;
			column-gap: 24px;

			.items {
				font-size: 14px;
				font-style: normal;
				font-weight: 600;
				line-height: 140%;
				color: var(--black);
				text-decoration: none;
			}
		}
	}

	.info-about-republic-materials {
		.content {
			width: 100%;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-gap: 10px;
			color: var(--black);
			@include P2-bold;
		}
	}

	.materials-list {
		display: flex;
		flex-direction: row;
		gap: 24px;

		.categories {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			gap: 24px;

			.category-section-wrapper {
				.category-materials-wrapper {
					display: grid;
					grid-template-columns: 1fr 1fr;
					grid-gap: 24px;

					.material {
						display: flex;
						gap: 24px;
						flex-direction: row;

						img {
							width: 48px;
							height: 48px;
							border-radius: 8px;
							object-fit: cover;
						}

						.title-description {
							.title {
								@include H7;
							}

							.description {
								@include P2;
								@include line-clamp;
								-webkit-line-clamp: 2;
								color: var(--grey-1);
							}
						}
					}
				}
			}
		}
	}
}

.material-page-wrapper {
	display: flex;
	gap: 24px;
	flex-direction: row;
	margin-top: 88px;
	padding: 64px 0;
}

@media(max-width: $mobile-width-breakpoint){

	.about-republic-container{
		padding: var(--mobile-wrapper-content-padding-all);
		@include flex-column;
		gap: var(--mobile-standart-row-gap);

		.about-republic-info{
			padding-bottom: 0px;
			gap: var(--mobile-standart-row-gap);

			.about-republic{
				@include flex-column;
				gap: var(--mobile-medium-row-gap);

				picture{

					img{
						max-width: 100%;
						height: auto;
					}
				}

				.about-republic-description{
					gap: var(--mobile-small-row-gap);

					.description{
						@include p1-mobile;
						-webkit-line-clamp: 7;
					}

					.read-more{
						@include p1-mobile;
					}
				}
			}

			.info-about-republic-materials{
				@include flex-column;
				gap: var(--mobile-standart-row-gap);

				.section-card__title{
					margin: 0px;
				}

				.content{
					@include flex-column;
					gap: 10px;
				}
			}
		}

		.materials-list{
			flex-direction: column-reverse;
			gap: var(--mobile-standart-row-gap);

			.latest-materials{
				@include flex-column;
				gap: var(--mobile-standart-row-gap);
				width: 100%;

				.section-card__title{
					margin: 0px;
				}

				.content{
					@include flex-row;
					gap: var(--mobile-medium-row-gap);
					max-width: 100%;
					overflow-x: auto;

					&::-webkit-scrollbar{
						height: 0px;
					}

					.about-republic-card-sidebar{
						min-width: 216px;
						max-width: 216px;
						padding-bottom: 8px;

						.title-description{

							.title{
								word-wrap: break-word;
								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 2;
								@include h7-mobile;
								color: var(--black);
							}

							.description{
								@include p2-mobile;
							}
						}

						.card-image{
							img{
								width: 48px;
								height: 48px;
							}
						}
					}
				}
			}

			.categories{
				gap: var(--mobile-standart-row-gap);

				.category-section-wrapper{
					margin: 0px;
					@include flex-column;
					gap: var(--mobile-standart-row-gap);

					.section-card__title{
						margin: 0px;
					}

					.content{

						.category-materials-wrapper{
							@include flex-column;
							gap: var(--mobile-standart-row-gap);

							.material{

								.title-description{
									@include flex-column;
									gap: 4px;

									.title{
										@include h7-mobile;
									}

									.description{
										@include p2-mobile;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}