@import 'site/styles/mediaBreakpoints';

.scroll-top-button {
	position: fixed;
	z-index: 999;
	display: inline-block;
	vertical-align: middle;
	padding: 0 5px;
	cursor: pointer;
	border: none;
	background-color: #f1f7fa;
	width: 56px;
	height: 56px;
	bottom: 40px;
	right: 30px;
	padding: 16px;
	border-radius: 30px;
	gap: 10px;

	&:hover {
		background-color: #cce3f0;
		path {
			fill: #ffffff;
		}
	}
}

@media(max-width: $mobile-width-breakpoint){
	.scroll-top-button{
		bottom: 16px;
		right: 16px;
		width: 48px;
		height: 48px;
	}
}
