.support-measure-small {
	padding-bottom: 24px;

	.topic {
		color: var(--grey-2);
		font-size: 10px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%; /* 15px */
		letter-spacing: 0.4px;
		text-decoration-line: underline;
		text-transform: uppercase;
		padding-bottom: 8px;
	}

	.title {
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 140%;
		color: var(--black);
		margin-bottom: 8px;

		word-wrap: break-word;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.data {
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
		color: var(--grey-3);
	}
}
