.video-player-wrapper {
    display: flex;
    flex-direction: column;
    font-weight: normal;

    .iframe-wrapper {
        iframe {
            width: 100%;
            height: 100%;
        }
    }
}
