@import 'admin/styles/mixins';

.vacancy-filters {
  &__wrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 16px;

    .sp-dropdown {
      width: 200px;
    }

    :nth-child(1n) {
      margin-right: 8px;
    }

    .right {
      span {
        @include P2;
        color: var(--black);
      }

      .sp-dropdown {
        width: 240px;
      }
    }
  }
}

.sp-dropdown-item {
  font-weight: 400;
}