.banner-285x366 {
	width: 285px;
	height: 366px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	// background: #ddd;
	margin:0 auto;
}
