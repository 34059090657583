.popular-services-container {
  width: 894px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  //border-radius: 8px;
  max-width: 100%;

  .popular-services-header {
    padding-bottom: 16px;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    color: var(--black);
  }

  .popular-services {
    position: relative;
    width: 894px;
    height: 357px;
    display: flex;
    border-radius: 8px;
    border: 1px solid var(--grey-4);
    background: var(--white);


  .popular-services-image {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    overflow: hidden;

    img {
      z-index: 5;
    }
  }

    .popular-services-blocks {
      align-self: stretch;
      z-index: 2;

      .block-column {
        display: grid;
        align-content: center;
        position: absolute;
        right: 0;
        top: 45%;
        transform: translate(0, -50%);
        margin: 24px;
        gap: 24px;

        :nth-child(n+1){
          grid-row: 1 span;
        }

        :nth-child(n+3){
          grid-row: 2;
        }

        :nth-child(n+5){
          grid-row: 3;
        }

    .block {
      display: flex;
      justify-content: space-between;
      width: 269px;
      height: 87px;
      padding: 12px 16px;
      align-items: center;
      gap: 16px;
      border-radius: 8px;
      border: 1px solid var(--grey-5 );
      background: var(--white);

      &:hover {
        cursor: pointer;
        background: var(--grey-6);
        .text-block {
          color: var(--deep-blue);
        }
      }

      .icon-block {
        width: 36px;
        height: 36px;
        border-radius: 8px;
      }

      .text-block {
        color: var(--black);
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
  }

      .block-column-2 {
        padding: 24px 24px 24px 12px;
      }
    }
  }
}
