@import 'site/styles/mediaBreakpoints';

.poster {
	.poster-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 32px;

		span {
			color: var(--black, #464a51);
			font-size: 32px;
			font-weight: 600;
			line-height: 140%;
		}

		a {
			display: flex;
			align-items: flex-end;
			gap: 8px;
			color: var(--grey-4, #c8d2e0);
			font-size: 12px;
			font-weight: 600;
			line-height: 140%;
			letter-spacing: 0.48px;
			text-transform: uppercase;
			text-decoration: none;

			&:hover {
				color: var(--black, #464a51);

				svg {
					* {
						fill: var(--black, #464a51);
					}

					path:nth-child(2) {
						stroke: var(--black, #464a51);
					}
				}
			}

			svg {
				* {
					fill: var(--grey-4, #c8d2e0);
				}

				path:nth-child(2) {
					stroke: var(--grey-4, #c8d2e0);
				}
			}
		}
	}

	.events-container {
		padding-top: 32px;

		.events-items-container {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			column-gap: 24px;
			row-gap: 32px;
		}

		.pagination-container {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			gap: 10px;
			margin-top: auto;
			padding-top: 16px;

			.events-pagination-item {
				height: 10px;
				width: 10px;
				background: var(--grey-4);
				border-radius: 50%;
				margin-right: 16px;
				cursor: pointer;
				opacity: 0.7;
				transition: all 300ms;
				list-style: none;

				&:hover {
					opacity: 1;
					background: var(--deep-blue);
				}

				&.active {
					background: var(--deep-blue);
				}
			}
		}
	}
}

@media(max-width: $mobile-width-breakpoint){
	.poster{
		padding: var(--mobile-section-padding);
		@include flex-column;
		gap: var(--mobile-standart-row-gap);

		.poster-header{
			margin: 0px;
			@include flex-row;
			gap: var(--mobile-standart-row-gap);
			flex-wrap: wrap;

			span{
				@include p2-mobile-bold;
				text-transform: uppercase;
				color: var(--deep-blue);
				flex-grow: 1;
				@include flex-row;
				align-items: center;
				gap: 10px;

				&::after{
					content: '';
					height: 1px;
					background: var(--deep-blue);
					flex-grow: 1;
				}
			}

			a{
				width: 100%;
			}
		}

		.poster-content{
			@include flex-column;
			gap: var(--mobile-standart-row-gap);

			.calendar-slider-container{
				margin: 0px;

				.calendar-slider{
					gap: var(--mobile-small-row-gap);

					.calendar-slider-dates{
						flex-grow: 1;
						overflow-x: auto;

						&::-webkit-scrollbar{
							height: 0px;
						}
					}
				}
			}

			.events-container{
				padding: 0px;
				width: 100%;

				.events-items-container{
					@include flex-row;
					gap: var(--mobile-small-row-gap);
					max-width: 100%;
					overflow-x: auto;

					&::-webkit-scrollbar{
						height: 0px;
					}

					.event-card{
						@include flex-column;
						gap: var(--mobile-small-row-gap);
						min-width: 160px;
						max-width: initial;

						.image{
							width: 100%;
							height: 125px;
						}

						.info{
							@include flex-column;
							gap: var(--mobile-small-row-gap);

							.p2, .h6{
								margin: 0px !important;
								padding: 0px;
							}

							.p{
								@include p3-mobile;
							}

							.h6{
								@include h6-mobile;
							}
						}
					}
				}
			}
		}
	}
}
