.route-menu-page {
	display: flex;

	.route-menu-container {
		flex-basis: 240px;
		flex-shrink: 0;
		margin-right: 30px;

		div.route-menu-item {
			cursor: pointer;
			line-height: 1.4em;
			//text-align: right;
			padding: 10px 15px;
			border-bottom: 1px solid #ddd;

			&:last-of-type {
				border: 0;
			}

			&:hover {
				//color: var(--color-red);
				//text-decoration: underline;
				background: #f2f2f4;
			}

			&.active {
				background: #eee;
			}
		}
	}

	.route-menu-content {
		flex-grow: 1;
	}
}
