.feedback {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.block {
		display: flex;
		flex-direction: column;
		width: 100%;

		label {
			font-size: 16px;
			margin-bottom: 15px;
			margin-top: 20px;

			span {
				color: red;
			}
		}

		input {
			background-color: #F5F5F5;
			border: 1px solid #C4C4C4;
			height: 40px;
			padding: 10px;
		}

		textarea {
			max-width: 100%;
			min-width: 100%;
			min-height: 150px;
			background-color: #F5F5F5;
			border: 1px solid #C4C4C4;
			margin-bottom: 30px;
			padding: 10px;
		}

		.reason-select {
			background-color: #F5F5F5;

			&.active {
				.list {
					border-color: #C4C4C4;
				}
			}

			.list {
				background-color: #F5F5F5;
			}

			.filter {
				height: 40px;
				vertical-align: middle;
				border-radius: 0;
				border-color: #C4C4C4;

				.placeholder, input, .down-icon {
					padding-top: 3px;
				}
			}
		}
	}

	.block:nth-child(1), .block:nth-child(2) {
		label {
			margin-top: 0;
		}
	}

	.half-block {
		width: 49%;
	}

	.error {
		color: red;
		align-self: center;
		margin-right: 60%;
	}
}

@media (max-width: 768px) {
	.feedback{
		.half-block {
			width: 100%;
		}

		.block:nth-child(2) {
			label {
				margin-top: 20px;
			}
		}
	}
}
