@import 'site/styles/mediaBreakpoints';

.slider-small {
  width: 1200px;
  overflow: hidden;
  border-radius: 8px;
  margin-top: 48px !important;


  .small-slide-items {
    display: flex;
    transition: all 300ms;
    width: 1200px;


    .small-item {
      width: 342px;
      height: 202px;
      display: flex;
      padding-right: 16px;
    }
  }

  .small-indicators {
    display: flex;
    list-style: none;
    margin-top: 24px;
    justify-content: center;
  }
  .small-indicators li {
    height: 10px;
    width: 10px;
    background: var(--grey-4);
    border-radius: 50%;
    margin-right: 16px;
    cursor: pointer;
    opacity: .7;
    transition: all 300ms;

    &:hover {
      opacity: 1;
      background: var(--deep-blue);
    }

    &.active {
      background: var(--deep-blue);
    }
  }
}

.small-top-slide-container {
  width: 1200px;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 8px;

  .sliders-small {
    display: flex;
    border-radius: 8px;
    max-width: 100%;
    height: auto;
    overflow: hidden;

    img {
      min-width: 342px;
      height: 202px !important;
      object-fit: cover;
    }

    &.linked:hover {
      border: 0;
      box-shadow: none;
      transform: none;
    }

    div:first-child {
      width: 100%;
    }
  }
}

@media(max-width: $mobile-width-breakpoint){
  .slider-small{
    padding: var(--mobile-section-padding);
    max-width: 100% !important;
    margin: 0px !important;

    .small-slide-items{
      max-width: 100%;
      overflow-x: auto;
      display: flex;
      flex-direction: row;
      gap: var(--mobile-small-row-gap);

      &::-webkit-scrollbar{
        height: 0px;
      }

      .small-item{
        padding: 0px;

        .small-top-slide-container{
          display: block;
        }
      }
    }

    .small-indicators{
      display: none;
    }
  }
}