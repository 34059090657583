@import 'site/styles/mediaBreakpoints';

.logo-container {
    display: flex;
    position: relative;

    .logo-minus {
        display: flex;
        z-index: 2;
    }

    .logo-domain {
        display: flex;
        z-index: 1;
        position: absolute;
        width: 24px;
        top: 8px;
        left: 8px;
        height: 24px;
        border-radius: 30px;
    }

    .logo-root {
        display: flex;
        z-index: 1;
        position: absolute;
        width: 29px;
        height: 29px;
        top: 9px;
        left: 9px;
        border-radius: 30px;
    }
}

@media(max-width: $mobile-width-breakpoint){
    .logo-container{

        .logo-minus{
            width: 113px;
        }

        .logo-root{
            width: 26px;
            height: 26px;
            top: 7px;
            left: 7px;
        }
    }
}