div[class*="banner-"] {
	transition: all 250ms;
	overflow: hidden;

	&.linked:hover {
		// border-bottom: 1px solid #d00;
		// box-shadow: 0 3px 15px rgba(#000, 0.15);
		transform: translate(0, -3px);
	}

	img {
		object-fit: contain;
	}

}
