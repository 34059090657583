.rating-star {
  display: flex;
  flex-direction: row;
  gap: 15px;

  .star {
    font-size: 24px;
    cursor: pointer;

    &:hover {
      fill: var(--grey-4);
    }
  }
}