.sp-calendar {
  .month-year {
    .pick-month:hover,
    .pick-year:hover,
    svg:hover,
    .prev-month:hover,
    .next-month:hover {
      color: var(--bright-blue);
    }
  }
  .month-days {
    .day-cell{
      .default-day{
        color: var(--bright-blue);

        &:hover {
          border: 1px solid var(--bright-blue);
          color: var(--deep-blue);
        }

        &.current {
          color: var(--deep-blue);
          background: var(--bright);
          border-color: var(--deep-blue);

          &:hover {
            border: 1px solid var(--bright-blue);
            background: var(--bright-hover);
            color: var(--deep-blue);
          }
        }
      }
      .default-day.today:not(.current) {
        color: var(--deep-blue);

        &:hover {
          color: var(--bright-blue);
          border: 2px solid var(--bright-blue);
        }
      }

      .default-day.other {

        &:hover {
          color: var(--bright-blue);
        }
      }
    }
  }
}