@import 'site/styles/mediaBreakpoints';

.events-small-container {
  display: flex;
  flex-direction: row;
  min-height: 560px;
  gap: 24px;

  .events-small-left {
    width: 75%;

    .events-container {
      display: flex;
      flex-direction: column;
      margin-top: 32px;
      height: 400px;

      .events-items-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 24px;

        .events-small-events-item-container {
          display: flex;
          flex-direction: column;
          gap: 8px;
          flex: 1 0 0;
          max-width: 33%;
          &:hover {
            cursor: pointer;
          }

          .events-small-events-item-title {
            display: flex;
            font-family: "Golos Text", sans-serif;
            font-weight: 400;
            font-size: 12px;
            color: #8291A4;
          }

          .events-small-events-item-image img {
            display: flex;
            margin-top: 8px;
            border-radius: 8px;
            height: 100%;
            width: 100%;
          }

          .events-small-events-item-description-title {
            display: flex;
            flex-wrap: wrap;
            margin-top: 8px;
            font-family: "Golos Text", sans-serif;
            font-weight: 400;
            font-size: 14px;
            color: #1051A1;
            line-height: 21px;
          }

          .events-small-events-item-description {
            display: flex;
            flex-wrap: wrap;
            margin-top: 8px;
            font-family: "Golos Text", sans-serif;
            font-weight: 600;
            font-size: 18px;
            color: #464a51;
            line-height: 25.2px;
          }

          .events-small-events-item-organizator {
            display: flex;
            margin-top: 8px;
            font-family: "Golos Text", sans-serif;
            font-weight: 400;
            font-size: 12px;
            color: #8291A4;
          }
        }
      }

      .pagination-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-top: auto;
        padding-top: 16px;

        .events-pagination-item {
          height: 10px;
          width: 10px;
          background: var(--grey-4);
          border-radius: 50%;
          margin-right: 16px;
          cursor: pointer;
          opacity: 0.7;
          transition: all 300ms;
          list-style: none;

          &:hover {
            opacity: 1;
            background: var(--deep-blue);
          }

          &.active {
            background: var(--deep-blue);
          }
        }
      }
    }
  }

  .events-small-right {
    width: 260px;

    .events-small-holidays-container {
      display: flex;
      flex-direction: column;
      margin-top: 32px;

      .events-small-holidays-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        gap: 8px;
        cursor: pointer;

        .events-small-holidays-item-container {
          display: flex;
          flex-direction: column;

          .events-small-holidays-item-title {
            display: flex;
            font-family: "Golos Text", sans-serif;
            font-weight: 400;
            font-size: 14px;
            color: #1051A1;
          }

          .events-small-holidays-item-description {
            font-weight: 600;
            font-size: 18px;
            color: #464a51;
            margin-top: 8px;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }

        .events-small-holidays-item-icon {
          display: flex;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media(max-width: $mobile-width-breakpoint){
  .events-small-container{
    padding: var(--mobile-section-padding);
    @include flex-column;
    gap: var(--mobile-standart-row-gap);
    min-height: max-content;

    .section-card{
      margin: 0px;
      width: 100%;
      @include flex-column;
      gap: var(--mobile-standart-row-gap);

      .section-card__title{
        margin: 0px;
      }
    }

    .events-small-left{
      .content{
        @include flex-column;
        gap: var(--mobile-standart-row-gap);

        .calendar-slider-container, .events-container{
          margin: 0px;
        }

        .events-container{
          padding: 0px;
          width: 100%;
          height: max-content;

          .events-items-container{
            @include flex-row;
            gap: var(--mobile-small-row-gap);
            max-width: 100%;
            overflow-x: auto;

            &::-webkit-scrollbar{
              height: 0px;
            }

            .event-card{
              @include flex-column;
              gap: var(--mobile-small-row-gap);
              min-width: 160px;
              max-width: initial;

              .image{
                width: 100%;
                height: 125px;
              }

              .info{
                @include flex-column;
                gap: var(--mobile-small-row-gap);

                .p2, .h6{
                  margin: 0px !important;
                  padding: 0px;
                }

                .p{
                  @include p3-mobile;
                }

                .h6{
                  @include h6-mobile;
                }
              }
            }
          }

          .pagination-container{
            margin: 0px;
          }
        }

        .calendar-slider-container{
          margin: 0px;

          .calendar-slider{
            gap: var(--mobile-small-row-gap);

            .calendar-slider-dates{
              flex-grow: 1;
              overflow-x: auto;

              &::-webkit-scrollbar{
                height: 0px;
              }
            }
          }
        }

      }
    }

    .events-small-right{
      .content{
        width: 100%;

        .events-small-holidays-container{
          margin: 0px;
          @include flex-column;
          gap: var(--mobile-standart-row-gap);

          .events-small-holidays-item{
            margin: 0px;

            .events-small-holidays-item-title{
              @include p2-mobile;
            }

            .events-small-holidays-item-description{
              @include h6-mobile;
            }
          }
        }
      }
    }

  }
}