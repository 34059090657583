@import 'site/styles/mediaBreakpoints';

.question-answer-container {
	display: flex;
	max-width: 1200px;
	width: 100%;

	.question-answer {
		max-width: 800px;
	}

	.question-answer-appeal {
		display: flex;
		padding: 16px;
		flex-direction: column;
		gap: 16px;
		border-radius: 8px;
		background: #4e81b1;
		color: var(--white, #fff);

		button {
			border: none;
		}
	}

	.question-answer-poll {
		.content {
			display: flex;
			flex-direction: column;
			gap: 24px;

			button {
				font-weight: 400;
			}
		}
	}
}

@media(max-width: $mobile-width-breakpoint){
	.question-answer-container{
		padding: var(--mobile-section-padding);
		flex-direction: column;
		gap: var(--mobile-standart-row-gap);

		.question-answer{
			margin: 0px;
			display: flex;
			flex-direction: column;
			gap: var(--mobile-medium-row-gap);


			.section-card__title{
				margin: 0px;
			}

			.content{
				display: flex;
				flex-direction: column;
				gap: var(--mobile-medium-row-gap);

				.sp-tabs-container{
					margin: 0px;
					height: max-content;

					.sp-tabs-well{
						height: max-content;

						&::-webkit-scrollbar{
							height: 0px;
						}
					}
				}

				.list{
					display: flex;
					flex-direction: column;
					gap: var(--mobile-medium-row-gap);

					.question-answer-card{
						margin: 0px;
						gap: var(--mobile-standart-row-gap);

						.images{
							margin: 0px;
							min-width: max-content;
							height: max-content	;

							img{
								height: 87px;
							}
						}

						.question-answer-card-items{
							flex-grow: 1;
							overflow: hidden;

							.text-middle{
								display: none;
							}

							.question-answer-title{
								@include h6-mobile;
								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box;
								-webkit-line-clamp: 2;
							}

							.date{
								color: var(--grey-3);
							}
						}
					}
				}
			}
		}

		.question-answer-poll{
			display: flex;
			flex-direction: column;
			gap: var(--mobile-standart-row-gap);

			.section-card__title{
				margin: 0px;
			}

			.content{
				gap: var(--mobile-standart-row-gap);

				.discussions-poll{
					display: flex;
					flex-direction: column;
					gap: var(--mobile-small-row-gap);
					height: max-content;

					.poll-text{
						height: max-content;
						margin: 0px;
					}

					.poll-data, .poll-site, .sp-btn{
						margin: 0px;
					}

					.poll-data, .poll-site{
						@include p3-mobile;
					}

					.sp-btn{
						padding: 8px 16px;
						@include p1-mobile;
						height: max-content;
					}
				}

				& > .sp-btn{
					padding: 8px 16px;
					@include p1-mobile;
					height: max-content;
				}
			}
		}

		.question-answer-appeal{
			gap: var(--mobile-small-row-gap);

			.h5{
				@include h7-mobile;
			}

			.p2{
				@include p3-mobile;
				line-height: 140%;
			}

			.sp-btn{
				padding: 8px 16px;
				@include p1-mobile;
				height: max-content;
			}
		}
	}
}
