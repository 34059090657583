a,
p {
	color: var(--black);
}

.exchange-name {
	display: flex;
	justify-content: flex-end;
}

.currencies {
	display: flex;
	padding: 8px 0;

	.currencies-item {
		display: flex;

		&.euro {
			padding-left: 16px;
		}

		.course {
			display: flex;

			.dollar-course,
			.euro-course {
				padding: 0 8px;
			}

			.count {
				color: var(--grey-3);
			}
		}
	}
}
