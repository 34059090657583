@import 'admin/styles/mixins';

.rating-wrapper {
	display: flex;
	gap: 16px;
	flex-wrap: nowrap;
	align-items: center;

	.putRating {
		@include P2;
		color: var(--grey-1);
	}

	.averageRating {
		@include H7;
		color: var(--grey-1);
	}

	.ratingsNumber {
		@include P3;
		color: var(--grey-1);
	}

	.stars {
		display: flex;
		flex-wrap: nowrap;

		svg {
			width: 28px;
			height: 24px;
		}

		.filled-light {
			fill: var(--light);
		}

		.filled-dark {
			path {
				stroke: var(--deep-blue);
			}

			fill: var(--deep-blue);
		}

		&:hover {
			.star:not(.disabled) {
				cursor: pointer;

				path {
					stroke: var(--deep-blue);
				}

				fill: transparent;

				&.filled-dark-hover {
					path {
						stroke: var(--deep-blue);
					}

					fill: var(--deep-blue);
				}
			}
		}

		.star:hover~.star {
			*[fill] {
				fill: #cce3f0;
			}
		}
	}
}