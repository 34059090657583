@import 'site/styles/mediaBreakpoints';

.events-page {
	.announcement {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-gap: 24px;
		.announcement-item {
			cursor: pointer;
			text-decoration: initial;
			max-width: 280px;

			.image {
				overflow: hidden;
				border-radius: 8px;
				width: 280px;
				height: 200px;
				img {
					width: 100%;
					object-fit: contain;
					transition: all 0.25s ease;
				}
				svg {
					height: 100%;
				}
			}
			.p3 {
				color: var(--grey-2);
			}
			.p2 {
				color: var(--deep-blue);
			}
			&:hover {
				img {
					transform: scale(1.1);
				}
				.p2 {
					color: var(--deep-blue-hover);
				}
				.h6 {
					color: var(--deep-blue);
				}
				.p3 {
					color: var(--grey-2-hover);
				}
			}
		}
	}
}

@media(max-width: $mobile-width-breakpoint){
	.events-page {
		.announcement{
			@include flex-column;
			flex-wrap: wrap;
			gap: var(--mobile-medium-row-gap);
			width: 100%;

			.event-card{
				min-width: 100%;
				@include flex-column;
				gap: var(--mobile-small-row-gap);

				& > .p3{
					@include p3-mobile;
				}

				.image{
					width: 100%;
					height: max-content;
					max-height: 195px;
					@include flex-column;
					justify-content: center;
					align-items: center;

					picture{
						min-width: 100%;
					}
				}

				.info{
					@include flex-column;
					gap: var(--mobile-small-row-gap);

					p{
						margin: 0px !important;
					}

					.p2{
						@include p2-mobile;
					}

					.h6{
						@include h6-mobile;
					}

					.p3{
						@include p3-mobile;
					}
				}
			}
		}

		.no-record{
			@include p1-mobile;
		}
	}
}
