.calendar-slider-container {
	display: flex;
	flex-direction: column;
	margin-top: 32px;
	color: #6d7886;

	.calendar-slider {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 8px;

		.date-backward,
		.date-forward {
			cursor: pointer;
		}

		.calendar-slider-dates {
			display: flex;

			.current-day {
				background-color: var(--deep-blue) !important;
				color: var(--white);
			}

			.day-container {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: 51px;
				height: 54px;
				padding: 8px 15px;
				border-radius: 12px;

				&:hover {
					cursor: pointer;
					background-color: var(--grey-6-hover);
				}

				.month {
					position: absolute;
					top: -29px;
					color: #6d7886;
				}

				.day-of-week {
					display: flex;
					font-family: 'Golos Text', sans-serif;
					font-weight: 500;
					font-size: 10px;
					color: #a8afb8;
					text-transform: uppercase;
				}

				.day {
					display: flex;
					font-family: 'Golos Text', sans-serif;
					font-weight: 600;
					font-size: 16px;
					text-transform: uppercase;
				}
			}
		}
	}
}
