@import 'site/styles/mediaBreakpoints';

.discussion {
	padding: 48px 0;

	.discussion-polls {
		border-radius: 8px;
		border: 1px solid var(--basic-grey-6, #F1F7FA);
		background: var(--basic-white, #FFF);
		padding: 24px;

		&.discussion-polls-warning {
			.warning-text {
				display: flex;
				gap: 16px;
				margin-bottom: 24px;
			}

			.warning-button {
				border-radius: 24px;
				background: var(--primary-deep-blue, #1051A1);
				height: 48px;
				min-width: 135px;
				padding: 12px 24px 12px 16px;
				color: var(--basic-white, #FFF);
				font-size: 16px;
				font-weight: 600;
				line-height: 150%;

				svg {
					* {
						fill: #CCE3F0;
					}
				}
			}
		}

		&:has(.discussion-result) {
			display: grid;
			grid-template-columns: 1fr 1fr;

			.poll-chart {
				position: relative;
				height: 40rem;
				top: 0;
				width: 100%;
				display: flex;
				justify-content: center;
			}
		}

		.discussion-poll {
			.discussion-poll-name {
				color: var(--basic-black, #464A51);
				font-size: 16px;
				font-weight: 600;
				line-height: 150%;
				margin-bottom: 16px;
			}

			.ui-input {
				margin-right: 10px;
			}

			.poll-form-field {
				margin-bottom: 16px;

				input {
					width: initial;
				}

				.custom {
					padding: 16px 14px;
					border-radius: 24px;
					border: 1px solid var(--basic-grey-5, #E4EEF2);
					background: var(--basic-grey-7, #FAFCFF);
					height: 48px;
					width: 60%;
				}

				.checkbox-input {
					width: 20px;
					height: 20px;
				}

				&:only-child {
					.radio-input {
						display: none;
					}
				}
			}
		}

		.discussion-submit {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 12px 24px;
			height: 48px;
			width: 130px;
			border-radius: 24px;
			font-size: 16px;
		}

		.has-image form {
			display: grid;
			grid-template-columns: 1fr 1fr;

			.image {
				width: 380px;
				height: 260px;
				margin-bottom: 10px;

				img {
					object-fit: contain;
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	.discussion-name {
		color: var(--basic-black, #464A51);
		font-size: 32px;
		font-weight: 600;
		line-height: 140%;
		margin-bottom: 24px;
	}

	.discussion-end {
		color: var(--basic-grey-2, #8291A4);
		font-size: 14px;
		font-weight: 400;
		line-height: 150%;
		margin-bottom: 24px;
	}

	.discussion-description {
		margin-bottom: 24px;
	}
}

@media(max-width: $mobile-width-breakpoint){
	.page-content-single{
		&.discussion{
			padding: var(--mobile-wrapper-content-padding-vertical);
			margin: 0px;
			@include flex-column;
			gap: var(--mobile-small-row-gap);

			.breadcrumbs{
				display: none;
			}

			& > div{
				margin: 0px;
				padding: var(--mobile-wrapper-content-padding-horizontal);
			}

			.discussion-name{
				@include h1-mobile;
			}

			.discussion-end{
				@include p2-mobile;
			}

			.discussion-description{
				@include p1-mobile;
			}

			.discussion-polls{
				border-radius: 0px;
				padding: var(--mobile-wrapper-content-padding-all);
				@include flex-column;
				gap: var(--mobile-standart-row-gap);

				&.discussion-polls-warning{
					@include flex-column;
					gap: var(--mobile-standart-row-gap);

					.warning-text{
						margin: 0px;
						@include p2-mobile;
					}
				}

				.discussion-poll{
					@include flex-column;
					gap: var(--mobile-small-row-gap);

					.discussion-poll-name{
						margin: 0px;
					}

					form{
						@include flex-column;
						gap: var(--mobile-standart-row-gap);

						.poll-form-field{
							margin: 0px;

							label{
								@include p2-mobile;
							}
						}
					}

					&.has-image{
						.poll-form-field{
							.image{
								width: 100%;
								height: auto;
							}
						}
					}

					&.discussion-result{

						.poll-chart{
							max-height: 150px;
						}
					}
				}

				button{
					height: max-content;
					width: 100%;
				}
			}

			.wrapper-back{
				padding: var(--mobile-wrapper-content-padding-horizontal);
				@include flex-row;
				align-items: center;
				gap: var(--mobile-small-row-gap);
				color: var(--grey-3);
			}
		}
	}
}
