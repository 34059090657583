.banners-468x60-container {
	margin: 30px auto;
	//background: #f5f5f5;
	//padding: 30px 0;

	.banners-468x60 {
		width: 1200px;
		height: 60px;
		display: flex;
		margin: 0 auto;
		justify-content: space-between;

		.banner-468x60 {
			// width: 468px;
			// height: 60px;
			// display: flex;
			// align-items: center;
			// justify-content: center;
			// text-align: center;
			// background: #ddd;
			// margin: 0 auto;
		}

	}
}

@media (max-width: 1199px) {
	.banners-468x60-container {
		display: none;
	}
}