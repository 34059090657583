@import "client/styles/mixins";

.sp-btn {
    .icon-content {
        display: flex;
        gap: 5px;
        align-items: center;
        height: 100%;
    }

    &.transparent-underline {
        @include P2;
        background-color: unset !important;
        color: var(--grey-3);
        border: unset;
        border-bottom: 1px solid var(--grey-3);
        border-radius: unset;
        padding: 2px 0;
        height: 25px;
    }

    &.transparent {
        @include P2;
        background: red !important;
        border: 1px solid var(--deep-blue);
    }

    &.deep-blue {
        background: var(--deep-blue);
        border: 1px solid var(--deep-blue);

        &:active {
            border: 1px solid var(--deep-blue);
            color: var(--deep-blue);
        }
    }

    &.light-blue {
        // primary - светло синяя
        background: var(--light);
        color: var(--deep-blue);
        border: 1px solid var(--light);
    }

    &.green {
        // success - зеленая
        background: #75bf78;
        border: 1px solid #75bf78;
        color: var(--white);

        &:active {
            color: #75bf78;
        }
    }

    &.blue {
        // info - голубая
        background-color: var(--blue);
        border: 1px solid var(--blue);
        color: var(--white);

        &:active {
            color: var(--blue);
        }
    }

    &.grey {
        background: var(--grey-6);
        color: var(--grey-1);
        border: 1px solid var(--grey-6);

        &:hover {
            box-shadow: unset;
        }

        &:active {
            background: transparent;
        }

        svg {
            *[fill] {
                fill: var(--grey-2);
            }

            *[stroke] {
                fill: var(--grey-2);
            }
        }
    }

    &.white {
        background: var(--white);
        color: var(--deep-blue);
        border: 1px solid var(--deep-blue);
    }

    &-small {
        @include P2;
        border-radius: 24px;
        height: var(--element-height-small) !important;
    }
}