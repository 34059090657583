@import 'client/styles/mixins';

.latest-materials {
	width: 282px;
	.content {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 24px;
	}
}
