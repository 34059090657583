.slider-big {
  width: 1300px;
  margin: 20px auto;
  overflow: hidden;
  border-radius: 8px;

  .big-slide-items {
    display: flex;
    transition: all 300ms;

    .big-item {
      width: 1300px;
      height: 606px;
    }
  }

  .big-indicators {
    display: flex;
    list-style: none;
    margin-top: 16px;
    justify-content: center;
  }
  .big-indicators li {
    height: 10px;
    width: 10px;
    background: var(--grey-4);
    border-radius: 50%;
    margin-right: 16px;
    cursor: pointer;
    opacity: .7;
    transition: all 300ms;
  }
  .big-indicators li:hover {
    opacity: 1;
    background: var(--deep-blue);
  }
  .big-indicators li.active {
    background: var(--deep-blue);
  }
}

.big-top-slide-container {
  width: 1300px;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 8px;

  .sliders-big {
    display: flex;
    width: 100%;
    overflow: hidden;
    color: #fff;
    max-width: 100%;

    .slide-picture {
      width: 1200px;
      height: 606px;
      border-radius: 8px;
    }

    &.linked:hover {
      border: 0;
      box-shadow: none;
      transform: none;
    }

    div:first-child {
      width: 100%;
    }
  }
}

@media (max-width: 1199px) {
  .big-top-slide-container {
    display: none;
  }
}