.sp-dropdown {
    //height: calc(var(--element-height-no-px) * 1em / 16);
    position: relative;
    //border-color: var(--grey-5);
    border-radius: 24px;
    //border: 1px solid var(--grey-5) !important;
    //background: var(--grey-7);
    --dropdown-selected-bg: var(--deep-blue);
    --dropdown-selected-text: var(--white);
    color: var(--black);

    &-item {
        @include P2;
        color: var(--black);
        height: calc(var(--element-height) * 0.8) !important;
    }

    &-items {
        max-height: 600px !important;
        border-right: 1px solid var(--grey-5);
        border-left: 1px solid var(--grey-5);
    }

    @include P2;

    &.grey {
        background: var(--grey-6);
        color: var(--grey-1);
    }

    .filter-arrow {
        display: none;
    }

    .filter {
        height: 100%;
        width: calc(100% - 30px);

        &::before {
            transition: transform 250ms;
            position: absolute;
            content: "";
            right: 20px;
            top: 16px;
            width: 10px;
            z-index: 1;
            height: 6px;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M9 1L5 5L1 1' stroke='%23B8B8B8' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e ");
        }

        .show-value {
            font-size: 14px;
            height: 100%;
            padding: 0 5px;
            align-items: center;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .item-tag {
                display: inline-block;
                vertical-align: top;
                background: #ddd;
                color: #333;
                font-weight: normal;
                max-width: calc(100% - 24px);
                margin: 1px;
                border-radius: 2px;
                padding: 0 7px;
                height: 26px;
                border: 1px solid #ccc;
                line-height: 24px;
                user-select: none;
                white-space: nowrap;

                .item-tag-content {
                    display: inline-block;
                    vertical-align: middle;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 12px;
                }

                svg {
                    vertical-align: middle;
                }

                .remove-btn {
                    cursor: pointer;
                    margin-left: 10px;
                }
            }
        }
    }

    &.active {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;

        .filter {
            &::before {
                transform: scale(1, -1);
            }
        }
    }

    &.small {
        @include P2;
        border-radius: 24px;
        height: var(--element-height-small);

        .filter {
            &::before {
                transition: transform 250ms;
                position: absolute;
                content: "";
                right: 14px;
                top: 15px;
                width: 10px;
                z-index: 1;
                height: 6px;
                background-repeat: no-repeat;
                background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M9 1L5 5L1 1' stroke='%23B8B8B8' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e ");
            }
        }
    }
}

.sp-trigger-popup {
    .sp-dropdown {
        .actions {
            height: calc(var(--element-height) * 0.8);
            border-radius: 0 0 24px 24px;
        }

        &.grey {
            .sp-dropdown-items {
                border: 1px solid var(--grey-5);
            }
        }
    }
}