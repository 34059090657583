@import 'client/styles/mixins';
@import 'site/styles/mediaBreakpoints';

.documents-page {
	.filters {
		display: flex;
		flex-direction: column;
		gap: 30px;

		.sp-date-picker {
			width: 180px;
		}

		.bottom {
			display: flex;
			gap: 8px;
		}

		.document-types {
			margin-top: 20px;

			.title {
				@include H5;
				margin-bottom: 16px;
			}

			@include P2;
			color: var(--grey-1);

			.list {
				display: flex;
				gap: 8px;
				flex-wrap: wrap;

				.sp-btn {
					margin-left: unset;
				}

				.count {
					font-weight: bold;
				}
			}
		}
	}

	.content {
		margin-top: 24px;
		padding-bottom: 64px;

		.top {
			display: flex;
			justify-content: space-between;
			@include P2;
			margin-bottom: 16px;

			.total-count {
				color: var(--grey-2);
			}

			.order-by {
				display: flex;
				gap: 10px;
				align-items: center;
			}
		}

		.auto-animate {
			display: flex;
			flex-direction: column;
			gap: 24px;
		}
	}
}

@media (max-width: $mobile-width-breakpoint) {
	.documents-page {
		@include flex-column;
		gap: var(--mobile-standart-row-gap);
		padding: var(--mobile-section-padding);

		.filters {
			gap: var(--mobile-standart-row-gap);

			.document-types {
				margin: 0px;
				@include flex-column;
				gap: var(--mobile-standart-row-gap);

				.title {
					margin: 0px;
					@include h5-mobile;
					color: var(--black);
				}

				.list {
					button {
						padding: 4px 10px;
						height: max-content;
						@include p2-mobile;
					}

					.hide-mobile {
						display: none;
					}

					&.show {
						.hide-mobile {
							display: block;
						}
					}

					.show-all-types {
						@include p2-mobile;
						color: var(--grey-2);
						width: 100%;
						margin-top: 8px;
					}
				}
			}

			.bottom {
				max-width: 100%;
				@include scroll-horizontal;

				.sp-trigger {
					min-height: max-content;
					min-width: 140px;

					input {
						@include p2-mobile;
					}
				}
			}
		}

		.content {
			padding: 0px;
			margin: 0px;
			@include flex-column;
			gap: var(--mobile-standart-row-gap);

			.top {
				margin: 0px;
				display: flex;
				flex-direction: column-reverse;
				gap: var(--mobile-standart-row-gap);

				.order-by {
					@include p2-mobile;
				}

				.total-count {
					@include p2-mobile;
				}
			}

			.auto-animate {
				gap: var(--mobile-standart-row-gap);

				.simple-card {
					gap: var(--mobile-small-row-gap);
					padding: var(--mobile-wrapper-content-padding-all);
					flex-direction: column-reverse;

					.info {
						gap: var(--mobile-small-row-gap);

						.top {
							gap: var(--mobile-small-row-gap);
							flex-direction: column;
							align-items: flex-start;

							.number {
								@include flex-row;
								gap: 10px;
								@include p1-mobile;
							}
						}

						.name {
							@include p2-mobile;
						}

						.organization-wrapper {
							flex-direction: column;
							gap: var(--mobile-small-row-gap);

							.organization,
							.publishAt {
								@include p2-mobile;
							}
						}

						.description {
							.toggle,
							.description-content {
								@include p2-mobile;
							}

							.description-content {
								padding-top: 8px;
							}
						}
					}

					.file {
						align-items: flex-start;

						.sp-btn {
							min-width: max-content;
							height: max-content !important;
							padding: 8px 12px;

							.icon-content {
								@include p2-mobile;
							}
						}
					}
				}
			}
		}
	}
}
