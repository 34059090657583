.podcast-player {
	background: #fff;
	padding: 15px;
	border-radius: 10px;
	border-bottom: 3px solid #E21D24;
	display: flex;
	align-items: center;
	.content {
		flex-grow: 1;
	}
	.image {
		margin-right: 15px;
		img {
			height: 70px;
			width: 70px;
			border-radius: 50%;
			object-fit: cover;
		}
	}
	.active-count {
		display:none
	}
	&.single {
		background: var(--light-grey);
	}

	.podcast-title {
		font-size: 16px;
		padding-bottom: 15px;

	}

	.podcast-controls {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.sp-range-line {
			background: var(--red);
		}

		.status-line {
			width: 100%;
			margin-top: -5px;

			.status {
				&-dark-line {
					width: 0;
					height: 5px;
					position: absolute;
					top: 0;
					left: 0;
					background-color: #969696;
					cursor: pointer;
				}

				&-line-pin {
					width: 12px;
					height: 12px;
					border-radius: 6px;
					position: absolute;
					top: -3px;
					left: 0;
					background-color: #969696;
					cursor: pointer;
					z-index: 99999;
				}
			}
		}


		.volume-line {
			width: 100%;
			height: 5px;
			display: block;
			background-color: #E5E5E5;
			position: relative;
			cursor: pointer;
			margin-bottom: 10px;

			.volume {
				&-dark-line {
					width: 0;
					height: 5px;
					position: absolute;
					top: 0;
					left: 0;
					background-color: #969696;
					cursor: pointer;
				}

				&-line-pin {
					width: 12px;
					height: 12px;
					border-radius: 6px;
					position: absolute;
					top: -3px;
					left: 0;
					background-color: #969696;
					cursor: pointer;
				}
			}
		}

		.timeline {
			flex-grow: 1;
			padding-right: 15px;
			//width: 60%;
			height: 32px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.current-time,
			.rest-time {
				color: #969696;
			}
		}

		.buttons {
			display: flex;
			align-items: center;
			flex-basis: 280px;
			flex-shrink: 0;
			//min-width: 20%;
			margin-top: -22px;

			img {
				margin-right: 13px;
				cursor: pointer;

				&:hover {
					filter: grayscale(100%);
				}
			}

			.button-speed {
				font-weight: bold;
				font-size: 18px;
				color: #E21D24;
				margin-left: 17px;
				cursor: pointer;
				user-select: none;

				&:hover {
					filter: grayscale(100%);
				}
			}
		}

		.volume {
			flex-basis: 80px;
			flex-shrink: 0;
			//width: 80px;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			color: #969696;

			.sp-slide-input {
				width: 100%;
			}
		}
	}
}
