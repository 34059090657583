.media-filters {
	margin-bottom: 32px;

	.selectors {
		display: flex;
		column-gap: 8px;
	}

	.sort {
		display: flex;
		align-items: center;
		gap: 8px;
		margin-left: auto;
	}
}