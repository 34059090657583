.slider {
  width: 894px;
  margin: 20px auto;
  overflow: hidden;

  .slide-items {
    display: flex;
    transition: all 300ms;

    .item {
      width: 894px;
      height: 280px;
      vertical-align: top;
      font-size: 15px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      text-transform: uppercase;
    }
  }

  .indicators {
    display: flex;
    list-style: none;
    margin-top: 16px;
    justify-content: center;
  }
  .indicators li {
    height: 10px;
    width: 10px;
    background: var(--grey-4);
    border-radius: 50%;
    margin-right: 16px;
    cursor: pointer;
    opacity: .7;
    transition: all 300ms;
  }
  .indicators li:hover {
    opacity: 1;
    background: var(--deep-blue);
  }
  .indicators li.active {
    background: var(--deep-blue);
  }
}

.top-slide-container {
  width: 894px;
  margin: 0 auto;
  background: var(--light-green);
  text-align: center;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 8px;

  .sliders {
    display: flex;
    //position: static;
    width: 100%;
    height: 100%;
    overflow: hidden;
    color: #fff;
    max-width: 100%;

    .slide-text-block {
      justify-content: center;
      align-items: stretch;
      text-align: left;
      width: 50%;
      padding: 32px;

      .slide-header {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        text-transform: none;
        color: var(--white);
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .slide-description {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-transform: none;
        color: var(--white);
        padding-top: 16px;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .slide-text-block-button-container {
        position: absolute;
        bottom: 0;
        padding-bottom: 32px;

        .slide-text-block-button {
          display: flex;
          height: 48px;
          padding: 12px 24px;
          align-items: center;
          border: none;
          border-radius: 24px;
          background: var(--grey-6);
          color: var(--grey-1);
          cursor: pointer;

          &:hover {
            background: var(--grey-6-hover);
          }
        }
      }
    }

    &.linked:hover {
      border: 0;
      box-shadow: none;
      transform: none;
    }

    div:first-child {
      width: 100%;
    }
  }
}
