@import 'site/styles/mediaBreakpoints';
@import "client/styles/mixins";

.wrapper-cookie-message-popup{
  position: fixed;
  z-index: 1000;
  bottom: 24px;
  width: var(--site-width);
  left: calc((100% - var(--site-width)) / 2);
  padding: 16px 24px;
  border: 1px solid #CCE3F0;
  background: var(--grey-6);
  border-radius: 24px;
  @include flex-row;
  justify-content: space-between;

  .left{
    @include flex-column;
    gap: var(--mobile-small-row-gap);

    .head{
      color: var(--deep-blue);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
    }

    .additional-text{
      color: var(--grey-2);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;

      a{
        color: var(--blue);
        text-decoration: underline;
      }

      svg{
        margin-left: 5px;
        vertical-align: middle;
      }
    }
  }

  .button-accept{
    padding: 12px 24px;
    border-radius: 50px;
    background: var(--deep-blue);
    cursor: pointer;
    color: var(--white);
    @include H7;
  }
}

@media(max-width: $mobile-width-breakpoint){
  .wrapper-cookie-message-popup{
    width: calc(100vw - 32px);
    left: 16px;
    padding: 12px;
    flex-direction: column;
    gap: var(--mobile-small-row-gap);
    align-items: center;

    .left{

      .head{
        @include p1-mobile;
        text-align: center;
        font-weight: 600;
      }

      .additional-text{
        text-align: center;
      }
    }
  }
}