@import 'site/styles/mediaBreakpoints';

.main-wrapper-mobile-filters{

  .filter-show-control{
    padding: 8px 16px;
    width: 100%;
    border-radius: 50px;
    background: var(--grey-6);
    @include p1-mobile;
    text-align: center;
    color: var(--grey-1);
  }

  .wrapper-moving-panel{
    position: fixed;
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    @include scroll-vertical;
    background: var(--grey-6);
    @include flex-column;
    gap: var(--mobile-standart-row-gap);
    top: 0px;
    left: 0px;
    z-index: 1000;
    transition: all .5s;
    transform: translate(-100%, 0);

    &.active{
      transform: translate(0,0);
    }
  }

  .wrapper-head-panel{
    padding: var(--mobile-wrapper-content-padding-all);
    background: var(--white);
    @include flex-row;
    align-items: center;
    justify-content: space-between;
  }
}

body:has(.wrapper-moving-panel.active){
  overflow: hidden !important;
}

.wrapper-input-item-mobile-filter{
  padding: var(--mobile-wrapper-content-padding-all);
  background: var(--white);

  .wrapper-head{
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    @include p2-mobile-bold;
    color: var(--grey-2);

    svg{
      transition: all .3s;
    }

    &.open{

      svg{
        transform: rotateZ(180deg);
      }
    }
  }

  .wrapper-content-mobile-filter{
    max-height: 0px;
    overflow: hidden;

    &.active{
      max-height: max-content;
    }

    .text-input{
      height: max-content;
      padding: 8px 16px;
      margin-top: 16px;
    }

    .wrapper-radio-buttons{

      .list-items{
        .items{
          .item-id{
            display: none;
          }
        }
      }
    }
  }
}