@import 'site/styles/mediaBreakpoints';

.search-page {
	padding-top: 30px;
	background: linear-gradient(var(--white) 180px, var(--grey-7) 0%);
	height: 103px;
	margin-bottom: 40px;
	max-width: 100%;

	.content {
		max-width: var(--site-width);
		display: flex;
		flex-direction: column;
		margin: 0 auto;

		.header {
			z-index: 92;
		}
	}

	svg {
		position: absolute;

		&.stars-left {
			left: 0;
			top: 124px;
			z-index: 90;
		}

		&.stars-right {
			top: 0;
			right: 0;
			z-index: 96;
		}
	}

	.search-title-line {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.right {
			display: flex;
			align-items: center;
			gap: 20px;
		}

		svg {
			position: static;
		}
	}
	span {
		margin-bottom: 24px;
		min-height: 21px;
	}

	.search-container {
		z-index: 92;

		.search {
			position: relative;

			::placeholder {
				font-size: 14px;
				font-weight: 400;
				color: var(--grey-3);
			}

			.search-icon {
				position: absolute;
				left: 14px;
				top: 12px;
				z-index: 6;
			}

			.search-input {
				height: 48px;
				width: 100%;
				background: var(--white);
				border: 1px solid var(--grey-5);
				box-shadow: 0 0 16px rgba(28, 93, 168, 0.04);
				border-radius: 32px;
				padding-left: 50px;
			}

			.search-button {
				position: absolute;
				top: 8px;
				right: 10px;
				width: 81px;
				height: 32px;
				background: var(--deep-blue);
				border-radius: 24px;
				border: none;
				color: var(--white);
				font-weight: 400;
				cursor: pointer;
			}
		}
		.search-filter {
			display: flex;
			color: var(--black);
			white-space: nowrap;
			overflow: auto;
			height: auto;
			padding-bottom: 5px;

			&::-webkit-scrollbar {
				height: 10px;
			}

			:nth-child(1n) {
				margin: 8px 10px 0 0;
			}

			.search-filter_item {
				color: var(--grey-2);
				font-weight: 400;
				border-bottom: 1px solid var(--grey-4);

				&:hover {
					cursor: pointer;
					color: var(--black);
				}
			}
		}
	}
}

.search-page-with-crumbs {
	padding-top: 0;

	.search-title-line {
		padding-top: 10px;
	}
}

@media (max-width: $tabled-width-breakpoint) {
	.search-page {
		& > svg:last-child {
			display: none;
		}
	}
}

@media (max-width: $mobile-width-breakpoint) {
	.search-page {
		margin: 0px;
		padding: var(--mobile-wrapper-content-padding-all) !important;
		height: max-content;

		.content {
			@include flex-column;
			gap: var(--mobile-standart-row-gap);

			.header {
				margin: 0px;
				@include h1-mobile;
				color: var(--black);
			}

			.search-container {
				@include flex-column;
				gap: var(--mobile-small-row-gap);

				.search {
					.search-input {
						height: max-content;
						padding: 8px 50px 8px 16px;
					}

					svg {
						right: 16px;
						left: auto;
						width: 20px;
						top: 7px;
					}
				}

				.search-filter {
					padding: 0px;
					max-width: 100%;
					overflow-x: auto;
					@include flex-row;
					gap: 10px;

					&::-webkit-scrollbar {
						height: 0px;
					}

					span {
						margin: 0px;
						@include p2-mobile;
					}
				}
			}
		}
	}
}
