@import 'client/styles/mixins';

.material-record-card {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 24px;

	.title {
		@include H1;
	}

	.material-image {
		width: 100%;
		max-height: 535px;
		object-fit: cover;
		border-radius: 8px;
	}

	.head-item {
		width: 100%;
		padding-top: 24px;
		font-size: 32px;
		font-style: normal;
		font-weight: 600;
		line-height: 140%;
		color: var(--black);
	}

	.item-image {
		width: 100%;
		height: 100%;
		border-radius: 8px;
		object-fit: cover;
	}

	.social-share {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		text-transform: uppercase;
		color: var(--grey-1);
		@include P4;
		opacity: 50%;
	}
}