$mobile-width-breakpoint: 640px;

.wrapper-text-with-documents{
    display: flex;
    flex-direction: column;
    gap: 10px;

    .documents-page{
      margin-bottom: 10px;
    }
}

@media(max-width: $mobile-width-breakpoint){
    .wrapper-text-with-documents{
        padding: var(--mobile-section-padding);

        .documents-page{
          padding: 0px;
        }
    }
}