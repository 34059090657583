@import 'site/styles/mixins';

.page-not-found {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-top: 280px;
	& > * {
		margin-bottom: 20px;
	}
	.page {
		@include H1;
	}
	.link {
		text-decoration: none;
		color: #0af;
	}
}
