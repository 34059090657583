.tooltip {
  display: flex;
  position: absolute;
  width: 100px;
  justify-content: center;
  align-items: center;
  padding: 6px 24px;
  text-align: center;
  color: var(--black);
  background-color: var(--grey-4);
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  line-height: 150%;
}