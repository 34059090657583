.top-banner-container-600x300 {
	width: 100%;
	height: auto;

	.banner-600x300 {
		// баннер всегда отцентрован и не вызывает скроллбар при ширине браузера < 1920px
		width: 100%;
	
		padding-bottom: 50%;
		overflow: hidden;
		position: relative;
		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: fill;
		}
		&.linked:hover {
			border: 0;
			box-shadow: none;
			transform: none;
		}
	}
}

@media (max-width: 1199px) {
	.top-banner-container {
		display: none;
	}
}
