.captcha {
	.captcha-status {
		color: red;
	}

	.captcha-input {
		display: flex;
		gap: 5px;
		width: 50%;
		height: 35px;

		input {
			height: 100%;
		}

		button {
			width: 70%;
			height: 100%;

		}
	}
}