.sp-trigger {
	display: inline-block;
	vertical-align: baseline;
}

.sp-portal {
	$border-radius: 4px;

	&.sp-trigger-popup {
		//background: var(--popover-bg, #fff);
		//color: var(--popover-text, #000);
		//min-width: 100px;
		//border-radius: $border-radius;
		//border: 1px solid rgba(#000, 0.15);
		//box-shadow: var(--popover-shadow, 0 3px 5px 1px rgba(#000, 0.1));
	}

}
