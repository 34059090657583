.open-data-filters {
  .selectors {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .category-organization-date-picker {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }

    .data-organization-select {
      width: 150px;
    }

    .date-picker {
      width: 185px;
    }
  }

}
