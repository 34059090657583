.breadcrumbs {
	margin-bottom: 15px;
    font-weight: 400;
	 font-size: 14px;
	// font-family: 'Roboto Condensed', sans-serif;

	.bc-arr {
		color: var(--grey-3);
        padding: 0 16px;
	}

	a,
	.bc-title {
		color: var(--grey-3);
		text-decoration: none;
        // padding-right: 16px;
	}

	a:hover {
		color: var(--grey-3-hover);
	}
}
