@import "site/styles/mixins";

.background-sample {
  //для показа, потом удалить
  padding: 5px;
  width: 394px;
  height: 510px;
  border-radius: 8px;
  position: absolute;
  top: 200px;
  z-index: 99;

  .widget5 {
    width: 384px;
    height: 500px;
    border-radius: 8px;
    border: 1px solid var(--translucent-white-60);
    background: var(--translucent-white-30);
    backdrop-filter: blur(12px);

    .widget-weather {
      width: 384px;
      height: 157px;
      padding: 16px 24px;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      background: rgba(16, 81, 161, .3);
      backdrop-filter: blur(12px);

      .city-date {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        color: var(--white);
        padding-bottom: 16px;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;

        .city-weather {
          .city {
            @include H7;
          }
          .city-county {
            @include P3;
          }
        }

        .date-weather-day-of-week {
          @include P2;
        }
      }

      .weather-container {
        height: 64px;
        border-radius: 8px;
        background: linear-gradient(45deg, #1051A1 0%, #387ACC 100%);
      }
    }

    .widget-links {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 24px 16px 24px;

      .link-header-container {
        display: flex;
        padding-top: 24px;
        padding-bottom: 16px;
        justify-content: space-between;
        height: 80px;
        align-items: center;
        border-bottom: 1px solid var(--translucent-white-60);
        text-decoration: none;


        .link-header {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%;
          padding-right: 16px;
          color: var(--white);
          cursor: pointer;
          word-wrap: break-word;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        &:hover {
          .link-header {
            color: var(--blue);
          }
        }
      }
    }
  }
}