.share-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 150ms ease-out;

  a {
    margin-left: 16px;
    border-radius: 24px;
    background: var(--grey-6);
  }

  .telegram,
  .whatsapp,
  .vk,
  .ok {
    width: 32px;
    height: 32px;
  }

  .telegram {
    svg {
      &:hover {
        transform: scale(1.1, 1.1);
        #Vector {
          fill: #6699ce !important;
        }
      }
    }
  }

  .whatsapp {
    svg {
      &:hover {
        transform: scale(1.1, 1.1);
        #Vector {
          fill: var(--primary)!important;
        }
      }
    }
  }

  .vk {
    svg {
      &:hover {
        transform: scale(1.1, 1.1);
        #Vector {
          fill: var(--deep-blue)!important;
        }
      }
    }
  }

  .ok {
    svg {
      &:hover {
        transform: scale(1.1, 1.1);
        #Vector {
          fill: #f77f00!important;
        }
      }
    }
  }

}