@import 'client/styles/mixins';

.simple-card {
	padding: 24px;
	width: 100%;
	border-radius: 8px;
}

.section-card {
	width: 100%;

	&:not(:last-child) {
		margin-right: 24px;
	}

	&__title {
		display: flex;
		align-items: center;
		color: var(--deep-blue);
		white-space: nowrap;
		text-transform: uppercase;
		gap: 10px;
		margin-bottom: 24px;
	}
	&__icon {
		max-height: 20px;
	}

	&__line {
		width: 100%;
		border-bottom: 1px solid var(--deep-blue);
	}
}

.question-answer-card {
	margin: 24px 24px 24px 0;
	display: flex;

	.images {
		min-width: 125px;
		height: 125px;
		border-radius: 8px;
		margin-right: 24px;
		overflow: hidden;

		img {
			transition: linear 1s;
			max-width: 125px;
			height: 125px;
			object-fit: cover;
			border-radius: 8px;
		}
	}

	.category-subcategory {
		display: flex;
		padding-bottom: 8px;
		font-weight: 500;

		.category {
			padding-right: 10px;
			border-right: 1px solid var(--grey-3);
		}

		.subcategory {
			padding-left: 10px;
			white-space: nowrap;
		}
	}

	.question-answer-title {
		color: var(--black);
		padding-bottom: 8px;
	}

	.text-middle {
		word-wrap: break-word;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		color: var(--grey-1);
		font-weight: 400;
		margin-bottom: 8px;
	}

	.date {
		max-width: 200px;
		color: var(--grey-2);
	}

	.info {
		color: var(--grey-2);
	}

	&:hover {
		img {
			transform: scale(1.1);
		}

		.title {
			color: var(--deep-blue);
		}

		.text {
			color: var(--black);
		}
	}
}

.event-card {
	cursor: pointer;
	text-decoration: initial;
	max-width: 280px;

	.image {
		overflow: hidden;
		border-radius: 8px;
		width: 280px;
		height: 200px;
		img {
			width: 100%;
			object-fit: contain;
			transition: all 0.25s ease;
		}
		svg {
			height: 100%;
		}
	}

	.info {
		.event-title {
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
	}

	.p3 {
		color: var(--grey-2);
	}
	.p2 {
		color: var(--deep-blue);
	}
	&:hover {
		img {
			transform: scale(1.1);
		}
		.p2 {
			color: var(--deep-blue-hover);
		}
		.h6 {
			color: var(--deep-blue);
		}
		.p3 {
			color: var(--grey-2-hover);
		}
	}
}
