.small-question {
	display: block;
	padding-bottom: 20px;
	margin-bottom: 20px;
	border-bottom: 1px solid var(--grey-4);
	text-decoration: none;

	.category-subcategory {
		display: flex;
		padding-bottom: 8px;
		font-weight: 500;
		color: var(--grey-3);

		.category {
			padding-right: 10px;
			border-right: 1px solid var(--grey-3);
		}

		.subcategory {
			padding-left: 10px;
		}
	}

	&:last-child {
		margin-bottom: 0;
		border: 0;
	}

	.title {
		line-height: 1.3em;
		color: var(--black);
		margin-bottom: 5px;
	}

	.info {
		display: flex;

		.date {
			flex-shrink: 0;
			padding-right: 10px;
            font-weight: 400;
			color: var(--grey-3);
		}
	}

	&:hover {
		.title {
			color: var(--deep-blue);
		}
	}
}
