.attachments {
	margin-bottom: 5px;
	white-space: nowrap;
	text-overflow: ellipsis;
	display: flex;
	flex-direction: column;

	.list,
	.new-files,
	.upload-errors {
		line-height: 1.4em;

		.attachment,
		.new-file {
			margin-bottom: 7px;
			display: flex;
			align-items: center;
			a {
				text-decoration: none;
				&:hover {
					color: var(--primary) !important;
				}
			}

			svg {
				cursor: pointer;
				&:hover {
					*[stroke] {
						stroke: red;
					}
				}
			}

			.sp-trigger {
				width: 20px;
				height: 16px;
				margin-right: 10px;
			}

			.icon {
				flex: 0 0 20px;
				font-size: 14px;
				color: var(--primary);

				.loader {
					margin-left: -2px;
				}
			}

			& > .info {
				flex-grow: 1;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				line-height: 1.3em;
				font-size: 12px;
			}

			.file-name {
				display: inline-block;
				max-width: calc(100% - 30px);
				vertical-align: top;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				color: var(--text);

				&:hover {
					color: var(--secondary);
				}
			}

			.date,
			.progress {
				font-size: 11px;
				color: var(--text-lighter);

				em {
					font-style: normal;
					color: var(--secondary);
				}
			}

			.error-msg {
				font-size: 11px;
				color: var(--danger);
			}

			.small-delete-btn {
				vertical-align: top;
				font-size: 10px;
				margin-left: 5px;
				color: var(--text-lighter);

				&:hover {
					color: var(--danger);
				}
			}
		}

		.upload-error {
			.icon {
				color: #d00;
			}
		}
	}

	.upload {
		margin-bottom: 13px;
		display: flex;
		align-items: center;
		column-gap: 13px;

		input[type="file"] {
			display: none;
		}

		input[type="file"] + label {
			display: inline-block;
			cursor: pointer;
			color: var(--primary) !important;
			text-decoration: underline;
		}
	}
}

.popconfirm-content.confirm-delete {
	max-width: 300px;
	word-break: break-all;

	em {
		font-style: normal;
		color: #0af;
	}
}

.upload-guidelines {
	margin-bottom: 13px;
	display: flex;
	align-items: center;
	column-gap: 13px;

	input[type="file"] {
		display: none;
	}

	input[type="file"] + label {
		cursor: pointer;
		display: inline-flex;
		padding: 10px 24px;
		align-items: flex-start;
		text-decoration: none;
		text-transform: uppercase;
		border-radius: 4px;
		background: var(--deep-blue);
		color: var(--white);
	}
}

.guidelines-attachment {
	display: flex;
	align-items: center;
	width: 743px;

	.guidelines-info {
		display: flex;

		.file-name {
			min-width: 543px;
			text-decoration: none;
		}

		.file-name, .file-size {
			display: flex;
			padding: 10px 0 10px 20px;
			margin-top: 8px;
			align-items: center;
			background: var(--white);
			color: var(--dark);
		}

		.file-size {
			width: 200px;
		}

		.delete-button {
			display: flex;
			align-items: center;
			padding-bottom: 6px;
			margin-left: 24px;
			cursor: pointer;
		}
	}
}