@import 'client/styles/mixins';
@import 'site/styles/mediaBreakpoints';

.interactive-map {
	background: linear-gradient(79.09deg, #1051a1 0%, #387acc 93.57%);
	display: flex;
	justify-content: center;
	color: white;
	max-width: 100%;
	padding-block: 64px;

	.map-container {
		display: flex;
		gap: 20px;
		width: var(--site-width, 1200px);

		.content {
			flex: 1;

			display: flex;
			flex-direction: column;
			gap: 24px;

			.map-info {
				display: flex;
				gap: 24px;
				flex: 1;

				.map-data {
					width: 384px;
					display: flex;
					flex-direction: column;
					gap: 24px;

					.dropdown {
						padding: 16px 8px;
						display: flex;
						gap: 14px;
						height: fit-content;
						width: 100%;
						background-color: transparent !important;
						color: white;
						border: solid 1px #ffffff4d;
						border-radius: 8px;

						.county-icon {
							width: 48px;
							height: 48px;
						}

						.county-name,
						.show-value {
							flex: 1;
							font-size: 14px;
						}

						.filter {
							height: fit-content;

							&::before {
								top: 30px;
							}
						}
					}

					.county-description {
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 150%;
						height: 126px;
						word-wrap: break-word;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 6;
						-webkit-box-orient: vertical;
					}

					.info-grid {
						display: grid;
						grid-template-columns: 1fr 1fr;
						grid-column-gap: 8px;
						grid-row-gap: 24px;

						.info-cell {
							display: flex;
							flex-direction: column;
							gap: 10px;
						}

						.info-title {
							color: white;
							font-size: 12px;
							font-weight: 600;
							text-transform: uppercase;
							line-height: 16.8px;
							letter-spacing: 0.48px;
							word-wrap: break-word;
						}
					}

					.weather {
						display: flex;
						width: 100%;
						padding: 8px 16px;
						justify-content: space-between;
						gap: 16px;
						background: linear-gradient(79.09deg, #1051a1 0%, #387acc 93.57%);
						border-radius: 8px;

						.info {
							.city {
								font-size: 16px;
								font-weight: 500;
								line-height: 24px;
							}

							.description {
								font-size: 12px;
								line-height: 19.2px;
							}

							.rain {
								font-size: 10px;
								line-height: 14px;
							}
						}

						.temperature {
							display: flex;
							align-items: center;
							gap: 4px;

							.text {
								font-size: 40px;
								line-height: 40px;
							}

							.icon {
								width: 48px;
								height: 48px;
							}
						}
					}
				}

				.map {
					flex: 1;

					svg > g {
						cursor: pointer;
					}

					svg g .flag {
						pointer-events: none;
						opacity: 0;
						transition: opacity 0.5s, transform 0.5s;
					}

					svg > g:hover .flag {
						opacity: 1;
					}

					svg > g.selected .flag {
						transform: translateY(-4px);
						opacity: 1;
					}
				}
			}

			.map {
				width: 490px;
			}

			.buttons {
				display: flex;
				gap: 16px;
				height: 48px;

				a {
					display: flex;
					gap: 10px;
					padding: 12px 22px;
					align-items: center;
					font-size: 16px;
					font-weight: 600;
					color: var(--white);

					border-radius: 9999px;

					text-decoration: none;
					cursor: pointer;
				}

				.main-site {
					background-color: var(--blue);
				}

				.other-sites {
					background-color: var(--light);
					color: var(--deep-blue-hover);
				}

				.gis {
					background-color: #75bf78;
				}
			}
		}

		.side-bar, .articles-list {
			width: 282px;
			display: flex;
			flex-direction: column;
			gap: 24px;

			.article {
				padding-bottom: 10px;
				display: flex;
				flex-direction: column;
				gap: 8px;
				border-bottom: solid 1px #e0e0e033;

				&-title {
					color: var(--basic-white, #fff);
					@include H7;
				}

				.date {
					color: var(--basic-grey-4, #c8d2e0);
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 160%;
				}
			}
		}
	}

	.title {
		font-size: 12px;
		font-weight: 600;
		text-transform: uppercase;
		line-height: 16.8px;
		letter-spacing: 0.48px;
		word-wrap: break-word;

		display: flex;
		gap: 10px;
		align-items: center;

		&::after {
			content: '';
			flex: 1;
			height: 1px;
			background-color: white;
		}
	}
}

.interactive-map-selector {
	display: flex;
	align-items: center;
	gap: 14px;

	img {
		border-radius: 48px;
	}
}

.sp-dropdown-trigger .filter input[type='text'] {
	color: var(--white) !important;
}

@media(max-width: $mobile-width-breakpoint){
	.interactive-map{
		padding: var(--mobile-section-padding);

		.map-container{
			max-width: 100%;
			flex-direction: column;

			.content{
				gap: var(--mobile-standart-row-gap);

				.map-info{
					flex-direction: column-reverse;

					.map-data, .map{
						width: 100%;
					}

					.map{

						svg{
							max-width: 100%;
							height: auto;
							display: block;
						}
					}

					.map-data{
						gap: var(--mobile-standart-row-gap);

						.record-select{
							padding: 8px 16px;

							.filter{

								&:before{
									min-height: 100%;
									top: 0px;
									background-position: center;
								}
							}

							.filter-content{
								padding: 0px;
							}
						}

						.county-description{
							@include p2-mobile;
							height: max-content;
						}
					}
				}

				.buttons{
					display: flex;
					flex-direction: column;
					gap: var(--mobile-standart-row-gap);
					height: max-content;

					a{
						width: 100%;
						justify-content: center;
						padding: 6px 16px;
						height: max-content;

						span{
							@include p1-mobile;
							font-weight: var(--mobile-p2-weight);
						}
					}
				}
			}

			.side-bar{
				width: 100%;
				gap: var(--mobile-standart-row-gap);

				.articles-list{
					width: 100%;
					max-width: 100%;
					overflow-x: auto;
					display: flex;
					gap: var(--mobile-standart-row-gap);
					flex-direction: row;

					&::-webkit-scrollbar{
						height: 0px;
					}

					.article{
						min-width: 136px;
						justify-content: space-between;

						.article-title{
							word-wrap: break-word;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 3;
							-webkit-box-orient: vertical;
							@include h7-mobile;
						}

						.date{
							@include p3-mobile;
						}
					}
				}
			}
		}
	}
}
