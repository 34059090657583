.tab-bar {
	display: flex;
	gap: 2px;
	justify-content: space-between;
	text-transform: uppercase;
	padding-bottom: 41px;

	.items {
		display: flex;
		align-items: center;
	}

	a {
		color: var(--black);
		text-decoration: none;
		cursor: pointer;
	}

	.link {
		border: 3px solid transparent;
		border-radius: 20px;
		a {
			padding: 8px 16px;
		}

		&:hover {
			border: 3px solid #1051a1;
		}
	}
}
