.discussions-filters {
	margin-bottom: 32px;

	input[type="checkbox"] {
		width: 20px;
		margin-right: 10px;
	}

	.selectors {
		display: flex;
		align-items: center;
		column-gap: 8px;
	}

	.sort {
		display: flex;
		align-items: center;
		gap: 8px;
		margin-left: auto;
	}
}