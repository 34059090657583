@import 'client/styles/mixins';
@import 'site/styles/mediaBreakpoints';

.article-page-container {
	display: flex;
	margin-top: 226px;
	gap: 16px;

	.article-page {
		width: calc(100% - 298px);
		font-size: 16px;
		line-height: 1.4em;

		.link {
			a {
				text-decoration: none;
				color: rgb(0, 150, 240);
				font-weight: 600;
			}

			padding-bottom: 20px;
		}

		.wrapper-top-content{
			padding-right: 30px;

			.search-page{
				background: transparent;
				padding: 0px !important;
				height: auto;
				margin-bottom: 24px;
			}
			.wrapper-category-and-crumbs{
				display: flex;
				gap: 16px;
				justify-content: space-between;

				.breadcrumbs{
					display: flex;
					overflow: hidden;
					span{
						flex-shrink: 0;
						margin: 0px;

						.bc-title{
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
				}

				.right{
					flex-shrink: 0;
					min-width: max-content;

					.portal-link{
						svg{
							position: relative;
						}
					}
				}
			}

			.search-title-line{
				.h1{
					line-height: 44px;
					margin: 0px;
				}
			}
		}

		.article-container {
			display: flex;

			.social-side {
				flex-basis: 50px;
				flex-shrink: 0;

				.share-links {
					a {
						display: block;
						height: 27px;
						margin-bottom: 15px;
					}
				}
			}

			.article {
				flex-grow: 1;
				padding-right: 30px;


				.content {
						padding-top: 26px;
					.labels {
						display: flex;
						flex-direction: row;
						@include P2;
						color: var(--black);
						padding-bottom: 24px;
						.label {
							padding-right: 10px;
						}
					}

					.footer {
						display: flex;
						font-size: 14px;

						.author {
							flex-grow: 1;
							font-weight: bold;
							position: relative;
						}

						.social-share {
							display: flex;
							align-items: center;
						}
					}

					img{
						max-width: 100%;
					}
				}
			}
		}

		&>.title {
			margin: 20px 0;
			line-height: 150%;
			position: relative;
			max-width: 880px;
		}

		&>.info {
			display: flex;
			margin-bottom: 20px;
			color: var(--grey-2);
			font-weight: 400;

			.left {
				flex-grow: 0.5;

				.date {
					color: var(--grey-2);
					margin-right: 10px;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 150%;
				}

				.date-topic {
					font-size: 12px;
					font-style: normal;
					font-weight: 600;
					line-height: 140%;
					letter-spacing: 0.48px;
					text-transform: uppercase;
					padding-left: 10px;
				}

				.magazine {
					color: var(--grey-2);

					&:hover {
						color: var(--grey-2-hover);
					}
				}
			}

			.right {
				flex-grow: 0.5;
				text-align: right;
			}
		}

		.article {
			width: calc(100% - 298px);

			.topics {
				a {
					color: black;
				}

				.topic {
					color: #5e5e5e;
					display: inline-block;
					vertical-align: top;
					height: 30px;
					line-height: 30px;
					padding: 0 15px;
					border-radius: 5px;
					background: #eee;
					cursor: pointer;
					margin-right: 5px;
					margin-bottom: 10px;
					font-size: 14px;

					&:hover {
						background: #ccc;
					}
				}
			}

			.image {
				margin-bottom: 8px;
				position: relative;

				img {
					width: 100%;
					//height: 634px; // это правило урезает картинку
					object-fit: contain;
				}
			}

			.image-signature {
				color: var(--grey-2-hover);
				margin-bottom: 24px;
			}

			.subtitle {
				font-weight: bold;
				margin-bottom: 20px;
			}

			.text {
				margin-bottom: 30px;

				a {
					text-decoration: underline !important;
				}

				img {
					height: 100%;
					object-fit: contain;
				}
			}
		}

		.more-container {
			margin-top: 70px;

			.related-news {
				display: flex;
				align-items: center;
				color: var(--deep-blue);
				text-transform: uppercase;
				white-space: nowrap;

				.line {
					width: 100%;
					margin-left: 10px;
					border-bottom: 1px solid var(--deep-blue);
				}
			}

			.news-more {
				width: 100%;
				padding-top: 40px;

				.articles-grid {
					display: grid;
					grid-template-rows: repeat(3, 1fr);
					gap: 20px;
					padding-bottom: 20px;
					width: 100%;

					.small-articles {
						width: 100%;
					}
				}
			}
		}

		.back-forward {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin: 35px 0;
			padding-right: 30px;

			.back,
			.forward {
				display: flex;
				flex-direction: row;
				align-items: center;
				cursor: pointer;

				.back-to-chapter,
				.forward-to-chapter {
					color: var(--grey-2);
					word-wrap: break-word;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;

					&:hover {
						color: var(--black);
					}
				}

				.forward-to-chapter {
					max-width: 600px;
				}

				.arrow-left,
				.arrow-right {
					margin-right: 10px;
					vertical-align: middle;

					.s-fill {
						fill: var(--deep-blue);
					}

					.s-stroke {
						stroke: var(--black);
					}
				}
			}
		}
	}

	.sidebar {
		position: sticky;
		flex-basis: 285px;
		flex-shrink: 0;
		width: 285px;

		.sidebar-news {
			display: flex;
			align-items: center;
			color: var(--deep-blue);
			text-transform: uppercase;
			padding-bottom: 24px;

			.p4 {
				font-size: 12px;
				font-style: normal;
				font-weight: 600;
				line-height: 140%;
				letter-spacing: 0.48px;
				text-transform: uppercase;
			}

			.line {
				width: 200px;
				margin-left: 10px;
				border-bottom: 1px solid var(--deep-blue);
			}
		}
	}
}

.media-popup-slider-article{

	.media-slide-content{

		img{
			min-width: 100%;
			max-width: 100%;
		}
	}

	.slick-arrow{
		z-index: 10;
		width: max-content;
		height: max-content;

		&:before{
			display: none;
		}

		&.slick-prev{
			left: 0px;
		}

		&.slick-next{
			right: 0px;
		}
	}
}

.media-slider {
	width: 101%;
	margin: 20px 0;
	overflow: hidden;

	.slide-items {
		display: flex;
		transition: all 300ms;

		.media-slider-item {
			width: 223px;
			height: 129px;
			display: flex;
			padding-right: 8px;
			cursor: pointer;
		}
	}

	.indicators {
		display: flex;
		list-style: none;
		margin-top: 24px;
		justify-content: flex-start;
	}

	.indicators li {
		height: 10px;
		width: 10px;
		background: var(--grey-4);
		border-radius: 50%;
		margin-right: 16px;
		cursor: pointer;
		opacity: .7;
		transition: all 300ms;

		&:hover {
			opacity: 1;
			background: var(--deep-blue);
		}

		&.active {
			background: var(--deep-blue);
		}
	}
}

.media-slider-container {
	margin: 0 auto;
	text-align: center;
	overflow: hidden;
	position: relative;
	display: flex;
	align-items: center;
	border-radius: 8px;

	.media-slider-small {
		display: flex;
		border-radius: 8px;
		max-width: 100%;
		height: auto;
		overflow: hidden;

		img {
			min-width: 210px;
			height: 129px !important;
			object-fit: cover;
		}

		&.linked:hover {
			border: 0;
			box-shadow: none;
			transform: none;
		}

		div:first-child {
			width: 100%;
		}
	}
}

@media (min-width: 768px) and (max-width: 1199px) {
	.article-page {
		.sidebar {
			display: none;
		}

		.article-container {
			.article {
				padding: 0;

				.image {
					img {
						width: 100%;
						height: auto;
					}
				}
			}
		}

		.more-container {
			width: 100%;
			margin: 0;

			.news-more {
				width: 100%;

				.articles {
					grid-template-columns: 1fr 1fr 1fr;
				}
			}
		}
	}
}

@media (max-width: 767px) {
	.article-page {
		.sidebar {
			margin: 20px 0;
		}

		.more-container {
			background: #fff;

			.news-more {
				width: 100%;

				.articles {
					display: block;

					.big-article,
					.mid-article {
						background: #f5f5f5;
						height: auto;
					}
				}
			}
		}

		.article-container {
			display: flex;
			flex-direction: column;

			.social-side {
				display: none;
			}

			.article {
				flex-grow: 1;
				padding: 0;

				.image {
					img {
						width: 100%;
						height: auto;
					}
				}

				.content {
					display: block;

					.text {
						img {
							height: auto;
						}
					}

					.footer {
						display: block;
						font-size: 14px;

						.author {
							display: block;
							position: relative;
							padding-top: 15px;
						}

						.social-bottom {
							text-align: left;
							padding-top: 15px;

							.share-links {
								a {
									margin-left: 0;
									margin-right: 10px;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media(max-width: $mobile-width-breakpoint){
	.article-page-container{
		margin: 0px !important;
		padding: var(--mobile-wrapper-content-padding-all) !important;
		flex-direction: column;
		background: var(--white);
		gap: var(--mobile-standart-row-gap);

		.article-page{
			width: 100%;
			@include flex-column;
			gap: var(--mobile-standart-row-gap);

			.breadcrumbs{
				display: none;
			}

			& > div{
				margin: 0px !important;
			}

			.h1{
				@include h1-mobile;
			}

			.info{
				width: 100%;

				.p4{
					@include p2-mobile;
					font-weight: 400 !important;
				}
			}

			.article-container{

				.article{
					width: 100%;
					padding: 0px;
					@include flex-column;
					gap: var(--mobile-standart-row-gap);

					.image-signature{
						margin: 0px;
					}

					.content{
						@include flex-column;
						gap: var(--mobile-small-row-gap);

						& > div{
							margin: 0px;
						}

						.media-slider{
							@include scroll-horizontal;

							.indicators{
								display: none;
							}
						}

						.labels{
							padding: 0px;
						}

						.footer{
							@include flex-column;

							.author{
								padding: 0px;
								@include p2-mobile;
								color: var(--grey-2);
							}

							.social-share{
								width: 100%;
								@include flex-column;
								gap: var(--mobile-standart-row-gap);

								.social-bottom{
									padding: 0px;

									.share-links{
										gap: var(--mobile-standart-row-gap);

										a{
											margin: 0px;
											width: 48px;
											height: 48px;
											@include flex-column;
											justify-content: center;
											align-items: center;
										}
									}
								}
							}
						}
					}

					.more-container{
						margin: 0px;
					}
				}
			}

			.back-forward{
				flex-direction: column;
				align-items: flex-start;

				a{
					opacity: .5;
					svg{
						path{
							fill: var(--black);
						}
					}

					.p4{
						color: var(--black) !important;
					}
				}
			}
		}

		.sidebar{
			width: 100%;
			@include flex-column;
			gap: var(--mobile-standart-row-gap);

			.sidebar-news{

				.line{
					width: auto;
					flex-grow: 1;
				}
			}

			& > div{
				padding: 0px !important;
			}

			.small-article{
				margin: 0px;
			}
		}
	}
	.media-popup-slider-article{

		.sp-popup-content{
			max-width: 100%;
			width: 100% !important;
		}
	}
}

@media(max-width: 640px){
	.article-page-container{
		.article-page{
			.wrapper-top-content{
				padding: 0px;
				.wrapper-category-and-crumbs{
					flex-direction: column;
				}
				.search-title-line{
					.h1{
						line-height: 1.2em;
					}
				}
				.search-page{
					margin: 0px;
				}
			}
		}
	}
}