.top-banner-container {
	width: 100%;
	background: #eee;
	text-align: center;
	overflow: hidden;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	.banner-1920x195 {
		// баннер всегда отцентрован и не вызывает скроллбар при ширине браузера < 1920px
		position: static;
		width: 1920px;
		overflow: hidden;
		color: #fff;
		max-width: 100%;

		&.linked:hover {
			border: 0;
			box-shadow: none;
			transform: none;
		}

		div:first-child {
			width: 100%;
		}
	}
}

@media (max-width: 1199px) {
	.top-banner-container {
		display: none;
	}
}
