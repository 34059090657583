@import 'site/styles/mediaBreakpoints';

.static-page {

	padding-top: 0;
	$line-height: 1.5em;

	&,
	* {
		line-height: $line-height;
	}

	h1:first-of-type {
		text-align: center;
	}
	&>.title {
		margin: 20px 0;
		line-height: 150%;
		position: relative;
		max-width: 880px;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		padding: 0;
	}

	a {
		text-decoration: underline;
	}

	p {
		margin: 0;
	}

	ol:first-of-type,
	ul:first-of-type {
		margin-top: 0;
	}

	ol,
	ul {
		margin: $line-height 15px;

		li {
			padding: 0;
			margin: 0;

			p {
				margin: 0;
			}
		}
	}

	ol {
		li {
			padding: 0;
			margin-bottom: $line-height;
		}
	}

	table {
		th {
			background: #eee;
		}
		th,
		td {
			p {
				margin: 0;
			}
		}
	}

	.application-wrapper {
		.title {
			padding-top: 20px;
			padding-bottom: 20px;
			color: var(--grey-2, #8291A4);
		}
	}
	.application {
		display: flex;
		flex-wrap: wrap;
		gap: 24px;
		padding-bottom: 30px;

		.docs {
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 50px;
			flex: 0 0 calc(50% - 24px);
			padding: 0 16px;
			border-radius: 8px;
			background: var(--grey-6);
			color: var(--black);
			text-decoration: inherit;
			text-align: left;
			cursor: pointer;

			&:hover {
				background: var(--grey-6-hover);
			}

			.file-name {
				word-wrap: break-word;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
			}
		}
	}

	div.accordeon + div.accordeon {
		margin-top: 15px;
	}

	.accordeon {
		padding: 10px;
		border: 1px solid #ccc;

		.accordeon-header {
			cursor: pointer;
			font-weight: bold;
		}

		.accordeon-body {
			padding-top: 1.4em;
			display: none;

			p {
				//margin: 0 0 1.4em 0; // не включать! из-за WYSIWYG
				padding: 0;
			}
		}

		&.active {
			& > .accordeon-header {
				//margin-bottom: 0;
			}

			& > .accordeon-body {
				display: block;
			}
		}
	}

	.hide-nav > .route-links-container {
		display: none;
	}

	.article-container {
		display: flex;
		flex-direction: column;
		gap: var(--mobile-standart-row-gap);
		margin-bottom: 64px;

		.article {
			flex-grow: 1;


			.content {
				.footer {
					display: flex;
					font-size: 14px;

					.author {
						flex-grow: 1;
						font-weight: bold;
						position: relative;
					}

					.social-share {
						display: flex;
						align-items: center;
					}
				}

				pre {
					white-space: pre-wrap;
				}

				&.label {
					margin: 0;
					padding: 0;
				}
			}
		}
	}
}

.page-with-menu {
	h2 {
		margin-bottom: 15px;
	}
}

@media(max-width: $mobile-width-breakpoint){
	.static-page{
		padding: var(--mobile-wrapper-content-padding-all);
		@include flex-column;
		gap: var(--mobile-standart-row-gap);

		.title{
			margin: 0px;
			@include h1-mobile;
		}

		.article-container{
			margin: 0px;
		}
	}
}
