@import 'site/styles/mediaBreakpoints';

.management-page {
  padding-bottom: 64px;

  .management-page-group-list {

    .management-page-group-item {

      .management-page-group-title {
        padding-bottom: 32px;
        color: var(--basic-black, #464A51);
      }

      .management-page-group-person-list {

        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        padding-bottom: 32px;

        .management-person-card {
          border-radius: 8px;
          background: var(--basic-white, #FFF);
          overflow: hidden;
          display: flex;
          min-width: 384px;
          max-width: 384px;
          cursor: pointer;

          .management-person-card-pic {}

          .management-person-card__text-wrapper {
            padding: 16px 16px 16px 24px;
            display: flex;
            flex-direction: column;
            gap: 16px;

            .management-person-card__title {
              color: var(--basic-black, #464a51);
              font-weight: 600;
              line-height: 140%;
            }

            .management-person-card__position {
              color: var(--basic-black, #464A51);
            }

          }
        }
      }
    }
  }
}

@media(max-width: $mobile-width-breakpoint){
  .management-page{
    padding: var(--mobile-wrapper-content-padding-all);

    .management-page-group-list{
      @include flex-column;
      gap: var(--mobile-standart-row-gap);

      .management-page-group-item{
        @include flex-column;
        gap: var(--mobile-standart-row-gap);

        .management-page-group-title{
          padding: 0px;
        }

        .management-page-group-person-list{
          padding: 0px;

          .management-person-card{
            @include flex-row;
            gap: var(--mobile-standart-row-gap);
            min-width: 100%;
            max-width: 100%;
            background: transparent;

            picture{
              max-width: 120px;

              img{
                max-width: 100%;
              }
            }

            .management-person-card__text-wrapper{
              padding: 0px;
              flex-grow: 1;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}
