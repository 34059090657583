.comments {
	width: 895px;
	max-width: 100%;
	margin-top: 30px;
	padding-top: 30px;
	border-top: 1px solid #ccc;
	margin-bottom: 30px;

	.title {
		font-weight: bold;
		font-size: 22px;
		padding-bottom: 15px;
		margin-bottom: 20px;
		position: relative;
		color: #aaa;

		&:after {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100px;
			height: 2px;
			background: var(--red);
		}
	}

	.pending {
		margin-bottom: 30px;
	}
}

.comment {
	margin-bottom: 15px;
	padding-bottom: 15px;
	border-bottom: 1px solid #ccc;
	font-size: 14px;

	&:last-child {
		border: 0;
		padding-bottom: 0;
	}

	.info {
		margin-bottom: 5px;
		
		.author {
			font-weight: bold;
			margin-right: 10px;
		}
		
		.date {
			color: #888;
		}
	}

	.actions {
		color: #888;

		a {
			color: #888;
		}
	}

	.text {
		margin-bottom: 5px;
		line-height: 1.4em;
		word-break: break-word;
		//background: #f2f4f6;

		p {
			margin: 0;
		}

		ol, ul {
			margin: 0;
			padding: 0 0 0 15px;
		}

		* + ol, * + ul, ol + *, ul + * {
			margin-top: 1.4em;
		}
	}

	.toggle {
		font-size: 12px;
		margin-top: 5px;
		margin-bottom: 2px;
	}

	.delete-btn * {
		fill: #aaa;
	}
}

.post-comment {
	margin-top: 30px;
	margin-bottom: 30px;

	.post-title {
		font-size: 12px;
		margin-bottom: 20px;
		color: var(--secondary);
	}

	input {
		border: 1px solid #ccc;
		border-radius: 4px;
		height: 34px;
		width: 700px;
		max-width: 100%;
		padding: 0 5px;
		font-size: 14px;

		&:focus {
			outline: none;
		}

		&::placeholder {
			color: #ccc;
		}
	}

	.block {
		margin-bottom: 15px;
		
		label {
			display: block;
			font-size: 12px;
			color: #888;
		}
	}

	.actions {
		margin-top: 5px;
	}

	.error {
		margin-top: 10px;
		font-size: 12px;
		color: #d00;
	}
}

.edit-text {
	.tox-tinymce {
		width: 700px;
		max-width: 100%;
		border-radius: 4px;
	}

	textarea {
		width: 700px;
		max-width: 100%;
		border-radius: 4px;
		border: 1px solid #ccc;
		padding: 5px;
		font-size: 14px;

		&:focus {
			outline: none;
		}
	}
}

