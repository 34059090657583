.weather-scale-container {
	position: absolute;
	bottom: 10px;
	right: 10px;
	height: 20px;
	border-radius: 10px;
	width: 302px;
	background: #fff;
	border: 1px solid rgba(#fff, 0.5);
	z-index: 100;
	color: #fff;
	font-size: 12px;
	text-shadow: 1px 2px rgba(#000, 0.9);

	&.hidden {
		display: none;
	}

	&.in-card {
		bottom: 80px;
	}

	.weather-scale {
		width: 100%;
		height: 100%;
		position: relative;

		canvas {
			width: 100%;
			height: 100%;
			border-radius: 9px;
		}

		.min-value {
			position: absolute;
			left: 10px;
			top: 0;
		}

		.max-value {
			position: absolute;
			right: 10px;
			top: 0;
		}

		.measure {
			position: absolute;
			left: 50%;
			transform: translate(-50%, 0);
			top: 0;
		}
	}
}

.map-scale-container {
	//transform-origin: right bottom;
	//transform: scale(3);
	position: absolute;
	bottom: 10px;
	right: 10px;
	height: 20px;
	border-radius: 10px;
	width: 302px;
	background: #fff;
	border: 1px solid rgba(#fff, 0.5);
	z-index: 100;
	color: #fff;
	font-size: 12px;
	text-shadow: 1px 2px rgba(#000, 0.9);

	&.hidden {
		display: none;
	}

	&.in-card {
		bottom: 80px;
	}

	.map-scale {
		width: 100%;
		height: 100%;
		position: relative;

		canvas {
			width: 100%;
			height: 100%;
			border-radius: 9px;
		}

		.min-value {
			position: absolute;
			left: 10px;
			top: 0;
		}

		.max-value {
			position: absolute;
			right: 10px;
			top: 0;
		}

		.measure {
			position: absolute;
			left: 50%;
			transform: translate(-50%, 0);
			top: 0;
		}
	}
}