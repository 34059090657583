@import 'site/styles/mediaBreakpoints';

.main-page-content {
	& > * {
		margin: 0 auto;
		max-width: var(--site-width);
		width: 100%;
		flex: 0;
	}
	display: flex;
	flex-direction: column;
	gap: 64px;
}

h2.main {
	display: flex;
	align-items: center;
	color: #969696;
	font-size: 30px;
	font-weight: bold;
	margin: 20px 0 30px 0;

	img {
		flex-basis: 40px;
		width: 40px;
		height: 40px;
		margin-right: 15px;
	}

	.right {
		flex-grow: 1;
		text-align: right;
		font-size: 14px;
		font-weight: normal;
		padding-left: 15px;
	}
}
.logo-section {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
	& > * {
		margin: 0 50px;
	}
	.logo-promo {
		max-width: 300px;
		max-height: 100px;
	}
}

.main-header {
	margin-bottom: 100px;
}
@media (max-width: 767px) {
	.logo-section {
		& > * {
			margin: 0 10px;
		}
		.logo-promo {
			max-width: 200px;
			max-height: 80px;
		}
	}
}

@media (max-width: 767px) {
	h2.main {
		font-size: 24px;
	}
}

@media(max-width: $mobile-width-breakpoint){
	.main-page-content{
		gap: 0px;
	}
}
