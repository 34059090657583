@import 'client/styles/mixins';

.record-card {
	display: flex;
	flex-direction: column;
	gap: 24px;

	.header-wrapper {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.record-breadcrumbs {
			display: flex;
			@include P2;

			.back-title {
				color: var(--grey-3);
				padding-right: 16px;
				border-right: 1px solid var(--grey-3);
				cursor: pointer;

				&:hover {
					color: var(--black);
				}
			}

			.current-title {
				color: var(--grey-3);
				padding-left: 16px;
			}
		}
	}
}